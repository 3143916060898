import React, { useState, useEffect } from "react";
import {
  CursorArrowRippleIcon,
  PlayCircleIcon,
  ArrowPathRoundedSquareIcon,
  PauseCircleIcon,
  ArrowLeftOnRectangleIcon
} from "@heroicons/react/20/solid";
import { startFirstLesson, startFirstQuiz } from "../../config/lessonFunctions";
import { UserAuth } from "../../context/AuthContext";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function FirstLesson() {
  const { studentProfile, setCurrentLesson, setStartTime, setIsLoading } = UserAuth();
  const [countDown, setCountDown] = useState(5);
  const [countDownStarted, setCountDownStarted] = useState(false);
  const [lessonStarted, setLessonStarted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [video, setVideo] = useState(null);
  const videoRef = useRef(null);
  const [shake, setShake] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    
    if (studentProfile) {
      startFirstQuiz(studentProfile?.lessonsAssigned[0]?.path).then((res) => {
        setCurrentLesson(res);
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [studentProfile]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = video;
    }
  }, [video]);

  useEffect(() => {
    if (countDownStarted && countDown > 0) {
      const timer = setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countDown === 0) {
      setCountDownStarted(false);
    }
  }, [countDownStarted, countDown]);

  const startCountDown = async () => {
    const videoRef = await startFirstLesson(studentProfile.lessonsAssigned[0].path);
    setVideo(videoRef);
    setCountDownStarted(true);
    setLessonStarted(true);
    setStartTime(new Date().getTime());
  };

  function updateProgress() {
    setProgress(
      (videoRef.current.currentTime / videoRef.current.duration) * 100
    );
  }

  useEffect(() => {
    if (progress === 100) {
      setShake(true);
    }
  }, [progress]);


  const handleQuiz = () => {
    navigate("/student/dashboard/first-quiz")
  };

  const handleBack = () => {
    navigate("/student/dashboard")
  };

  function handleReset() {
    videoRef.current.currentTime = 0;
  }
  return (
    <div className="max-w-5xl mx-auto px-4">
      <div className="flex items-center justify-center h-screen">
        {countDownStarted ? (
          <div className="font-londrina sm:text-7xl text-4xl tracking-wide text-center">
            {countDown}
          </div>
        ) : lessonStarted ? (
          <div className="">
            <div className="relative max-w-5xl mx-auto ">
              <button
                className="inline-flex items-center rounded-full text-white"
                onClick={() => {
                  if (isPlaying) {
                    videoRef.current.pause();
                    setIsPlaying(false);
                  } else {
                    videoRef.current.play();
                    setIsPlaying(true);
                  }
                }}
              >
                <video
                  className="mx-auto rounded-lg drop-shadow-2xl md:p-0 p-4"
                  ref={videoRef}
                  onEnded={() => setShowButton(true)}
                  onPlay={() => setShowButton(false)}
                  onPause={() => setShowButton(true)}
                  onTimeUpdate={updateProgress}
                >
                  <source src={video} type="video/mp4" />
                </video>
                <div className="absolute inset-0 flex items-center justify-center">
                  {isPlaying ? (
                    <p className="text-transparent">Pause</p>
                  ) : (
                    <PlayCircleIcon className="text-emerald-600 bg-white rounded-full hover:bg-emerald-700 hover:text-white md:h-48 h-12 mt-44 md:mt-12 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 shadow-xl animate-bounce" />
                  )}
                </div>
              </button>
            </div>
            {progress > 0 ? (
              <div className="grid grid-cols-12 w-full max-w-sm md:max-w-5xl mx-auto bg-emerald-200 rounded-md drop-shadow-ld border border-1 border-zinc-500">
                <div className="hidden sm:block sm:col-span-3 p-2">
                  <button
                    className="p-2 bg-blue-500 rounded-lg font-londrina text-md text-white w-full h-full hover:bg-blue-600 focus:ring focus:ring-2 focus:ring-blue-500"
                    onClick={handleBack}
                  >
                   <ArrowLeftOnRectangleIcon className="inline-block h-5 w-5" /> Back to Dashboard
                  </button>
                </div>

                <div className="col-span-7">
                  <div className="flex justify-start">
                    <button>
                      <ArrowPathRoundedSquareIcon
                        className="h-14 -mr-1 text-emerald-900"
                        onClick={handleReset}
                      />
                    </button>
                    <button
                      onClick={() => {
                        if (isPlaying) {
                          videoRef.current.pause();
                          setIsPlaying(false);
                        } else {
                          videoRef.current.play();
                          setIsPlaying(true);
                        }
                      }}
                    >
                      {isPlaying ? (
                        <PauseCircleIcon className="h-14 text-red-600" />
                      ) : (
                        <PlayCircleIcon className="h-14 text-emerald-500" />
                      )}
                    </button>
                    <div
                      className="bg-emerald-600 h-4 rounded-full flex align-center my-6 mr-6 "
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
                <div className="sm:col-span-2 col-span-5 p-2">
                  <button
                    className={`${shake && "animate-bounce"} p-2 bg-emerald-500 rounded-lg font-londrina text-xl text-white w-full h-full hover:bg-emerald-600 focus:ring focus:ring-2 focus:ring-emerald-500`}
                    onClick={handleQuiz}
                  >
                    Start Quiz
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div>
            <div className="font-londrina sm:text-7xl text-4xl tracking-wide text-center">
              Welcome to your first Lesson!
            </div>
            <button
              className="mt-12 w-full inline-flex items-center justify-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-blue-500 hover:bg-blue-600 font-londrina animate-bounce"
              onClick={startCountDown}
            >
              <CursorArrowRippleIcon className="inline-block h-12 w-12 mr-4" />{" "}
              Try it out!
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
