import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBxx2e-cYL-LE0ovwLhRGtRt--QknNo1Kw",
  authDomain: "reading-mastery-7f9b1.firebaseapp.com",
  projectId: "reading-mastery-7f9b1",
  storageBucket: "reading-mastery-7f9b1.appspot.com", // Primary bucket
  messagingSenderId: "231376283482",
  appId: "1:231376283482:web:549a09863484755aa27563",
  measurementId: "G-GD5EMJ0WBY"
};

// Initialize the default Firebase app
const app = initializeApp(firebaseConfig);

// Get references to Firestore, Auth, and the existing storage bucket
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

// Add another storage bucket to the configuration
const firebaseConfigWithSecondBucket = {
  ...firebaseConfig,
  storageBucket: "your-second-bucket-url" // Replace with your second bucket URL
};

// Initialize another Firebase app with the updated config containing the second bucket
const appWithSecondBucket = initializeApp(firebaseConfigWithSecondBucket, "reading-mastery-benchmarks");

// Get a reference to the second storage bucket using the second app
export const storageForSecondBucket = getStorage(appWithSecondBucket);
