import {
    ArrowLeftIcon,
    CursorArrowRippleIcon,
    HomeIcon,
    PlayCircleIcon,
    StopCircleIcon,
  } from "@heroicons/react/20/solid";
  import React, { useEffect, useState } from "react";
  import { useRecordWebcam } from "react-record-webcam";
  import { UserAuth } from "../../context/AuthContext";
  import {
    startBenchMark,
    studentCompletedBenchmark,
  } from "../../config/lessonFunctions";
  import { useNavigate } from "react-router-dom";
  import UploadCare from "../components/UploadCare";
  
  const { REACT_APP_API_URL } = process.env;
  
  export default function Benchmarks() {
    const { studentProfile, startTime, setStartTime, setIsLoading } = UserAuth();
  
    const [cameraOpen, setCameraOpen] = useState(false);
    const [lessonStarted, setLessonStarted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [benchmarkID, setBenchmarkID] = useState("");
    const [lessonComplete, setLessonComplete] = useState(false);

    const { uploading, uploadBenchMark } = UploadCare("3033b358967e042bd2d0");
  
    const OPTIONS = {
      fileName: "test-filename",
      mimeType: "video/x-matroska;codecs=avc1",
      width: 1920,
      height: 1080,
      disableLogs: true,
    };
  
    const recordWebcam = useRecordWebcam(OPTIONS);
  
    const [currentBenchmark, setCurrentBenchmark] = useState(null);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      setBenchmarkID(
        studentProfile?.gradeLevel +
          "-" +
          studentProfile?.currentBenchmark?.toString()
      );
    }, []);
  
    const handleOpenWebcam = async () => {
      recordWebcam.open();
      setLoading(true);
      setTimeout(() => {
        setCameraOpen(true);
        setLoading(false);
      }, 1500);
    };
  
    useEffect(() => {
      if (benchmarkID) {
        const fetchBenchmark = async () => {
          const benchmarkData = await startBenchMark(benchmarkID);
          setCurrentBenchmark(benchmarkData);
          setIsLoading(false);
          // Do something with benchmarkData, e.g., setBenchmark(benchmarkData);
        };
        fetchBenchmark();
  
      }
      setIsLoading(false);
    }, [benchmarkID]);
  
    const handleRecordStart = () => {
      recordWebcam.start();
      setLessonStarted(true);
      setStartTime(new Date().getTime());
    };
  
    const handleStopRecording = async () => {
      recordWebcam.stop();
      setLessonStarted(false);
      setCameraOpen(false);
      setLessonComplete(true);
    };
    const videoLink = `https://storage.googleapis.com/l2l-benchmarks/${studentProfile?.studentId}${benchmarkID}`;
  


    const uploadBenchMarkWithUploadCare = async (file, lessonData, readType, fileName) => {
      setLoading(true);
      try {
        const fileBlob = await recordWebcam.getRecording();

      // Create a new Blob with a custom type if needed
      const mimeType = "video/mp4";
      const recordedVideoBlob = new Blob([fileBlob], { type: mimeType });

      // Create a file name for the recorded video
      const fileName = `${studentProfile?.studentId}-benchmark-read-${new Date().getTime()}`;
      const formData = new FormData();
      formData.append("file", recordedVideoBlob, fileName);

      const finalTime = new Date().getTime() - startTime;
      const lessonData = {
        studentId: studentProfile?.studentId,
        benchmarkID: benchmarkID,
        lexileScore: currentBenchmark?.lexile,
        time: finalTime,
        totalWords: currentBenchmark?.text?.length,
        parentID: studentProfile?.parentID,
      };

      await uploadBenchMark(
        lessonData,
        recordedVideoBlob,
        fileName
      );
      setLoading(false);
    } catch (error) {
      alert("Error uploading file:", error);
      setLoading(false);
      navigate("/student/lesson-complete");
    }
  };




  
    const [showAllowCameraOpenPrompt, setShowAllowCameraOpenPrompt] =
      useState(true);
  
    useEffect(() => {
      setTimeout(() => {
        setShowAllowCameraOpenPrompt(false);
      }, 6000);
    }, []);
  
    return (
      <div className="relative">
        <div className="absolute top-0 left-0  w-96">
          <video ref={recordWebcam.webcamRef} autoPlay muted />
        </div>
        {showAllowCameraOpenPrompt && (
          <div className="text-center font-londrina text-3xl  mt-4">
            <div>
              {" "}
              <ArrowLeftIcon className="w-8 inline-block" /> Make sure you allow
              camera{" "}
            </div>
          </div>
        )}
        <div className="max-w-7xl mx-auto">
          {lessonStarted ? (
            <div className="justify-center p-12 max-w-5xl mx-auto">
              <div className="text-center text-4xl font-londrina font-semibold">
                {currentBenchmark?.title}
              </div>
              <div className="mt-4">
                {currentBenchmark?.text?.map((text, index) => (
                  <div key={index} className="text-left text-xl ">
                    {text === "" ? <div className="mt-4 mb-4"></div> : text}
                  </div>
                ))}
              </div>
              <button
                onClick={handleStopRecording}
                className="mt-8 w-full inline-flex items-center justify-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-red-500 hover:bg-red-600 font-londrina"
              >
                <StopCircleIcon className="inline-block h-12 w-12 mr-4" /> Stop
              </button>
            </div>
          ) : cameraOpen ? (
            <div className="bg-white px-6 lg:px-8 flex items-center h-screen justify-center">
              <div className="mx-auto max-w-2xl text-center">
                <div>
                  <div className="text-center text-4xl font-londrina font-semibold">
                    Read Clearly and Read Out Loud!
                  </div>
                  <button
                    onClick={handleRecordStart}
                    className="mt-8 w-full inline-flex items-center justify-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-green-500 hover:bg-green-600 font-londrina"
                  >
                    <PlayCircleIcon className="inline-block h-12 w-12 mr-4" />{" "}
                    Start
                  </button>
                </div>
              </div>
            </div>
          ) : lessonComplete ? (
            <div className="bg-white px-6 lg:px-8 flex items-center h-screen justify-center">
              <div className="mx-auto max-w-2xl text-center">
                <button
                  onClick={uploadBenchMarkWithUploadCare}
                  className="w-full flex items-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 animate-bounce font-londrina "
                >
                  <HomeIcon className="inline-block h-12 w-12 mr-4" />
                  Go Home
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white px-6 lg:px-8 flex items-center h-screen justify-center">
              <div className="mx-auto max-w-2xl text-center">
                <button
                  onClick={handleOpenWebcam}
                  className="w-full flex items-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 animate-bounce font-londrina "
                >
                  {loading ? (
                    <div className="z-50 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
                    </div>
                  ) : (
                    <>
                      <CursorArrowRippleIcon className="inline-block h-12 w-12 mr-4" />
                      Start Lesson
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  