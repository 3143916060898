import { useState } from "react";
import logo from "../assets/purple-long.png";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { current, setCurrent } = UserAuth();

  return (
    <header className="bg-gradient-to-r from-[#F8F8F8] from-10% via-red-50 via-30% to-teal-50 to-90%  sticky top-0 z-40">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5"
          onClick={() => {setCurrent(0)}}>
            <span className="sr-only">Lyrics2Learn At Home</span>
            <img className="h-12 w-auto" src={logo} alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link
            to="/"
            className={`text-sm font-semibold leading-6 text-gray-900 ${
              current === 0
                ? "border-b-2 border-indigo-500"
                : "border-b-2 border-transparent"
            }`}
            onClick={() => setCurrent(0)}
          >
            Home
          </Link>
          <Link
            to="/pricing"
            className={`text-sm font-semibold leading-6 text-gray-900 ${
              current === 1
                ? "border-b-2 border-indigo-500"
                : "border-b-2 border-transparent"
            }`}
            onClick={() => setCurrent(1)}
          >
            Pricing
          </Link>
          <Link
            to="/free-trial"
            className={`text-sm font-semibold leading-6 text-gray-900 ${
              current === 2
                ? "border-b-2 border-indigo-500"
                : "border-b-2 border-transparent"
            }`}
            onClick={() => setCurrent(2)}
          >
            Free Trial
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end">
          <Link
            to="/free-trial"
            type="button"
            className="relative hidden md:inline-flex items-center gap-x-1.5 rounded-md bg-red-400/90 px-8 py-3 text-md font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Start Free Trial
          </Link>
          <Link
            to="/login"
            type="button"
            className="ml-4 relative inline-flex items-center gap-x-1.5 rounded-md bg-teal-500 px-8 py-3 text-md font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Login<span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 " />
        <Dialog.Panel className="fixed inset-y-0 right-0  w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Lyrics2Learn at Home</span>
              <img className="h-8 w-auto" src={logo} alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-12 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Home
                </Link>
                <Link
                  to="/pricing"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Pricing
                </Link>
                <Link
                  to="/pricing"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Free Trial
                </Link>
              </div>
              <div className="py-6 z-50">
                <Link
                  to="/login"
                  type="button"
                  className="relative inline-flex items-center rounded-md bg-teal-500 px-8 py-3 text-md font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Login
                </Link>
              </div>
              <Link
                to="/free-trial"
                type="button"
                className="relative items-center gap-x-1.5 rounded-md bg-red-400/90 px-8 py-3 text-md font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 z-50"
                onClick={() => setMobileMenuOpen(false)}
              >
                Start Free Trial
              </Link>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
