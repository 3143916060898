import React from "react";

export default function Loading() {
  return (
    <div className="z-50">
      <div className="flex justify-center items-center h-screen w-full bg-violet-500">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
      </div>
    </div>
  );
}
