import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import files from "../../assets/Images";
import {
  ChevronDownIcon,
} from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function IconDropDown({icon, setIcon}) {

  const handleNewIcon = (index, e) => {
    e.preventDefault();
    setIcon(index);
  }
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Select New Icon
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 overflow-auto h-56">
            {files.map((file, index) => (
                <Menu.Item key={index}>
                    {({ active }) => (
                        <button
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm w-full'
                            )}
                            onClick={(e)=>{handleNewIcon(index, e)}}
                        >
                            <img src={file.source} className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" alt="icon" />
                            {file.title}
                        </button>
                    )}
                </Menu.Item>
            ))}
          </div>
         
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
