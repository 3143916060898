import lesson from "../assets/studentdashboard.png";
import awards from "../assets/lefty-speech.png";
import learning from "../assets/grading-example.png";
import parent from "../assets/parent-dashboard.png";
import scoring from "../assets/awardsexample.png";
import { Link } from "react-router-dom";

export default function MusicReading() {
  return (
    <div className="overflow-hidden bg-transparent py-16 lg:py-24">
      <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
   

        <div className="relative">
          <h2 className="text-center text-3xl font-gbold leading-8 tracking-tight text-violet-500 sm:text-4xl">
            Music+Reading = More Fun - More Memorable - More Effective
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-teal-500">
            Help your Child Excel and Develop a Love for Reading{" "}
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-gbold tracking-tight text-gray-600 sm:text-3xl">
              Thousands of activities and hundreds of read-along video lessons
              engaging kids with hip-hop, rock, jazz and blues reading practice
            </h3>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-red-100/90"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <img className="relative mx-auto" width={490} src={lesson} alt="" />
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-red-100/90"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-gbold tracking-tight text-gray-600 sm:text-3xl">
                Kids are guided through every lesson by "Lefty Lyric" as they
                earn awards and boost skills
              </h3>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <img
                className="relative mx-auto"
                width={300}
                src={awards}
                alt=""
              />
            </div>
          </div>
        </div>
   
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-gbold tracking-tight text-gray-600 sm:text-3xl">
              Kids have fun, earn rewards and build confidence with every
              lesson!
            </h3>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">

            <img
              className="relative mx-auto"
              width={490}
              src={scoring}
              alt=""
            />
          </div>
        </div>


        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-gbold tracking-tight text-gray-600 sm:text-3xl">
                Parents can track progress as students increase fluency,
                comprehension, and vocabulary skills
              </h3>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
          
              <img
                className="relative mx-auto"
                width={490}
                src={parent}
                alt=""
              />
            </div>
          </div>
        </div>
   
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-gbold tracking-tight text-gray-600 sm:text-3xl">
              Videos record your student’s progress as they read along and
              master each story through rhythm, rhyme, and recordings that show
              their improvement
            </h3>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
      
            <img
              className="relative mx-auto"
              width={490}
              src={learning}
              alt=""
            />
          </div>
        </div>
        <div className="text-center mt-24">
          <Link
            to="/free-trial"
            type="button"
            className="relative inline-flex items-center gap-x-1.5 rounded-md bg-red-400/90 px-12 py-5 text-2xl font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 z-30"
          >
            Start Free Trial
          </Link>
        </div>
      </div>
    </div>
  );
}
