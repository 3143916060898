import { BellIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { PlayCircleIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import ReactPlayer from "react-player";
import { handleWatchedVideo } from "../../config/lessonFunctions";

export default function Videos() {
  const { myStudents, tracker } = UserAuth();
  const [studentIndex, setStudentIndex] = useState(0);
  const [hasVideos, setHasVideos] = useState(false);
  const [completedTrackers, setCompletedTrackers] = useState([]);
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [videoIndex, setVideoIndex] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);

  const navigate = useNavigate();

  const handleNavigateToGrader = (index) => {
    navigate(`/grader/${index}`);
  };

  useEffect(() => {
    if (tracker.length > 0) {
      const completed = tracker.filter((item) => item.completed);
      setCompletedTrackers(tracker.filter((item) => item.completed));
      setVideoIndex(completed[0].hotReadVideo);
      setVideoTitle(completed[0].title);
    }
  }, [tracker]);

  useEffect(() => {
    if (completedTrackers.length > 0) {
      setHasVideos(true);
    }
  }, [completedTrackers]);

  const formatDate = (nanoseconds, seconds) => {
    const date = new Date(nanoseconds / 1000000 + seconds * 1000);
    return date.toLocaleDateString();
  };

  const handleViewVideo = (video, title) => {
    setOpen(true);
    setVideoUrl(video);
    setTitle(title);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSetVideo = (video, title, e, id, type) => {
    e.preventDefault();
    setVideoIndex(video);
    setVideoTitle(title);
    handleWatchedVideo(id, type);
    if (type === "cold") {
      completedTrackers.forEach((item) => {
        if (item.id === id) {
          item.coldReadViewed = true;
        }
      }
      )
    } else {
      completedTrackers.forEach((item) => {
        if (item.id === id) {
          item.hotReadViewed = true;
        }
      }
      )
    }
    scrollToTop();
  };

  console.log(videoIndex);

  return (
    <div className="flex min-h-screen h-full flex-col bg-indigo-900">
      <div className="mx-auto xl:flex w-full max-w-full items-start justify-center gap-x-8 px-14 py-10 sm:px-6 lg:px-8">
        <main>
          <ReactPlayer url={videoIndex} controls />

          <div className="flex items-center gap-x-2 mt-4">
            <div className="text-2xl font-bold col-span-4 text-white">
              {videoTitle}
            </div>
            <div className="ml-auto flex items-center gap-x-2">
              <div>Ungraded</div>
              <button>Share</button>
            </div>
          </div>
        </main>
        <aside className="flex gap-x-4">
          <div className="xl:sticky xl:top-8 hidden 2xl:w-96  w-48  shrink-0 sm:block">
            <h3 className="text-lg leading-6 font-bold text-blue-500 text-center bg-white w-full rounded-md">
              Cold Reads
            </h3>
            {completedTrackers?.map((file) => {
              return (
                <div
                  key={file.source}
                  className="relative group block 2xl:w-full w-48 overflow-hidden rounded-lg mt-2"
                >
                  <button
                    className="relative "
                    onClick={(e) => {
                      handleSetVideo(file.coldReadVideo, file.title, e, file.id, "cold");
                    }}
                  >
                    <video className="w-auto 2xl:h-56 h-36 rounded-2xl lg:px-2 px-1 ">
                      {" "}
                      {/* Set the height to 100% */}
                      <source src={file.coldReadVideo} type="video/mp4" />
                    </video>
                    <div className="absolute inset-0 bg-indigo-950 bg-opacity-50 rounded-lg transition-opacity group-hover:opacity-0"></div>

                    <div className="absolute inset-0 p-4 flex items-center justify-center group">
                      <PlayCircleIcon
                        className="h-10 w-10 text-white hidden group-hover:block"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="absolute inset-0 p-4 flex items-end justify-between">
                      <div>
                        <p className="text-sm font-medium text-white">
                          <span className="absolute inset-0"></span>
                          {file.title}
                        </p>
                      </div>
                      {file.coldReadViewed ? <BsCheck2Circle
                        className="h-10 w-10 text-emerald-500"
                        aria-hidden="true"
                      /> : <BsCircle
                        className="h-10 w-10 text-white"
                        aria-hidden="true"
                      />}

                    </div>
                  </button>
                </div>
              );
            })}
          </div>
          <div className="xl:sticky xl:top-8 hidden 2xl:w-96  w-48  shrink-0 sm:block">

            <h3 className="text-lg leading-6 font-bold text-red-500 text-center bg-white w-full rounded-md">
              Hot Reads
            </h3>
            {completedTrackers?.map((file) => {
              return (
                <div
                  key={file.source}
                  className="relative group block 2xl:w-full w-48  overflow-hidden rounded-lg mt-2"
                >
                  <button
                    className="relative"
                    onClick={(e) => {
                      handleSetVideo(file.hotReadVideo, file.title, e, file.id, "hot");
                    }}
                  >
                    <video className="w-auto 2xl:h-56 h-36 rounded-2xl lg:px-2  px-1  ">
                      {" "}
                      {/* Set the height to 100% */}
                      <source src={file.hotReadVideo} type="video/mp4" />
                    </video>
                    <div className="absolute inset-0 bg-indigo-950 bg-opacity-50 rounded-lg transition-opacity group-hover:opacity-0"></div>

                    <div className="absolute inset-0 p-4 flex items-center justify-center group">
                      <PlayCircleIcon
                        className="h-10 w-10 text-white hidden group-hover:block"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="absolute inset-0 p-4 flex items-end justify-between">
                      <div>
                        <p className="text-sm font-medium text-white">
                          <span className="absolute inset-0"></span>
                          {file.title}
                        </p>
                      </div>
                      {file.hotReadViewed ? <BsCheck2Circle
                        className="h-10 w-10 text-emerald-500"
                        aria-hidden="true"
                      /> : <BsCircle
                        className="h-10 w-10 text-white"
                        aria-hidden="true"
                      />}
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </aside>
      </div>
    </div>
  );
}
