import React, { useEffect, useState } from "react";
import { lessons } from "../../assets/lessons/lessonsALL";
import { UserAuth } from "../../context/AuthContext";
import { CheckIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import { findLessonByTitle, getVideoForLesson, handleAssignLessons, startFirstLesson } from "../../config/lessonFunctions";
import Video from "../../students/components/Video";
import { useRef } from "react";
import Loading from "../../components/Loading";

export default function AssignLesson() {
  const { myStudents } = UserAuth();
  const [lessonIndex, setLessonIndex] = useState("k");
  const [assignedLessons, setAssignedLessons] = useState([]);
  const [studentIndex, setStudentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false)
  const [newgrade, setGrade] = useState(myStudents[studentIndex]?.gradeLevel)

  const [video, setVideo] = useState(null);
  const videoRef = useRef(null);


useEffect(() => {
  setGrade(myStudents[studentIndex]?.gradeLevel)
}, [studentIndex])

  useEffect(() => {
    if (myStudents[studentIndex]?.assignedLessons?.length > 0) {
      setAssignedLessons(myStudents[studentIndex]?.assignedLessons);
    } else {
      setAssignedLessons([]);
    }
  }, [studentIndex]);

  const handleAssignLesson = (lesson, gradeLevel) => {
    const newLesson = {
      title: lesson.title,
      gradeLevel: gradeLevel,
      assignedBy: "parent",
      assignedById: myStudents[0]?.parentId,
      assignedDate: new Date().getTime(),
      completed: false,
      currentDay: 0,
      days: ["day1", "day2", "day3"],
    };

    if (
      assignedLessons?.find(
        (assignedLesson) => assignedLesson.title === lesson.title
      )
    ) {
      const newAssignedLessons = assignedLessons.filter(
        (assignedLesson) => assignedLesson.title !== lesson.title
      );
      setAssignedLessons(newAssignedLessons);
    } else {
      setAssignedLessons([...assignedLessons, newLesson]);
    }
  };


  const handleSave = () => {
    try {
      handleAssignLessons(
        assignedLessons,
        myStudents[studentIndex]?.studentId,
        myStudents[studentIndex]?.parentId
      );
    } catch (error) {
      console.log(error);
    }
  };

  const numericStringMap = {
    k: "k",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
  };

  const getGradeSuffix = (gradeLevel) => {
    if (gradeLevel === "k") {
      return ""; // Blank for kindergarten
    } else if (gradeLevel === "1") {
      return "st";
    } else if (gradeLevel === "2") {
      return "nd";
    } else if (gradeLevel === "3") {
      return "rd";
    } else if (gradeLevel === "4" || gradeLevel === "5") {
      return "th";
    } else {
      return "th"; // Default to "th" for other cases
    }
  };


  const openNewTab = (url) => {
    // Use window.open() to open a new tab or window with the specified URL
    window.open(url, '_blank');
  };
  

  const handleVideo = (video, grade) => {
    setIsLoading(true)


    findLessonByTitle(video, grade).then((res) => {
      openNewTab(res);
      setIsLoading(false)

    });




    // const videoRef = getVideoForLesson(video);

  };

  console.log(lessons)
  return (
    <>
      {isLoading ? (
        <div><Loading /></div>
      ) : isVideo ? (
        <div>
        <div className="text-center sm:mt-5">
          <div className="">
            <video
              className="mx-auto rounded-lg drop-shadow-2xl md:p-0 p-4"
              controls
            >
              <source src={video} type="video/mp4" controls />
            </video>
          </div>
        </div>
      </div>
      ) : (
        <div className="md:px-6 px-4 mt-12 h-full" >

          <h1 className="text-3xl font-bold text-center">Assign Lessons</h1>
          <p className="text-xl text-center">Assign lessons to your students</p>
          <div className="w-1/4 mx-auto">
            <select
              id="student"
              name="student"
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={myStudents[0]?.firstName}
              onChange={(e) => {
                setStudentIndex(e.target.value);
              }}
            >
              {myStudents?.map((student, index) => (
                <option key={index} value={index}>
                  {student.firstName}
                </option>
              ))}
            </select>
          </div>
          <div className="text-center mt-6">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
              onClick={handleSave}
            >
              Save
            </button>
          </div>

          <div className="grid grid-cols-9 gap-4 mt-6">
            <div className="col-span-6 row-span-2">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:p-6">
                  <h2 className="text-2xl font-bold mb-4">
                    Current Student Track - {myStudents[studentIndex]?.gradeLevel}
                    {getGradeSuffix(myStudents[studentIndex]?.gradeLevel)}{" "}
                  </h2>
                  <h4 className="text-xl font-semibold mb-4">Assigned Lessons</h4>
                  {assignedLessons?.map((lesson, index) => (
                    <div className="border-t-2 p-4 flex items-center bg-gray-50">
                      <h3 className="text-lg font-semibold">• {lesson.title}</h3>
                      <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 ml-auto">
                        Assigned By Parent
                      </span>
                    </div>
                  ))}

                  {lessons[
                    numericStringMap[myStudents[studentIndex]?.gradeLevel]
                  ]?.map((lesson, index) => (
                    <div className="border-t-2 p-4 flex items-center bg-gray-50">
                      <Video open={open} setOpen={setOpen} video={video} />
                      <h3 className="text-lg font-semibold">
                        • Week {index + 1}: {lesson.title} <button onClick={() => { handleVideo(lesson.title, myStudents[studentIndex]?.gradeLevel) }}><PlayCircleIcon className="inline-block text-emerald-600 h-5 w-5 ml-2" /></button>
                      </h3>
                      <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 ml-auto">
                        Assigned In Track
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:p-6">
                  <h2 className="text-2xl font-bold mb-4">All Lessons</h2>
                  <div>
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Grade Level
                    </label>
                    <select
                      id="location"
                      name="location"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-6"
                      defaultValue="Select a grade level"
                      onChange={(e) => setLessonIndex(e.target.value)}
                    >
                      <option disabled>Select a grade level</option>
                      <option value="k">Kindergarten</option>
                      <option value="1">1st Grade</option>
                      <option value="2">2nd Grade</option>
                      <option value="3">3rd Grade</option>
                      <option value="4">4th Grade</option>
                      <option value="5">5th Grade</option>
                    </select>
                  </div>

                  {lessons[numericStringMap[lessonIndex]]?.map((lesson, index) => (
                    <div className="border-t-2 p-4 flex items-center bg-gray-50">
                      <h3 className="md:text-md text-sm font-semibold">
                        {" "}
                        <span className="font-normal">{lesson.title}</span>
                      </h3>
                      <span
                        className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium  ring-1 ring-inset ml-1 ${myStudents[studentIndex]?.gradeLevel === lessonIndex ||
                          assignedLessons?.find(
                            (assignedLesson) =>
                              assignedLesson.title === lesson.title
                          )
                          ? "bg-green-50 text-green-700 ring-green-600/20 pr-1"
                          : "bg-red-50 text-red-700 ring-red-600/20 pr-1"
                          }`}
                      >
                        {myStudents[studentIndex]?.gradeLevel === lessonIndex ||
                          assignedLessons?.find(
                            (assignedLesson) => assignedLesson.title === lesson.title
                          )
                          ? "Assigned"
                          : "Unassigned"}
                      </span>
                      <button onClick={() => { handleVideo(lesson.title, lessonIndex) }}><PlayCircleIcon className="inline-block text-emerald-600 h-5 w-5 ml-2" /></button>
                      {myStudents[studentIndex]?.gradeLevel === lessonIndex ? (
                        <div className="ml-auto text-green-600 font-semibold py-2 px-4 rounded flex items-center">
                          Current Track{" "}
                          <CheckIcon className="inline-block h-4 w-4 ml-2" />{" "}
                        </div>

                      ) : (
                        <button
                          className={`ml-auto ${assignedLessons?.find(
                            (assignedLesson) =>
                              assignedLesson.title === lesson.title
                          )
                            ? "bg-red-500 hover:bg-red-600"
                            : "bg-green-500 hover:bg-green-600"
                            }  text-white font-semibold py-2 px-4 rounded`}
                          onClick={() => handleAssignLesson(lesson, lessonIndex)}
                        >
                          {assignedLessons?.find(
                            (assignedLesson) =>
                              assignedLesson.title === lesson.title
                          )
                            ? "Unassign"
                            : "Assign"}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:p-6">{/* Content goes here */}</div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
