import {
  ArrowLeftIcon,
  ArrowRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { updateAttempt, updateStudentScores } from "../../config/lessonFunctions";
import { SpeakerWaveIcon } from "@heroicons/react/24/outline";

const Quiz = () => {
  const { currentLesson, studentProfile, startTime, currentVideo } = UserAuth();
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [currentScores, setCurrentScores] = useState([]);
  const [selectedAnserIndex, setSelectedAnswerIndex] = useState(null);
  const [answerSelected, setAnswerSelected] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let scoreAvg = currentScores.reduce((a, b) => a + b, 0);
    setScore(scoreAvg);
  }, [currentScores]);

  // const handleAnswerOptionClick = (isCorrect) => {

  //   if (isCorrect) {
  //     setCurrentScores([...currentScores, 1]);
  //   } else {
  //     setCurrentScores([...currentScores, 0]);
  //   }

  //   const nextQuestion = currentQuestion + 1;
  //   if (nextQuestion < currentLesson?.quiz?.length) {
  //     setCurrentQuestion(nextQuestion);
  //   } else {
  //     setShowScore(true);
  //   }
  // };

  const handleAnswerOptionClick = (index) => {
    setAnswerSelected(true);
  };
  const handleNextQuestion = () => {
    if (
      selectedAnserIndex ===
      parseInt(currentLesson?.quiz[currentQuestion]?.correctAnswer)
    ) {
      setCurrentScores([...currentScores, 1]);
    } else {
      setCurrentScores([...currentScores, 0]);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < currentLesson?.quiz?.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  const handleGoBackOneQuestion = () => {
    const newScoreArr = [...currentScores]; // Create a copy of the score array
    newScoreArr.pop(); // Remove the last element from the copy

    setCurrentScores(newScoreArr);
    setCurrentQuestion(currentQuestion - 1);
  };

  const [awardDomains, setAwardDomains] = useState(4);

  const handleScore = () => {
    const finalScore = (score / currentLesson?.quiz?.length) * 100;
    const finalTime = new Date().getTime() - startTime;

    if (finalScore >= 80) {
      setAwardDomains(4);
      gradeQuiz(finalScore, finalTime);
    } else if (finalScore >= 65) {
      setAwardDomains(3);
      gradeQuiz(finalScore, finalTime);
    } else if (finalScore >= 60) {
      setAwardDomains(2);
      gradeQuiz(finalScore, finalTime);
    } else {
      setAwardDomains(1);
      updateAttempt(studentProfile?.studentId);
      navigate("/student/dashboard");
    }
  };

  const [starScore, setStarScore] = useState(4);

  useEffect(() => {
    const finalScore = (score / currentLesson?.quiz?.length) * 100;
    if (finalScore >= 80) {
      setStarScore(3);
    } else if (finalScore >= 65) {
      setStarScore(2);
    } else if (finalScore >= 60) {
      setStarScore(1);
    } else {
      setStarScore(0);
    }
  }, [score, currentLesson?.quiz?.length]);

  const gradeQuiz = (finalScore, time) => {
    if (studentProfile?.lessonsComplete === 2) {
      const lessonRef = studentProfile?.lessonsAssigned[0]?.path;
      updateStudentScores(
        studentProfile?.studentId,
        finalScore,
        1,
        studentProfile?.parentId,
        starScore,
        false,
        lessonRef,
        time,
        currentVideo,
        currentLesson.lessonNumber,
        currentLesson?.quiz?.length,
        score
      );
      navigate(`/award/book/1`);
    } else if (studentProfile?.currentLesson === 0) {
      const lessonRef = studentProfile?.lessonsAssigned[2]?.path;
      updateStudentScores(
        studentProfile?.studentId,
        finalScore,
        null,
        studentProfile?.parentId,
        starScore,
        false,
        lessonRef,
        time,
        currentVideo,
        currentLesson.lessonNumber,
        currentLesson?.quiz?.length,
        score

      );
      navigate(`/student/dashboard/completed/${starScore}`);
    } else if (studentProfile?.currentLesson === 1) {
      const lessonRef = studentProfile?.lessonsAssigned[1]?.path;
      updateStudentScores(
        studentProfile?.studentId,
        finalScore,
        null,
        studentProfile?.parentId,
        starScore,
        false,
        lessonRef,
        time,
        currentVideo,
        currentLesson.lessonNumber,
        currentLesson?.quiz?.length,
        score
      );
      navigate(`/student/dashboard/completed/${starScore}`);
    } else {
      const lessonRef = studentProfile?.lessonsAssigned[2]?.path;
      updateStudentScores(
        studentProfile?.studentId,
        finalScore,
        null,
        studentProfile?.parentId,
        starScore,
        true,
        lessonRef,
        time,
        currentVideo,
        currentLesson.lessonNumber,
        currentLesson?.quiz?.length,
        score

      );
      navigate(`/student/dashboard/completed/${starScore}`);
    }
  };

  const [hiddenIndexes, setHiddenIndexes] = useState([]);

  const handleHide = (index) => {
    if (hiddenIndexes.includes(index)) {
      const newHiddenIndexes = hiddenIndexes.filter((item) => item !== index);
      setHiddenIndexes(newHiddenIndexes);
    } else {
      setHiddenIndexes([...hiddenIndexes, index]);
    }
  };

  // const [voices, setVoices] = useState([]);
  // const [selectedVoice, setSelectedVoice] = useState(null);

  // useEffect(() => {
  //   // Fetch and set the available voices when the component mounts
  //   const fetchVoices = () => {
  //     const synth = window.speechSynthesis;
  //     const availableVoices = synth.getVoices();
  //     setVoices(availableVoices);
  //   };

  //   // Add an event listener to update the available voices when they change
  //   window.speechSynthesis.onvoiceschanged = fetchVoices;

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.speechSynthesis.onvoiceschanged = null;
  //   };
  // }, []);

  // // Handle the voice change event
  // const handleVoiceChange = (e) => {
  //   const selectedVoiceName = e.target.value;
  //   const selectedVoice = voices.find(
  //     (voice) => voice.name === selectedVoiceName
  //   );
  //   setSelectedVoice(selectedVoice);
  // };

  // Speak the question when the user hovers over it
  const speakQuestion = (question) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(question);
    utterance.voice = synth.getVoices()[4];
    synth.speak(utterance);
  };

  return (
    <div className="quiz">
      {showScore ? (
        <>
          <div className="score-section font-londrina text-7xl text-center">
            You scored <span className="text-emerald-700">{score}</span> out of{" "}
            <span className="text-emerald-700">
              {currentLesson?.quiz?.length}
            </span>
            !
          </div>
        <div className="score-section text-blue-500 font-bold text-8xl text-center">
            {((score / currentLesson?.quiz?.length) * 100).toFixed(0)}%
          </div>
          <button
            className="p-4 bg-yellow-300 text-7xl font-londrina mx-auto mt-4 rounded-md w-full hover:bg-yellow-500"
            onClick={handleScore}
          >
            Next
          </button>
          {(score / currentLesson?.quiz?.length) * 100 < 60 && (
            <div className="score-section font-londrina text-7xl text-center">
              You need to score at least 60% to pass the quiz.
            </div>
          )}
        </>
      ) : (
        <>
          <div className="question-section">
            {/* <select name="voice" onChange={handleVoiceChange}>
                {voices.map((voice) => (
                  <option key={voice.name} value={voice.name}>
                    {voice.name}
                  </option>
                ))}
              </select> */}
            <div className="text-2xl font-bold font-londrina">
              <span>Question {currentQuestion + 1}</span>/
              {currentLesson?.quiz?.length}
            </div>
            <div className="text-4xl font-bold">
              {currentLesson?.quiz[currentQuestion]?.question}{" "}
              <button
                onClick={() =>
                  speakQuestion(currentLesson?.quiz[currentQuestion]?.question)
                }
              >
                <SpeakerWaveIcon className="h-6 inline-block hover:text-blue-500" />
              </button>
            </div>
          </div>
          <div className="answer-section mt-4 grid grid-cols-1 gap-4">
            {currentLesson?.quiz[currentQuestion]?.answers?.map(
              (answerOption, index) => (
                <div className="flex items-center">
                  <button
                    className="hover:text-red-500"
                    onClick={() => handleHide(index)}
                  >
                    <XMarkIcon className="h-6 inline-block mr-2" />
                  </button>
                  <button
                    key={index}
                    className={`p-4 ${
                      hiddenIndexes.includes(index)
                        ? "bg-red-500 line-through hover:bg-red-700"
                        : selectedAnserIndex === index
                        ? "bg-indigo-500 hover:bg-indigo-700"
                        : "bg-emerald-500 hover:bg-emerald-700"
                    } rounded-full text-white capitalize text-2xl font-bold  w-full`}
                    // onClick={() =>
                    //   handleAnswerOptionClick(
                    //     index ===
                    //       parseInt(
                    //         currentLesson?.quiz[currentQuestion]?.correctAnswer
                    //       )
                    //   )
                    // }
                    onClick={() => {
                      handleAnswerOptionClick(index);
                      setSelectedAnswerIndex(index);
                    }}
                    disabled={hiddenIndexes.includes(index)}
                  >
                    {answerOption}
                  </button>
                  <button onClick={() => speakQuestion(answerOption)}>
                    <SpeakerWaveIcon className="h-6 ml-4 inline-block hover:text-blue-500" />
                  </button>
                </div>
              )
            )}
          </div>
          <div className="flex items-center">
            {currentQuestion > 0 && (
              <button
                className="bg-blue-500 hover:bg-blue-400 text-white text-lg font-londrina p-4 rounded-full mt-4 flex items-center"
                onClick={() => {
                  handleGoBackOneQuestion();
                }}
              >
                <ArrowLeftIcon className="h-6 flex mr-2" /> Go Back
              </button>
            )}
            {answerSelected && (
              <button
                className="bg-yellow-500 hover:bg-yellow-400 text-white text-lg font-londrina p-4 rounded-full mt-4 flex items-center ml-auto"
                onClick={() => {
                  handleNextQuestion();
                  setSelectedAnswerIndex(null);
                  setAnswerSelected(false);
                }}
              >
                Next <ArrowRightIcon className="h-6 flex ml-2" />
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Quiz;
