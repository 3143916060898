import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  BookOpenIcon,
  CheckCircleIcon,
  PuzzlePieceIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { UserAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { Timestamp, serverTimestamp } from "firebase/firestore";
import Video from "../../students/components/Video";

const secondaryNavigation = [
  { name: "Last 7 days", href: "#", current: true },
  { name: "Last 30 days", href: "#", current: false },
  { name: "Last 4 Months", href: "#", current: false },
  { name: "All Time", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Reports() {
  const { myStudents, tracker } = UserAuth();
  const [studentIndex, setStudentIndex] = useState(0);
  const [avg, setAvg] = useState(0);
  const [totalDayOneAsked, setTotalDayOneAsked] = useState(0);
  const [totalDayOneCorrect, setTotalDayOneCorrect] = useState(0);
  const [totalDayTwoAsked, setTotalDayTwoAsked] = useState(0);
  const [totalDayTwoCorrect, setTotalDayTwoCorrect] = useState(0);
  const [totalDayThreeAsked, setTotalDayThreeAsked] = useState(0);
  const [totalDayThreeCorrect, setTotalDayThreeCorrect] = useState(0);
  const [newTracker, setNewTracker] = useState([]);
  const [currentTimeLine, setCurrentTimeLine] = useState(0);
  const [open, setOpen] = useState(false);
  const [videoFile, setVideoFile] = useState(null);

  const [filteredTracker, setFilteredTracker] = useState([]);

  const handleNewTracker = (studentID) => {
    const newTracker = tracker?.filter(
      (lesson) => lesson.studentId === studentID
    );
    setNewTracker(newTracker);
  };

  

  useEffect(() => {
    handleNewTracker(myStudents[studentIndex]?.studentId);
  }, [studentIndex, myStudents]);

  useEffect(() => {
    // Get the current date as a Firestore Timestamp
    const currentDateTimestamp = Timestamp.now();

    console.log(currentDateTimestamp);

    // Calculate the date threshold based on currentTimeLine
    let dateThresholdTimestamp = currentDateTimestamp;
    if (currentTimeLine === 0) {
      dateThresholdTimestamp =
        dateThresholdTimestamp.seconds - 7 * 24 * 60 * 60; // Subtract 7 days in seconds
    } else if (currentTimeLine === 1) {
      dateThresholdTimestamp =
        dateThresholdTimestamp.seconds - 30 * 24 * 60 * 60; // Subtract 30 days in seconds
    } else if (currentTimeLine === 2) {
      dateThresholdTimestamp =
        dateThresholdTimestamp.seconds - 4 * 30 * 24 * 60 * 60; // Subtract 4 months in seconds
    } else {
      dateThresholdTimestamp = 0; // All time
    }

    // Filter the tracker data based on the date threshold
    const filteredTracker = newTracker?.filter((lesson) => {
      const lessonDateTimestamp = lesson.lastActiveDate;
      console.log(dateThresholdTimestamp);
      return lessonDateTimestamp.seconds >= dateThresholdTimestamp;
    });

    setFilteredTracker(filteredTracker);
  }, [currentTimeLine, newTracker]);

  const totals = [
    {
      id: 1,
      name: "Day 1 Literal",
      imageUrl: (
        <BookOpenIcon className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 text-teal-400" />
      ),
      dayscores: {
        correct: totalDayOneCorrect,
        total: totalDayOneAsked,
        mastery: (totalDayOneCorrect / totalDayOneAsked) * 100 || 0,
        status: "Paid",
      },
    },
    {
      id: 2,
      name: "Day 2 Inference",
      imageUrl: (
        <RocketLaunchIcon className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 text-red-400/90" />
      ),
      dayscores: {
        correct: totalDayTwoCorrect,
        total: totalDayTwoAsked,
        mastery: (totalDayTwoCorrect / totalDayTwoAsked) * 100 || 0,
        status: "Overdue",
      },
    },
    {
      id: 3,
      name: "Day 3 Complex",
      imageUrl: (
        <PuzzlePieceIcon className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 text-amber-400" />
      ),
      dayscores: {
        correct: totalDayThreeCorrect,
        total: totalDayThreeAsked,
        mastery: (totalDayThreeCorrect / totalDayThreeAsked) * 100 || 0,
        status: "Paid",
      },
    },
  ];

  const handleVideo = (video) => {
    setVideoFile(video);
    setOpen(true);
  }

  useEffect(() => {
    const dayOneAsked = filteredTracker?.map(
      (lesson) => lesson.totalQuestionsAsked1
    );
    const dayOneCorrect = filteredTracker?.map(
      (lesson) => lesson.totalQuestions1
    );
    const dayTwoAsked = filteredTracker?.map(
      (lesson) => lesson.totalQuestionsAsked2
    );
    const dayTwoCorrect = filteredTracker?.map(
      (lesson) => lesson.totalQuestions2
    );
    const dayThreeAsked = filteredTracker?.map(
      (lesson) => lesson.totalQuestionsAsked3
    );
    const dayThreeCorrect = filteredTracker?.map(
      (lesson) => lesson.totalQuestions3
    );

    setTotalDayOneAsked(dayOneAsked.reduce((a, b) => a + b, 0));
    setTotalDayOneCorrect(dayOneCorrect.reduce((a, b) => a + b, 0));
    setTotalDayTwoAsked(dayTwoAsked.reduce((a, b) => a + b, 0));
    setTotalDayTwoCorrect(dayTwoCorrect.reduce((a, b) => a + b, 0));
    setTotalDayThreeAsked(dayThreeAsked.reduce((a, b) => a + b, 0));
    setTotalDayThreeCorrect(dayThreeCorrect.reduce((a, b) => a + b, 0));
  }, [filteredTracker]);

  useEffect(() => {
    const average =
      Math.round(myStudents[studentIndex]?.grades.reduce((a, b) => a + b, 0)) /
      myStudents[studentIndex]?.grades?.length;
    if (isNaN(average)) {
      setAvg(0);
    } else {
      setAvg(average);
    }
  }, [studentIndex]);

  const comprehensionPracticeTimeTotal = filteredTracker.reduce(
    (total, lesson) => {
      return total + lesson.comprehensionPracticeTime;
    },
    0
  );

  const fluencyPracticeTimeTotal = filteredTracker.reduce((total, lesson) => {
    return total + lesson.fluencyPracticeTime;
  }, 0);

  const totalPracticeTime = ((comprehensionPracticeTimeTotal + fluencyPracticeTimeTotal) / 600000 ).toFixed(0);

  const stats = [
    {
      name: "Lessons Complete",
      value: filteredTracker.filter((lesson) => lesson.completed).length,
      change: "+4.75%",
      changeType: "positive",
    },
    {
      name: "Time Spent Reading",
      value:
      totalPracticeTime  +
        " minutes",
      change: "+54.02%",
      changeType: "negative",
    },
    {
      name: "Remaining Lessons in Track",
      value:
        myStudents[studentIndex]?.lastLesson -
        myStudents[studentIndex]?.currentLessonReference +
        " lessons",
      change: "-1.39%",
      changeType: "positive",
    },
    {
      name: "Average Overall Score",
      value: avg?.toFixed(2) + "%",
      change: "+10.18%",
      changeType: "negative",
    },
  ];

  const findLessonScore = (lessonScores) => {
    const scoreAverage = lessonScores.reduce((a, b) => a + b, 0);
    const average = scoreAverage / lessonScores.length;
    return average;
  };

  return (
    <>
      <main className="bg-gray-50">
        <div className="relative isolate overflow-hidden max-w-7xl mx-auto ">
          <header className="pb-4 pt-6 sm:pb-6">
            <div className="flex items-center">
              <h1 className="text-3xl font-bold text-gray-900">
                Student Data Report
              </h1>
              <div className="ml-3">
                <select
                  id="student"
                  name="student"
                  className=" block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                  defaultValue={myStudents[0]?.firstName}
                  onChange={(e) => {
                    setFilteredTracker([]); setStudentIndex(e.target.value); 
                  }}
                >
                  {myStudents?.map((student, index) => (
                    <option key={index} value={index}>
                      {student.firstName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8 mt-12">
              <h1 className="text-base font-semibold leading-7 text-gray-900">
                {myStudents[studentIndex]?.firstName}
              </h1>
              <div className="order-last flex w-full gap-x-8 text-lg font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                {secondaryNavigation.map((item, index) => (
                  <button
                    key={item.name}
                    className={
                      index === currentTimeLine
                        ? "text-indigo-600"
                        : "text-gray-700"
                    }
                    onClick={() => setCurrentTimeLine(index)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {stats.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1
                      ? "sm:border-l"
                      : statIdx === 2
                      ? "lg:border-l"
                      : "",
                    "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                  )}
                >
                  <dt className="text-lg font-medium leading-6 text-gray-500">
                    {stat.name}
                  </dt>

                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
            aria-hidden="true"
          >
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#00ff88] to-[#9089FC]"
              style={{
                clipPath:
                  "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
              }}
            />
          </div>
        </div>

        <div className="space-y-16  xl:space-y-20 bg-white">
          {/* Recent activity table */}
          <div>
            <div className="overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead>
                      <tr className="text-lg leading-6 text-gray-900">
                        <th
                          scope="colgroup"
                          colSpan={3}
                          className="relative isolate py-2 font-semibold"
                        >
                          <div>Scores by Lesson</div>
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        </th>
                      </tr>

                      <tr>
                        <th className="py-4">Lesson</th>
                        <th>Cold Read</th>
                        <th>Day 1 Literal</th>
                        <th>Day 2 Inference</th>
                        <th>Day 3 Complex</th>
                        <th className="text-right">Hot Read</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {filteredTracker?.map((lesson) => (
                     
                       
                        <tr key={lesson.id}>
                          <td className="relative py-5 pr-6">
                            <div className="flex gap-x-6">
                              <div className="flex-auto">
                                <div className="flex items-start gap-x-3">
                                  <div className="text-lg font-medium leading-6 text-gray-900">
                                    {lesson.title}
                                  </div>
                                  <div
                                    className={classNames(
                                      "rounded-md py-1 px-2 text-lg font-medium ring-1 ring-inset"
                                    )}
                                  >
                                    {lesson.completed
                                      ? findLessonScore(
                                          lesson.lessonScores
                                        ).toFixed(2) + "%"
                                      : "In Progress"}
                                  </div>
                                </div>

                                <div className="mt-1 text-lg leading-5 text-gray-500">
                                  Week {lesson.week}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="py-5 ">
                            <div>
                              {lesson.coldReadVideo ? (
                                <button className="text-lg font-medium leading-6 text-indigo-600 hover:text-indigo-500"
                                onClick={() => handleVideo(lesson.coldReadVideo)}
                                >
                                View
                              </button>
                              ) : (
                                <div className="text-lg font-medium leading-6 text-teal-600 ">In Progress</div>
                              )}
                              
                            </div>
                          </td>
                          <td>
                            {lesson.lessonScores[0] ? (
                              <span
                                className={`${
                                  lesson.lessonScores[0] > 80
                                    ? "text-green-800 bg-green-100"
                                    : lesson.lessonScores[0] > 60
                                    ? "text-yellow-800 bg-yellow-100"
                                    : "text-red-800 bg-red-100"
                                } inline-flex items-center gap-x-1.5 rounded-md  px-2 py-1 text-lg font-medium  w-20 justify-center`}
                              >
                                <svg
                                  className={`${
                                    lesson.lessonScores[0] > 80
                                      ? "fill-green-500"
                                      : lesson.lessonScores[0] > 60
                                      ? "fill-yellow-500"
                                      : "fill-red-500"
                                  } h-1.5 w-1.5 `}
                                  viewBox="0 0 6 6"
                                  aria-hidden="true"
                                >
                                  <circle cx={3} cy={3} r={3} />
                                </svg>
                                {(lesson.lessonScores[0])?.toFixed(0)}%
                              </span>
                            ) : (
                              <div className="text-lg font-medium leading-6 text-gray-900 rounded-full h-8 w-8 border border-gray-700 ml-8"></div>
                            )}
                          </td>
                          <td>
                            {lesson.lessonScores[1] ? (
                              <span
                                className={`${
                                  lesson.lessonScores[1] > 80
                                    ? "text-green-800 bg-green-100"
                                    : lesson.lessonScores[0] > 60
                                    ? "text-yellow-800 bg-yellow-100"
                                    : "text-red-800 bg-red-100"
                                } inline-flex items-center gap-x-1.5 rounded-md  px-2 py-1 text-lg font-medium  w-20 justify-center`}
                              >
                                <svg
                                  className={`${
                                    lesson.lessonScores[1] > 80
                                      ? "fill-green-500"
                                      : lesson.lessonScores[1] > 60
                                      ? "fill-yellow-500"
                                      : "fill-red-500"
                                  } h-1.5 w-1.5 `}
                                  viewBox="0 0 6 6"
                                  aria-hidden="true"
                                >
                                  <circle cx={3} cy={3} r={3} />
                                </svg>
                                {(lesson.lessonScores[1])?.toFixed(0)}%
                              </span>
                            ) : (
                              <div className="text-lg font-medium leading-6 text-gray-900 rounded-full h-8 w-8 border border-gray-700 ml-8"></div>
                            )}
                          </td>
                          <td>
                            {lesson.lessonScores[2] ? (
                              <span
                                className={`${
                                  lesson.lessonScores[2] > 80
                                    ? "text-green-800 bg-green-100"
                                    : lesson.lessonScores[0] > 60
                                    ? "text-yellow-800 bg-yellow-100"
                                    : "text-red-800 bg-red-100"
                                } inline-flex items-center gap-x-1.5 rounded-md  px-2 py-1 text-lg font-medium  w-20 justify-center`}
                              >
                                <svg
                                  className={`${
                                    lesson.lessonScores[2] > 80
                                      ? "fill-green-500"
                                      : lesson.lessonScores[2] > 60
                                      ? "fill-yellow-500"
                                      : "fill-red-500"
                                  } h-1.5 w-1.5 `}
                                  viewBox="0 0 6 6"
                                  aria-hidden="true"
                                >
                                  <circle cx={3} cy={3} r={3} />
                                </svg>
                                {(lesson.lessonScores[2])?.toFixed(0)}
                              </span>
                            ) : (
                              <div className="text-lg font-medium leading-6 text-gray-900 rounded-full h-8 w-8 border border-gray-700 ml-8"></div>
                            )}
                          </td>
                          <td className="py-5 text-right">
                            <div className="flex justify-end">
                              <Link className="text-lg font-medium leading-6 text-indigo-600 hover:text-indigo-500">
                                Grade
                              </Link>
                            </div>
                          </td>
                        </tr>
                      
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Comprehension Scores*/}
          <div className="mx-auto max-w-7xl px-4  sm:px-6 lg:px-8 ">
          <Video open={open} setOpen={setOpen} video={videoFile} />
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="text-lg leading-6 text-gray-900">
                <div className="relative isolate py-2 font-semibold">
                  <div>Comprehension Scores</div>
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                </div>
              </div>

              <div
                role="list"
                className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
              >
                {totals?.map((client) => (
                  <div
                    key={client.id}
                    className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-lg shadow-gray-300"
                  >
                    <div
                      className={`flex items-center gap-x-4 border-b border-gray-900/5  p-6 ${
                        client.id === 1
                          ? "bg-teal-50"
                          : client.id === 2
                          ? "bg-red-50"
                          : "bg-amber-50"
                      }`}
                    >
                      {client.imageUrl}

                      <div className="text-lg font-medium leading-6 text-gray-900">
                        {client.name}
                      </div>
                      <Menu as="div" className="relative ml-auto">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <QuestionMarkCircleIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/"
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block px-3 py-1 text-lg leading-6 text-gray-900"
                                  )}
                                >
                                  View
                                  <span className="sr-only">
                                    , {client.name}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/"
                                  className={classNames(
                                    active ? "bg-gray-50" : "",
                                    "block px-3 py-1 text-lg leading-6 text-gray-900"
                                  )}
                                >
                                  Edit
                                  <span className="sr-only">
                                    , {client.name}
                                  </span>
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-lg leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Correct/Total</dt>
                        <dd className="text-gray-700">
                          <div className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-lg font-medium text-blue-700">
                            {client.dayscores.correct}/{client.dayscores.total}
                          </div>
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Mastery</dt>
                        <dd className="flex items-start gap-x-2">
                          <div
                            className={`${
                              client.dayscores.mastery >= 80
                                ? "text-green-700 bg-green-50 ring-green-600/20"
                                : client.dayscores.mastery >= 60
                                ? "text-yellow-600 bg-yellow-50 ring-yellow-500/10"
                                : "text-red-700 bg-red-50 ring-red-600/10"
                            } rounded-md py-1 px-2 text-lg font-medium ring-1 ring-inset`}
                          >
                            {(client.dayscores.mastery).toFixed(0)}%
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead>
                      <tr className="text-lg leading-6 text-gray-900">
                        <th
                          scope="colgroup"
                          colSpan={3}
                          className="relative isolate py-2 font-semibold"
                        >
                          <div>Weekly Goal Completion</div>
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        </th>
                      </tr>

                      <tr>
                        <th className="py-4">Lesson</th>
                        <th>Lessons Complete</th>
                        <th>Goal Met</th>
                        <th>Fluency Practice</th>
                        <th>Comprehension Practice</th>
                        <th className="text-right">Total Reading Time</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {filteredTracker?.map((lesson) => (
                        <tr key={lesson.id}>
                          <td className="relative py-5 pr-6">
                            <div className="flex gap-x-6">
                              <div className="flex-auto">
                                <div className="flex items-start gap-x-3">
                                  <div className="text-lg font-medium leading-6 text-gray-900">
                                    {lesson.title}
                                  </div>
                                </div>

                                <div className="mt-1 text-lg leading-5 text-gray-500">
                                  Week 1
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="py-5 ">
                            {lesson.lessonScores.length}/3
                          </td>
                          <td>
                            {lesson.lessonScores.length === 3 ? (
                              <CheckCircleIcon className="h-5 w-5 text-green-500" />
                            ) : (
                              <XCircleIcon className="h-5 w-5 text-red-500" />
                            )}
                          </td>
                          <td>
                            <span
                              className={`${
                                lesson.fluencyPracticeTime / 600000 >= 5
                                  ? "text-green-800 bg-green-100"
                                  : lesson.fluencyPracticeTime / 600000 < 3
                                  ? "text-red-800 bg-red-100"
                                  : "text-yellow-800 bg-yellow-100"
                              } inline-flex items-center gap-x-1 rounded-md  px-2 py-1 text-lg font-medium  w-20 justify-center whitespace-nowrap`}
                            >
                              <svg
                                className={`${
                                  lesson.fluencyPracticeTime / 600000 >= 5
                                    ? "fill-green-500 h-1.5 w-1.5 shrink-0"
                                    : lesson.fluencyPracticeTime / 600000 < 3
                                    ? "fill-red-500 h-1.5 w-2 shrink-0"
                                    : "fill-yellow-500 h-1.5 w-1.5 shrink-0"
                                } `}
                                viewBox="0 0 6 6"
                                aria-hidden="true"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>
                              {(lesson.fluencyPracticeTime / 600000).toFixed(1)}{" "}
                              min
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                lesson.comprehensionPracticeTime / 600000 >= 5
                                  ? "text-green-800 bg-green-100"
                                  : lesson.comprehensionPracticeTime / 600000 < 3
                                  ? "text-red-800 bg-red-100"
                                  : "text-yellow-800 bg-yellow-100"
                              } inline-flex items-center gap-x-1 rounded-md  px-2 py-1 text-lg font-medium  w-20 justify-center whitespace-nowrap`}
                            >
                              <svg
                                className={`${
                                  lesson.comprehensionPracticeTime / 600000 >= 5
                                    ? "fill-green-500 h-1.5 w-1.5 shrink-0"
                                    : lesson.comprehensionPracticeTime / 600000 <
                                      3
                                    ? "fill-red-500 h-1.5 w-2 shrink-0"
                                    : "fill-yellow-500 h-1.5 w-1.5 shrink-0"
                                } `}
                                viewBox="0 0 6 6"
                                aria-hidden="true"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>
                              {(
                                lesson.comprehensionPracticeTime / 600000
                              ).toFixed(1)}{" "}
                              min
                            </span>
                          </td>
                          <td className="py-5 text-right">
                            <span
                              className={`${
                                (lesson.comprehensionPracticeTime +
                                  lesson.fluencyPracticeTime) /
                                  600000 >=
                                5
                                  ? "text-green-800 bg-green-100"
                                  : (lesson.comprehensionPracticeTime +
                                      lesson.fluencyPracticeTime) /
                                      600000 <
                                    3
                                  ? "text-red-800 bg-red-100"
                                  : "text-yellow-800 bg-yellow-100"
                              } inline-flex items-center gap-x-1 rounded-md  px-2 py-1 text-lg font-medium  w-20 justify-center whitespace-nowrap`}
                            >
                              <svg
                                className={`${
                                  (lesson.comprehensionPracticeTime +
                                    lesson.fluencyPracticeTime) /
                                    600000 >=
                                  5
                                    ? "fill-green-500 h-1.5 w-1.5 shrink-0"
                                    : (lesson.comprehensionPracticeTime +
                                        lesson.fluencyPracticeTime) /
                                        600000 <
                                      3
                                    ? "fill-red-500 h-1.5 w-2 shrink-0"
                                    : "fill-yellow-500 h-1.5 w-1.5 shrink-0"
                                } `}
                                viewBox="0 0 6 6"
                                aria-hidden="true"
                              >
                                <circle cx={3} cy={3} r={3} />
                              </svg>
                              {(
                                (lesson.comprehensionPracticeTime +
                                  lesson.fluencyPracticeTime) /
                                600000
                              ).toFixed(1)}{" "}
                              min
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
