export const colorOptions = [
    {
      leftColor: "from-red-500",
      rightColor: "to-red-300",
    },
    {
      leftColor: "from-amber-600",
      rightColor: "to-amber-400",
    },
    {
      leftColor: "from-yellow-500",
      rightColor: "to-yellow-300",
    },
    {
      leftColor: "from-green-500",
      rightColor: "to-green-300",
    },
    {
      leftColor: "from-cyan-500",
      rightColor: "to-blue-500",
    },
    {
      leftColor: "from-indigo-500",
      rightColor: "to-purple-500",
    },
    {
      leftColor: "from-pink-500",
      rightColor: "to-pink-300",
    },
    {
      leftColor: "from-zinc-800",
      rightColor: "to-zinc-600",
    }
  ];