import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "What is a Cold Read?",
    answer:
      "A cold read is when your student reads a new weekly text for the first time before practicing with the musician.",
    answer2:
      "Every weekly lesson starts with a 1 minute cold read video recording so you and your student can see fluency improvement.",
    video: "https://ucarecdn.com/c173e404-6f64-4b5d-ad74-7e57702055b9/",
  },
  {
    question: "What is a Hot Read?",
    answer:
      "A “hot read” is when your student has practiced with the musician (out loud 3 times each day!)",
    answer2:
      "Every weekly lesson ends with a 1 minute hot read video recording so you and your student can see how practice leads to improvement! ",
    video: "https://ucarecdn.com/fdf29d0e-b64f-4723-9db8-803cb4fb855b/",
  },
  {
    question: "What is a Benchmark?",
    answer:
      "This is a short fluency evaluation given every 4 lessons to help you track student progress.",
    answer2:
      " It is leveled to the grade level you have assigned your student. If your student is mastering one story per week, this will go up throughout the year!",
  },
  // More questions...
];

export default function SideFaq() {
  return (
    <div className="bg-white pb-12">
      <div className="mx-auto max-w-7xl px-2 mt-12">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-4 space-y-4 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-4">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                      <p className="text-base leading-7 text-gray-600 mt-4">
                        {faq.answer2}
                      </p>
                      {faq.video && (
                        <video className="mt-4 w-full" controls>
                          <source src={faq.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
