const k = [
  { title: "Early Reader Intro" },
  { title: "Letter A" },
  { title: "Letter B - I Love B!" },
  { title: "Letter C" },
  { title: "Letter D" },
  { title: "Letter E" },
  { title: "Letter F" },
  { title: "Letter G" },
  { title: "Letter H" },
  { title: "Letter I" },
  { title: "Letter J" },
  { title: "Letter K" },
  { title: "Letter L" },
  { title: "Letter M" },
  { title: "Letter N" },
  { title: "Letter O" },
  { title: "Letter P" },
  { title: "Letter Q" },
  { title: "Letter R" },
  { title: "Letter S" },
  { title: "Letter T" },
  { title: "Letter U" },
  { title: "Letter V" },
  { title: "Letter W" },
  { title: "Letter X" },
  { title: "Letter Y" },
  { title: "Letter Z" },
  { title: "Sight Words 1-20" },
  { title: "Sight Words 21-40" },
  { title: "Sight Words 41-60" },
  { title: "Sight Words 61 - 80" },
  { title: "Sight Words 81-100" },
];

const one = [
  { title: "Early Reader Intro" },
  { title: "Vowel Parade" },
  { title: "Ch Makes..." },
  { title: "Wh Makes Whaaaaaat?" },
  { title: "Sh Makes..." },
  { title: "Th Makes..." },
  { title: "Finding A - R" },
  { title: "AP Happy" },
  { title: "Am I Dreaming" },
  { title: "Listen In" },
  { title: "Can You Guess It" },
  { title: "My Senses Make Sense" },
  { title: "Manners Magic" },
  { title: "Push and Pull" },
  { title: "Do Not Let It Get To You" },
  { title: "Going Green (Earth Day)" },
  { title: "B - Bees" },
  { title: "H - How do you Say Hello" },
  { title: "N - Night Creatures" },
  { title: "Q - Quilt from Grandma" },
  { title: "A - Always Annie" },
  { title: "Z - Zack in the Zebra Suit" },
  { title: "M - Monster in My Closet" },
  { title: "Y - Yellow" },
  { title: "Waffles" },
  { title: "Perfect Pet" },
  { title: "Kicking it Sick" },
  { title: "Froggy" },
];

const two = [
  { title: "A - Awesome Intro" },
  { title: "V - Imagination Vacation" },
  { title: "Rhyme Time" },
  { title: "Best Friends" },
  { title: "E - Einstein" },
  { title: "D - Dancing Donuts" },
  { title: "Doing Whats Right" },
  { title: "I Used To Could Not" },
  { title: "Just Me" },
  { title: "Three States of Matter" },
  { title: "J - Journey of the Seeds" },
  { title: "P - Precious Plants" },
  { title: "Habitat" },
  { title: "R - Rocks" },
  { title: "Gotta Have a Heart" },
  { title: "L - Lollie Loves" },
  { title: "Thanksgiving" },
  { title: "F - Firefighters" },
  { title: "I - Icebergs" },
  { title: "Singing Sands" },
  { title: "My Tuba" },
  { title: "X - Extra" },
  { title: "Winter Holidays - Emerging Reader" },
  { title: "U - Unicorn Dream" },
  { title: "Halloween" },
  { title: "Holidays Daze" },
]

const three = [
  { title: "A - Awesome Intro" },
  { title: "Yo Insects!" },
  { title: "W - Weather" },
  { title: "Water Cycle" },
  { title: "Dont Catch a Cold" },
  { title: "George Washington Carver" },
  { title: "Who invented Time" },
  { title: "Yellowstone" },
  { title: "Dont Pick on Me" },
  { title: "Just Be You" },
  { title: "Winter Holidays - Established Reader" },
  { title: "Is The Truth Strong In You?" },
  { title: "Writing Process" },
  { title: "Life Cycle" },
  { title: "Im Leaving Home" },
  { title: "Chief Joseph" },
  { title: "Attitude Control" },
  { title: "The Unrelenting Grin" },
  { title: "Seasons" },
  { title: "Amelia Earhart" },
  { title: "Alien Invasion I" },
  { title: "Alien Invasion II" },
  { title: "Continents" },
  { title: "Homophones" },
  { title: "Halloween" },
] 

const four = [
  { title: "Rock With Us" },
  { title: "Knew, New" },
  { title: "Know, No, Now" },
  { title: "Their, There, They're" },
  { title: "Write, right" },
  { title: "CHALLENGE! To, Too, Two" },
  { title: "Martin Luther King Jr." },
  { title: "Thanksgiving Origins" },
  { title: "Egypt and the Pyramids" },
  { title: "Solar System" },
  { title: "Perspective I" },
  { title: "Perspective II" },
  { title: "Circuits" },
  { title: "Scientific Method" },
  { title: "Grit" },
  { title: "CHALLENGE! Integrity" },
  { title: "Cesar Chavez" },
  { title: "Louie the Button Pusher" },
  { title: "Masks" },
  { title: "Thomas Jefferson" },
  { title: "Medieval Times" },
  { title: "Ambition" },
  { title: "Great Wall of China" },
  { title: "Determination" },
]

const five = [
  { title: "Rock With Us" },
  { title: "CHALLENGE! Abe Lincoln" },
  { title: "Harriet Tubman" },
  { title: "Frederick Douglass" },
  { title: "Entrepreneurs" },
  { title: "Thomas Edison" },
  { title: "Nikola Tesla" },
  { title: "Rosa Parks" },
  { title: "Thanksgiving Origins" },
  { title: "Jane Addams" },
  { title: "Romes Coliseum" },
  { title: "Great Barrier Reef" },
  { title: "Niagara Falls" },
  { title: "Amazon Rain Forest" },
  { title: "The Great Depression" },
  { title: "Chivalry" },
  { title: "The Earth In The Universe" },
  { title: "Fake News" },
  { title: "Figurative Language" },
  { title: "Internet Safety" },
  { title: "Financial Literacy" },
  { title: "Human Impact on Earth" },
  { title: "Motion and Stability" },
  { title: "One People" },
  { title: "Natural Resources" },
  { title: "The Bill of Rights" },
  { title: "The Civil War" },
  { title: "Respect and Humility" },
  { title: "Words Need Actions" },
  { title: "Power Writing" },
];

export const lessons = {
    k,
    one,
    two,
    three,
    four,
    five
}
