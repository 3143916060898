import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { startFirstQuiz } from "../../config/lessonFunctions";

export default function StudentQuizGrader() {
  const { myStudents, tracker } = UserAuth();
  const [studentIndex, setStudentIndex] = useState(0);
  const [words, setWords] = useState([]);
  const [lines, setLines] = useState([]); // ["line1", "line2", "line3"
  const [hasVideos, setHasVideos] = useState(false);
  const [completedTrackers, setCompletedTrackers] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [clickedWords, setClickedWords] = useState([]);

  useEffect(() => {
    if (tracker.length > 0) {
      setCompletedTrackers(tracker.filter((item) => item.completed));
    } else {
      setCompletedTrackers([]);
    }
  }, [tracker]);

  useEffect(() => {
    if (completedTrackers.length > 0) {
      setHasVideos(true);
    }
  }, [completedTrackers]);

  useEffect(() => {
    // Assuming startFirstQuiz and tracker are defined elsewhere
    startFirstQuiz(tracker[0]?.references[0]?.path).then((res) => {
      setCurrentLesson(res);
      // Convert string of lines into an array of lines
      const linesArray = res?.lyricLines?.map((line) => line.split(" "));
      setLines(linesArray);
    });
  }, [tracker]);

  let periods = 0; // Initialize the periods count
  let commas = 0; // Initialize the commas count
  let exclamationPoints = 0; // Initialize the exclamation points count
  let questionMarks = 0; // Initialize the question marks count
  let quotationMarks = 0; // Initialize the quotation marks count
  let apostrophes = 0; // Initialize the apostrophes count
  let dashes = 0; // Initialize the dashes count
  let spaces = 0; // Initialize the spaces count
  const [totalwords, setTotalWords] = useState(0);

  const handleClick = (lineIndex, wordIndex, value) => {
    const totals =
      periods +
      commas +
      exclamationPoints +
      questionMarks +
      quotationMarks +
      apostrophes +
      dashes +
      spaces;

    // Create a copy of the clickedWords array
    const newClickedWords = [...clickedWords];

    // Check if the word is already clicked
    const existingWordIndex = newClickedWords.findIndex(
      (word) => word.lineIndex === lineIndex && word.wordIndex === wordIndex
    );

    if (existingWordIndex !== -1) {
      // If the word is already clicked, increment the click count
      newClickedWords[existingWordIndex].clicks++;
    } else {
      // If the word is not clicked, add it to the array
      newClickedWords.push({
        lineIndex,
        wordIndex,
        clicks: 1,
      });
    }

    // Check if any word needs to be unhighlighted (blue)
    const wordsToUnhighlight = newClickedWords.filter(
      (word) =>
        word.clicks === 2 &&
        (word.lineIndex !== lineIndex || word.wordIndex !== wordIndex)
    );

    // Remove the blue highlight from words that need to be unhighlighted
    wordsToUnhighlight.forEach((word) => {
      const indexToRemove = newClickedWords.findIndex(
        (w) => w.lineIndex === word.lineIndex && w.wordIndex === word.wordIndex
      );
      newClickedWords.splice(indexToRemove, 1);
    });

    // Update the state with the new clickedWords array
    setClickedWords(newClickedWords);

    // Determine the position of the clicked word in the entire sequence
    const clickedWordPosition = (lineIndex + 1) * (wordIndex + 1);
    console.log(
      `Clicked word position: ${
        clickedWordPosition - totals > totals
          ? clickedWordPosition - totals
          : clickedWordPosition > totalwords
          ? clickedWordPosition - totalwords
          : clickedWordPosition
      }`
    );
  };

  useEffect(() => {
    if (myStudents[studentIndex]?.coldReadVideos) {
      setHasVideos(true);
    } else {
      setHasVideos(false);
    }
  }, [studentIndex]);

  const [awpm, setAwpm] = useState(0);

  const determineWordsPerMinute = (count) => {
    const wordsPerMinute = count - 1;
    setAwpm(wordsPerMinute);
  };

  const determineAccuracy = () => {
    const accuracy = 0;
    return accuracy;
  };

  const lineIndex = 10;

  useEffect(() => {
    let count = 0;
    if (currentLesson?.lyrics) {
      const lines = currentLesson.lyrics.split("\n"); // Split the lyrics into lines
      lines.forEach((line) => {
        const words = line.trim().split(/\s+/); // Split each line into words
        count += words.length;
      });
      setTotalWords(count);
    }
  }, [currentLesson]);

  useEffect(() => {
    determineWordsPerMinute();
    determineAccuracy();
  }, [words]);

  console.log(totalwords);

  console.log(clickedWords.filter((word) => word.clicks === 2));

  return (
    <div className=" relative">
      <div
        className="absolute inset-0 max-w-full bg-indigo-400 opacity-90 flex items-center justify-center"
        style={{ zIndex: 10 }}
      >
        <div className="md:text-9xl text-4xl font-bold text-white -mt-96 px-24">
        <div className="md:text-9xl text-4xl font-bold text-white text-center">Coming Soon</div>
        <p className="text-white text-2xl font-bold mt-4 text-center">This feature is currently in development and will allow teachers and parents to dive even deaper into their students reading development by allowing them to grade each video on a micro-scale.</p>
        </div>
      </div>
      <div className="md:hidden flex items-center justify-center h-screen text-center px-4 ">
        <div className=" text-2xl font-bold ">
          To use this feature please use a larger screen, preferably a tablet or
          desktop.
        </div>
      </div>
      <div className="max-w-7xl mx-auto hidden md:block">
        {hasVideos ? (
          <div className="flex gap-4">
            <div className=" pt-12">
              <button className="bg-emerald-500 text-white rounded-md p-4">
                Hot Read
              </button>
              <div className="text-2xl font-bold text-center  ">
                Benchmark 1: Reading Fluency
              </div>

              <video className="mx-auto w-full mt-2" controls>
                <source src={tracker[0]?.coldReadVideo} type="video/mp4" />
              </video>
              <div className="text-lg mt-4">
                <div className="font-bold">Instructions:</div>
                <div>Watch the video and read along with the student</div>
                <div className="text-sm mt-4">
                  • For every word they{" "}
                  <span className="underline">struggle with</span>,{" "}
                  <span className="underline">skip</span>, or{" "}
                  <span className="underline">miss</span>{" "}
                  <span className="bg-red-400 rounded-md px-2 text-white">
                    click one time to mark it red
                  </span>
                </div>
                <div className="text-sm mt-4">
                  • Click twice{" "}
                  <span className="bg-blue-400 rounded-md px-2 text-white">
                    on the last word they read
                  </span>{" "}
                  <span className="font-bold">
                    {" "}
                    (only one word can be highlighted blue at a time as this is
                    how we determine words per minute)
                  </span>
                </div>
                <div className="text-sm mt-4">
                  • If you accidently click on a word, click 2 more times to
                  remove the highlight
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="p-4 shadow-lg bg-gray-100 mb-12 mt-20">
                <div className="text-2xl font-bold text-center">Who Am I</div>
                {lines?.map((line, lineIndex) => (
                  <div key={lineIndex} className=" whitespace-nowrap px-2">
                    {line?.map((word, wordIndex) => {
                      const wordClicks = clickedWords.find(
                        (w) =>
                          w.lineIndex === lineIndex && w.wordIndex === wordIndex
                      );

                      if (word === ".") {
                        periods++;
                      } else if (word === ",") {
                        commas++;
                      }
                      if (word === "!") {
                        exclamationPoints++;
                      } else if (word === "?") {
                        questionMarks++;
                      }
                      if (word === '"') {
                        quotationMarks++;
                      } else if (word === "'") {
                        apostrophes++;
                      }
                      if (word === "-") {
                        dashes++;
                      } else if (word === " ") {
                        spaces++;
                      }

                      return (
                        <React.Fragment key={wordIndex}>
                          {wordIndex > 0 && word.endsWith(".") ? " " : null}

                          <button
                            className={`bg-${getBackgroundColor(
                              wordClicks ? wordClicks.clicks : 0
                            )}-400 ml-1 text-xl`}
                            onClick={() =>
                              handleClick(lineIndex, wordIndex, word)
                            }
                          >
                            {word}
                          </button>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ))}
              </div>
              <div className="flex mb-24">
                <button className="bg-emerald-500 text-white rounded-md p-4 ml-auto">
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-screen flex items-center justify-center">
            <div className="text-2xl font-bold text-center">
              Currently no videos to grade
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function getBackgroundColor(clicks) {
  switch (clicks % 3) {
    case 0:
      return "white";
    case 1:
      return "red";
    case 2:
      return "blue";
    default:
      return "white";
  }
}
