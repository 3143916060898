import React, { useState, useEffect, useRef } from 'react';

export default function StudentRecord() {
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [videoRecorder, setVideoRecorder] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recording, setRecording] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        setVideoStream(stream);
      });

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        setAudioStream(stream);
      });
  }, []);

  useEffect(() => {
    if (videoStream && videoRef.current) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  useEffect(() => {
    if (videoStream && !videoRecorder) {
      setVideoRecorder(new MediaRecorder(videoStream, { mimeType: 'video/webm' }));
    }
  }, [videoStream, videoRecorder]);

  useEffect(() => {
    if (audioStream && !audioRecorder) {
      setAudioRecorder(new MediaRecorder(audioStream, { mimeType: 'audio/webm' }));
    }
  }, [audioStream, audioRecorder]);

  function startRecording() {
    if (videoRecorder && audioRecorder) {
      videoRecorder.start();
      audioRecorder.start();
      setRecording(true);
      let videoChunks = [];
      let audioChunks = [];
      videoRecorder.addEventListener("dataavailable", event => {
        videoChunks.push(event.data);
      });
      audioRecorder.addEventListener("dataavailable", event => {
        audioChunks.push(event.data);
      });
      videoRecorder.addEventListener("stop", () => {
        setVideoBlob(new Blob(videoChunks));
      });
      audioRecorder.addEventListener("stop", () => {
        setAudioBlob(new Blob(audioChunks));
      });
    }
  }

  function stopRecording() {
    if (videoRecorder && audioRecorder) {
      videoRecorder.stop();
      audioRecorder.stop();
      setRecording(false);
    }
  }

  return (
    <div>
      <video style={{ width: "320px", height: "240px" }} ref={videoRef} autoPlay={true} playsInline={true} />
      <div></div>
      {recording ? (
        <button className='p-4 bg-red-400 text-white text-center' onClick={stopRecording}>Stop Recording</button>
      ) : (
        <button className='p-4 bg-emerald-400 text-white text-center' onClick={startRecording}>Start Recording</button>
      )}
      {videoBlob && <video src={URL.createObjectURL(videoBlob)} controls={true} />}
      {audioBlob && <audio src={URL.createObjectURL(audioBlob)} controls={true} />}
    </div>
  );
}
