import React from "react";
import logo from "../../assets/purple-long.png";
import lefty from "../../assets/lefty-new.png";
import { RocketLaunchIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

export default function Welcome() {

    const navigate = useNavigate();

    const handleSetUpStudent = () => {
        navigate("/setup-student");
    }



  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center relative">
      <h1 className="text-7xl mt-4">Welcome to </h1>
        <img src={logo} alt="Lyrics2Learn Logo" className="w-64 mx-auto mt-4" />
        <p className="text-2xl mt-6">
          
          Please head to your dashboard to begin setting up your first student!
        </p>
        <div className="flex items-center mt-16 justify-center">
          <div>
            <img src={lefty} alt="Student" className="w-24 mx-auto mr-4" />
          </div>

          <div>
            <button className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-4 px-8 text-xl rounded col-span-4" onClick={handleSetUpStudent}>
              Set Up Your Student <RocketLaunchIcon className="w-6 h-6 inline-block" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
