import React, { useEffect } from "react";
import Progress from "./Progress";
import files from "../../assets/Images";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

export default function PrintID() {
  const { newUser } = UserAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (newUser.started) {
      return;
    } else {
     navigate("/parent/dashboard")
    }
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };


  return (
    <div className="overflow-hidden py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
     
      <div className="max-w-6xl mx-auto">
        <h1 className="text-center text-3xl font-bold mt-12">
          Congrats! Your student has been created!
        </h1>
        <p className="text-center text-md mb-8 mt-2">
          Please print this page for your records and your student's login
        </p>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg border border-dashed border-2 border-indigo-700 max-w-3xl mx-auto">
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-10 gap-1">
              <div className="col-span-4 row-span-3">
                <img
                  src={files[newUser?.imageIndex]?.source}
                  alt="student index token"
                  className="mr-auto h-48 w-48"
                />
              </div>
              <p className="text-right text-2xl mt-2 col-span-6 font-extrabold tracking-tight">
                Student Username:{" "}
                <span className="text-indigo-500">{newUser?.userName}</span>
              </p>
              <p className="text-right text-2xl mb-8 -mt-2 col-span-6 font-extrabold tracking-tight">
                Login Token:{" "}
                <span className="text-indigo-500">
                  {files[newUser?.imageIndex]?.title}
                </span>
              </p>

              <p className="text-right text-2xl mt-2 col-span-6 font-extrabold tracking-tight">
                Student Name:{" "}
                <span className="text-indigo-500">{newUser?.studentName}</span>
              </p>
            </div>
          </div>
        </div>
        {/* add a print button */}
        <div className="mx-auto max-w-xs grid grid-cols-1 gap-y-3 mt-4">
          <button
            type="button"
            className="mx-auto items-center text-center rounded-md border border-transparent bg-emerald-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 w-full"
            onClick={() => window.print()}
          >
            Print/Save Login Info
          </button>
        </div>
        <div className="mx-auto max-w-xs grid grid-cols-1 gap-y-3 mt-4">
          <button
            className="mx-auto items-center text-center rounded-md border-emerald-800 border-2 bg-emerald-100  px-4 py-2 text-base font-medium text-emerald-800 shadow-sm hover:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2 w-full"
            onClick={handleRefresh}
          >
            {" "}
            Go to your Student's Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}
