import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../parents'
import Welcome from '../parents/pages/Welcome'
import MoreInfo from '../parents/components/MoreInfo'
import AssignUserName from '../parents/components/AssignUserName'
import AssignToken from '../parents/components/AssignToken'
import PrintID from '../parents/components/PrintID'
import PrintLogin from '../parents/components/PrintLogin'
import CreateStudentAccount from "../students/CreateStudentAccount";
import PickBackground from '../students/components/PickBackground'
import StudentQuizGrader from '../parents/pages/StudentQuizGrader'

export default function ParentRoutes() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/welcome' element={<Welcome />} />
        <Route path="/setup-student" element={<MoreInfo />} />
        <Route path="/assign-username" element={<AssignUserName />} />
        <Route path="/token" element={<AssignToken />} />
        <Route path="/grader/:lessonTracker" element={<StudentQuizGrader />} />
        <Route path="/print/:index/:userName/:name" element={<PrintLogin />} />
        <Route path="/create-student-account/:id" element={<CreateStudentAccount />} />
        <Route
          path="/student/dashboard/:bg/:indx"
          element={<PickBackground />}
        />
        <Route path="/print-login" element={<PrintID />} />
        <Route path='/*' element={<Dashboard />} />
      </Routes>
    </div>
  )
}
