import React, { useEffect, useState } from "react";
import logo from "../assets/white-logo-main.png";
import purpleLogo from "../assets/purple-long.png";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { LockClosedIcon, MusicalNoteIcon } from "@heroicons/react/20/solid";

import greg from "../assets/greg.png";
import caleb from "../assets/caleb.jpeg";
import ashon from "../assets/ashton.png";
import { createStripeSubscription } from "../stripe/createAccount";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { createParent } from "../helpers/firebaseFunctions";

const REACT_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const features = [
  {
    name: "6 years",
    description:
      "of reading lessons and hundreds of music-based reading practice videos",
    icon: MusicalNoteIcon,
  },
  {
    name: "Thousands",
    description: "of worksheets and activities",
    icon: MusicalNoteIcon,
  },
  {
    name: "Access ",
    description:
      "to a reading evaluation that allows you to pinpoint your child's needs - $1,000 value",
    icon: MusicalNoteIcon,
  },
];
const stripePromise = loadStripe(REACT_STRIPE_KEY);

export default function FreeTrial() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <FreeTrialForm />
    </Elements>
  );
}

function FreeTrialForm() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [subscription, setSubscription] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [billingZipCode, setBillingZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [usersIP, setUsersIP] = useState("");
  const { setUser, setTempParentData } = UserAuth();

  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSubscription(event.target.value);
  };

  useEffect(() => {
    fetch("https://api.ipify.org/?format=json")
      .then((res) => res.json())
      .then((data) => setUsersIP(data.ip));
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (email === confirmEmail) {
      console.log("emails match");
    } else {
      alert("Your emails dont match");
    }

    if (password === confirmPassword) {
      console.log("passwords match");
    } else {
      alert("Your passwords dont match");
    }

    if (isAgreed) {
      console.log("You agreed to the terms");
    } else {
      alert("You must agree to the terms");
    }

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createToken(elements.getElement(CardElement));

    console.log(result.token.id);
    try {
      if (email === confirmEmail && password === confirmPassword && isAgreed) {
        setLoading(true);
        createStripeSubscription(
          result.token.id,
          email,
          phoneNumber,
          cardholderName,
          cardNumber,
          expirationMonth,
          expirationYear,
          securityCode,
          billingZipCode,
          subscription,
          3999,
          "Lyrics2Learn Subscription"
        ).then((res) => {
          const parentData = {
            email: email,
            password: password,
            name: cardholderName,
            firstName: firstName,
            lastName: lastName,
            acceptsTermsForSubscription: true,
            phoneNumber: phoneNumber,
            subscription: subscription,
            ip: usersIP,
            customerID: res.customer,
            subscriptionID: res.subscription,
          };
          if (res.status === 200) {
            console.log("Subscription Created");
            createParent(parentData).then((res) => {
              if (res.hasAccount) {
                console.log("Account Created");
                setUser({
                  email: parentData.email,
                  id: res._id,
                  name: parentData.name,
                  acceptsTermsForSubscription: true,
                  phone: parentData.phoneNumber,
                  subscription: parentData.subscription,
                  finished: false,
                  role: "parent",
                  customerID: res.customer,
                  subscriptionID: res.subscription,
                });
                navigate("/welcome");
                setLoading(false);
              } else if (res.error === "auth/email-already-in-use") {
                console.log("Account Not Created");
                alert("That email address is already in use!");
                setTempParentData(parentData);
                navigate("/create-parent-email");
                setLoading(false);
              }
            });
          }
        });
      } else {
        console.log("You are not ready to subscribe");
      }
    } catch (error) {
      console.error(error);
      // Handle any errors that occur during token creation
    }
  };

  const handleCardChange = (event) => {
    const { value } = event.target;

    // Remove all non-digit characters
    const digitsOnly = value.replace(/\D/g, "");

    // Limit the number of digits to 16
    const limitedDigits = digitsOnly.slice(0, 16);

    // Add a space after every four digits
    const formattedValue = limitedDigits.replace(/(\d{4})(?=\d)/g, "$1 ");

    // Remove spaces from the formatted value
    const cardNumber = formattedValue.replace(/\s/g, "");

    if (cardNumber.length > 0) {
      setCardNumber(cardNumber);
    } else {
      setCardNumber("");
    }

    // Update the input value
    event.target.value = formattedValue;
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen ">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      ) : (
        <div className="relative overflow-hidden bg-gradient-to-r from-amber-50 via-teal-100 to-violet-100 pb-24">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4 max-w-7xl mx-auto mt-16 sm:mt-24 items-center">
            <h1 className="text-4xl font-gbold tracking-tight text-dark sm:text-5xl text-center">
              A Groundbreaking Reading Program for K-5th grade homes
            </h1>
            <img src={logo} alt="" className="w-full px-4 drop-shadow-lg" />
          </div>
          <div className="text-center mt-4 font-semibold text-2xl">
            More Fun - More Memorable - More Effective - Research-Based
          </div>
          <main className="mt-12">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                  <div>
                    <div className="hidden sm:mb-4 sm:flex sm:justify-center lg:justify-start"></div>
                    <dl className="mt-10 max-w-xl space-y-8 text-2xl leading-7 text-gray-900 lg:max-w-none">
                      {features.map((feature) => (
                        <div key={feature.name} className="relative pl-9">
                          <dt className="inline font-gbold text-gray-900">
                            <feature.icon
                              className="absolute left-1 top-1 h-5 w-5 text-violet-500"
                              aria-hidden="true"
                            />
                            {feature.name}
                          </dt>{" "}
                          <dd className="inline font-medium">
                            {feature.description}
                          </dd>
                        </div>
                      ))}
                    </dl>
                    <div className="mt-14 w-full sm:mx-auto sm:max-w-lg lg:ml-0 text-center ">
                      <div className="text-xl font-gbold">
                        Mrs. Hardy's Entire 1st Grade Class!
                      </div>
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/lykyXGENQCI?si=KxNVDkciyIgDUUXD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      <div className="text-lg font-semibold">
                        Fluency - 100% of the class ended the year profieicnt
                      </div>
                      <div className="text-lg font-semibold">
                        70% of her class DOUBLED their reading goal for the
                        year!
                      </div>
                    </div>
                    <div className="mt-14 w-full sm:mx-auto sm:max-w-lg lg:ml-0 text-center ">
                      <div className="text-xl font-gbold">
                        Ashon's reading improved by 2 years
                      </div>
                      <img
                        src={ashon}
                        alt=""
                        className="w-64 h-64 object-cover object-center mx-auto mt-2 rounded-md shadow"
                      />
                      <div className="text-lg font-semibold">
                        Comprehension: Up 2 grades
                      </div>
                      <div className="text-lg font-semibold">
                        Phonics: 60% mastery to 85%
                      </div>
                      <div className="text-lg font-semibold">
                        Fluency: 200% word per minute increase
                      </div>
                    </div>
                   
                    <div className="mt-14 w-full sm:mx-auto sm:max-w-lg lg:ml-0 text-center ">
                      <div className="text-xl font-gbold">
                        Caleb improved 1 full year in 4 months!
                      </div>
                      <img
                        src={caleb}
                        alt=""
                        className="w-64 h-64 object-cover object-center mx-auto mt-2 rounded-md shadow"
                      />
                      <div className="text-lg font-semibold">
                        Comprehension: Up 2 grades
                      </div>
                      <div className="text-lg font-semibold">
                        Phonics: 42% mastery to 87%
                      </div>
                      <div className="text-lg font-semibold">
                        Fluency: 50% accuracy to 98%
                      </div>
                    </div>

                    <div className="mt-14 w-full sm:mx-auto sm:max-w-lg lg:ml-0 text-center ">
                      <div className="text-xl font-gbold">
                        Greg's reading improved by 2 years
                      </div>
                      
                      <img
                        src={greg}
                        alt=""
                        className="w-64 h-64 object-cover object-center mx-auto mt-2 rounded-md shadow"
                      />
                      <div className="text-lg font-semibold">
                        Comprehension: Up 2 grades
                      </div>
                      <div className="text-lg font-semibold">
                        Phonics: 42% mastery to 87%
                      </div>
                      <div className="text-lg font-semibold">
                        Fluency: 50% accuracy to 98%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0">
                  <div className="text-violet-500 font-semibold text-3xl drop-shadow-lg text-center mr-2">
                    Risk Free 20-Day Trial
                  </div>
                  <div className="bg-white sm:mx-auto sm:w-full sm:max-w-lg sm:overflow-hidden sm:rounded-lg  border-violet-500 border-2 mt-4">
                    <div className="flex items-center p-3">
                      <div className="text-xl bg-violet-500 text-white p-2 w-11 h-11 rounded-full text-center">
                        1.
                      </div>
                      <p className="text-xl font-extrabold text-violet-500 ml-3">
                        Create Your Family Account
                      </p>
                    </div>
                    <div className="px-6 pb-8 sm:px-10 ">
                      <div className="">
                        <div className="space-y-6">
                          <div className="">
                            <div className="items-center grid grid-cols-6 mt-4 gap-2 ">
                              <label
                                htmlFor="name"
                                className="block text-sm font-gbold leading-6 text-gray-900 text-right w-full"
                              >
                                Name
                              </label>
                              <div className="w-full col-span-2">
                                <input
                                  type="text"
                                  name="first-name"
                                  id="first-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-lg sm:leading-6 h-14"
                                  placeholder="First Name"
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                              </div>
                              <div className="w-full col-span-3">
                                <input
                                  type="text"
                                  name="last-name"
                                  id="last-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-lg sm:leading-6 h-14"
                                  placeholder="Last Name"
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="flex items-center grid grid-cols-6 mt-4">
                              <label
                                htmlFor="email"
                                className="block text-sm font-gbold leading-6 text-gray-900 text-right mr-2"
                              >
                                Email
                              </label>
                              <div className="w-full col-span-5">
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-lg sm:leading-6 h-14"
                                  placeholder="you@example.com"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="flex items-center grid grid-cols-6 mt-4">
                              <label
                                htmlFor="email"
                                className="block text-sm font-gbold leading-6 text-gray-900 text-right mr-2"
                              >
                                Confirm Email
                              </label>
                              <div className="w-full col-span-5">
                                <input
                                  type="email"
                                  name="confirm-email"
                                  id="confirm-email"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-lg sm:leading-6 h-14"
                                  placeholder="Confirm your email"
                                  onChange={(e) =>
                                    setConfirmEmail(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex items-center grid grid-cols-6 mt-4">
                              <label
                                htmlFor="email"
                                className="block text-sm font-gbold leading-6 text-gray-900 text-right mr-2"
                              >
                                Password
                              </label>
                              <div className="w-full col-span-5">
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-lg sm:leading-6 h-14"
                                  placeholder="Password"
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="flex items-center grid grid-cols-6 mt-4">
                              <label
                                htmlFor="email"
                                className="block text-sm font-gbold leading-6 text-gray-900 text-right mr-2"
                              >
                                Confirm Password
                              </label>
                              <div className="w-full col-span-5">
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-500 sm:text-lg sm:leading-6 h-14"
                                  placeholder="Confirm Password"
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white sm:mx-auto sm:w-full sm:max-w-lg sm:overflow-hidden sm:rounded-lg border-violet-500 border-2 mt-4">
                    <div className="flex items-center pl-3 pt-3">
                      <div className="text-xl bg-violet-500 text-white p-2 w-11 h-11 rounded-full text-center">
                        2.
                      </div>
                      <p className="text-xl font-extrabold text-violet-500 ml-3">
                        Start my 20-day free trial of
                      </p>
                    </div>
                    <img src={purpleLogo} alt="" className="w-full px-24" />
                    <div className="text-center font-extrabold text-lg">
                      Choose your renewal options:
                    </div>

                    <div className="px-6 pb-8 sm:px-10 mt-4">
                      <div className="">
                        <fieldset className="space-y-6">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="monthly"
                                name="subscription"
                                type="radio"
                                value="monthly"
                                checked={subscription === "monthly"}
                                onChange={handleOptionChange}
                                className="h-12 w-12 rounded-md border-gray-300 text-violet-500 focus:ring-violet-500"
                              />
                            </div>
                            <div className="ml-3 text-xl leading-6">
                              <label
                                htmlFor="monthly"
                                className="font-gbold text-gray-900"
                              >
                                Monthly Options -
                              </label>
                              <p
                                id="comments-description"
                                className="text-gray-500"
                              >
                                $34.99 /month until canceled
                              </p>
                            </div>
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="yearly"
                                name="subscription"
                                type="radio"
                                value="yearly"
                                checked={subscription === "yearly"}
                                onChange={handleOptionChange}
                                className="h-12 w-12 rounded-md border-gray-300 text-violet-500 focus:ring-violet-500"
                              />
                            </div>
                            <div className="ml-3 text-xl leading-6">
                              <label
                                htmlFor="yearly"
                                className="font-gbold text-gray-900"
                              >
                                Annual Options -
                              </label>
                              <p
                                id="comments-description"
                                className="text-gray-500"
                              >
                                $209.99 for your first 15 months
                              </p>
                              <p
                                id="comments-description"
                                className="text-gray-500 text-lg"
                              >
                                then renews every 12 months until canceled
                              </p>
                              <p
                                id="comments-description"
                                className="text-red-500 text-lg font-gbold"
                              >
                                (50% off our monthly pricing for your first
                                year)
                              </p>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="space-y-5"></div>
                  </div>
                  <div className="bg-white sm:mx-auto sm:w-full sm:max-w-lg sm:overflow-hidden sm:rounded-lg border-violet-500 border-2 mt-4">
                    <div className="flex items-center p-3">
                      <div className="text-xl bg-violet-500 text-white p-2 w-11 h-11 rounded-full text-center">
                        3.
                      </div>
                      <p className="text-xl font-extrabold text-violet-500 ml-3">
                        Enter Your Payment Information
                      </p>
                    </div>
                    <div className="px-6 pb-8 sm:px-6 ">
                      <CardElement className="rounded-lg border-violet-500 border-2 text-lg p-5" />
                    </div>
                  </div>
                  <div className="bg-white sm:mx-auto sm:w-full sm:max-w-lg sm:overflow-hidden sm:rounded-lg border-violet-500 border-2 mt-4">
                    <div className="flex items-center pl-3 pt-3">
                      <div className="text-xl bg-violet-500 text-white p-2 w-11 h-11 rounded-full text-center">
                        4.
                      </div>
                      <p className="text-xl font-extrabold text-violet-500 ml-3">
                        Agree to the Terms and Conditions
                      </p>
                    </div>
                    <div className="text-center font-gbold text-lg flex items-center justify-center mt-2">
                      <LockClosedIcon className="h-5 w-5" /> Your information is
                      safe and protected
                    </div>

                    <div className="px-6 pb-8 sm:px-10 mt-8">
                      <div className="">
                        <fieldset className="space-y-6">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-start">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                className="h-12 w-12 rounded border-gray-300 text-violet-500 focus:ring-violet-500"
                                onChange={(e) => setIsAgreed(e.target.checked)}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                After my 20-day free trial, I understand that I
                                will be charged{" "}
                                {subscription === "monthly"
                                  ? "$39.99 per month until canceled. I can cancel at any time. I also understand that I can upgrade to an annual subscription at any time"
                                  : "$209.99 for your first 15 months and renewing every 12 months until canceled"}
                              </label>
                              <p
                                id="comments-description"
                                className="text-gray-900 mt-4 text-center mr-8"
                              >
                                By clicking "Start My Free Trial" you agree to
                                our{" "}
                                <Link to="/terms-of-service" className="text-violet-500">
                                  Terms and Conditions
                                </Link>{" "}
                                .
                                <button
                                  className="bg-violet-500 text-white px-8 py-2 mx-auto hover:bg-violet-700 rounded-full text-2xl mt-3"
                                  onClick={handleSubscribe}
                                >
                                  Start My Free Trial
                                </button>
                              </p>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="space-y-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}
