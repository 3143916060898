import { useState } from "react";
import { uploadFile } from "@uploadcare/upload-client";
import { useNavigate } from "react-router-dom";
import { saveRead, studentCompletedBenchmark } from "../../config/lessonFunctions";



const UploadCare = (publicKey) => {
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate()


  const uploadColdHotRead = async (file, lessonData, readType, fileName) => {

    setUploading(true);
    try {
        const result = await uploadFile(file, {
            publicKey,
            store: "auto",
            metadata: { filename: fileName, readType: readType },
        });
        setUploadedFileUrl(result.cdnUrl);
        try {
            await saveRead(lessonData, result.cdnUrl, readType);
        } catch (error) {
            console.log(error);
        }
    } catch (error) {
        console.error("Error uploading file:", error);
    }
    setUploading(false);
    navigate("/student/dashboard")
};

const uploadBenchMark = async (lessonData, file, fileName) => {
    
        setUploading(true);
        try {
            const result = await uploadFile(file, {
                publicKey,
                store: "auto",
                metadata: { readType: "Benchmark" },
                original_filename: fileName,
            });
            setUploadedFileUrl(result.cdnUrl);
            console.log(result.cdnUrl);
            try {
                await studentCompletedBenchmark(lessonData, result.cdnUrl);
            } catch (error) {
                console.log(error);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
        setUploading(false);
        navigate("/student/dashboard")

};

  return {
    uploadedFileUrl,
    uploading,
    setUploadedFileUrl,
    uploadColdHotRead,
    uploadBenchMark
   
  };
};

export default UploadCare;
