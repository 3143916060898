import first from './first.png';
import second from './second.png';
import third from './third.png';
import fourth from './fourth.png';
import fifth from './fifth.png';
import sixth from './sixth.png';
import seventh from './seventh.png';
import eighth from './eighth.png';
import ninth from './ninth.png';
import tenth from './tenth.png';
import eleventh from './eleventh.png';
import twelfth from './twelfth.png';
import thirteenth from './thirteenth.png';
import fourteenth from './fourteenth.png';
import fifteenth from './fifteenth.png';
import sixteenth from './sixteenth.png';


const files = [
    first,
    second,
    third,
    fourth,
    fifth,
    sixth,
    seventh,
    eighth,
    ninth,
    tenth,
    eleventh,
    twelfth,
    thirteenth,
    fourteenth,
    fifteenth,
    sixteenth
];

export default files;