import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateStudentAccount from "../students/CreateStudentAccount";
import StudentDashboard from "../students";
import Dashboard from "../students/Dashboard";
import StudentLesson from "../students/pages/StudentLesson";
import StudentQuizGrader from "../students/pages/StudentQuizGrader";
import Award from "../students/components/Award";
import StudentRecord from "../students/components/StudentRecord";
import ColdAndHotReads from "../students/pages/ColdHotReads";
import Benchmarks from "../students/pages/Benchmarks";
import PickBackground from "../students/components/PickBackground";
import FirstLesson from "../students/pages/FirstLesson";
import FirstStudentQuiz from "../students/pages/FirstStudentQuiz";
import CompletedLesson from "../students/components/CompletedLesson";
import StarsAnimation from "../students/components/StarsAnimation";
import VideoPractice from "../students/pages/VideoPractice";

export default function StudentRoutes() {
  return (
    <div>
      <Routes>
        <Route path="/student/dashboard" element={<Dashboard />} />
        <Route
          path="/student/dashboard/student-lesson/:doc/:grade/:day/:lesson"
          element={<StudentLesson />}
        />
        <Route
          path="/student-quiz/:doc/:grade/:day/:lesson"
          element={<StudentQuizGrader />}
        />
        <Route path="/award/book/:id" element={<Award />} />
        <Route path="/record" element={<StudentRecord />} />
        <Route
          path="/student/dashboard/:bg/:indx"
          element={<PickBackground />}
        />
         <Route
          path="/student/dashboard/first-lesson"
          element={<FirstLesson />}
        />
         <Route
          path="/student/practice-time/:doc/:grade/:day/:lesson"
          element={<VideoPractice />}
        />
         <Route
          path="/student/dashboard/completed/:stars"
          element={<StarsAnimation  />}
        />
          <Route
          path="/student/dashboard/first-quiz"
          element={<FirstStudentQuiz />}
        />
        <Route
          path="/student/dashboard/cold-hot-reads"
          element={<ColdAndHotReads />}
        />
        <Route
          path="/student/dashboard/benchmark"
          element={<Benchmarks />}
        />
        <Route
          path="/create-student-account/:id"
          element={<CreateStudentAccount />}
        />
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </div>
  );
}
