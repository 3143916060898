import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { UserAuth } from "../context/AuthContext";
import { colorOptions } from "../assets/backgrounds/colors";
import files from "../assets/Avatars";
import { useNavigate } from "react-router-dom";
import { PhotoIcon } from "@heroicons/react/24/outline";
import star from "../assets/gameIcons/star.png";
import books from "../assets/books";
import playButton from "../assets/playButton.png";
import startButton from "../assets/gameIcons/startButton.png";
import locked from "../assets/gameIcons/lockedIcon.png";
import ready from "../assets/gameIcons/readyIcon.png";
import becnhMarkRocket from "../assets/gameIcons/benchmarkIcon.png";
import threeStars from "../assets/gameIcons/finishedThreeStars.png";
import oneStar from "../assets/gameIcons/finishedOneStar.png";
import twoStars from "../assets/gameIcons/finishedTwoStars.png";
import settings from "../assets/gameIcons/settings.png";
import dailyStatus from "../assets/gameIcons/dailyStatusBar.png";
import dailyStatusProgress from "../assets/gameIcons/dailyProgress.png";
import gradeStatus from "../assets/gameIcons/gradeStatusBar.png";
import dayOneReady from "../assets/gameIcons/dayOneReady.png";
import dayTwoReady from "../assets/gameIcons/dayTwoReady.png";
import dayThreeReady from "../assets/gameIcons/dayThreeReady.png";
import coldReady from "../assets/gameIcons/coldReadStart.png";
import coldFinish from "../assets/gameIcons/coldReadFinish.png";
import hotReady from "../assets/gameIcons/hotReadStart.png";
import hotFinish from "../assets/gameIcons/hotReadFinish.png";
import gradeStatusProgress from "../assets/gameIcons/gradeProgress.png";
import livesStatus from "../assets/gameIcons/livesStatusBar.png";
import liveStatusProgress from "../assets/gameIcons/livesProgress.png";
import { PlayCircleIcon } from "@heroicons/react/20/solid";
import Video from "./components/Video";
import ParentLoginModal from "./components/ParentLoginModal";

export default function Dashboard() {
  const { studentProfile, logout, setIsLoading, setStartTime, startLesson } =
    UserAuth();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [currentVid, setCurrentVid] = useState(null);
  const [student, setStudent] = useState(null);
  const [openModal, setOpenModal] = useState(false);  

  const navigate = useNavigate();

  useEffect(() => {
    if (studentProfile) {
      setStudent(studentProfile);
    }
  }, [studentProfile]);

  useEffect(() => {
    if (studentProfile && student) {
      if (!student.finishedSetup) {
        navigate(`/create-student-account/${studentProfile?._id}`);
      } else {
        return;
      }
    }
  }, [studentProfile, student]);
  useEffect(() => {
    // Use the Image constructor to load all your images
    const images = [
      playButton,
      startButton,
      locked,
      ready,
      threeStars,
      oneStar,
      twoStars,
      settings,
      dailyStatus,
      dailyStatusProgress,
      gradeStatus,
      gradeStatusProgress,
      livesStatus,
      liveStatusProgress,
      becnhMarkRocket,
    ];

    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
    };

    // Use Promise.all to wait for all images to load
    Promise.all(images.map(loadImage))
      .then(() => setIsLoading(false))
      .catch(() => console.error("Failed to load images"));
  }, []);

  const spaceBG = colorOptions[studentProfile?.color]?.leftColor;
  const opacity = colorOptions[studentProfile?.color]?.rightColor;
  const lesScores = [coldReady, locked, oneStar, twoStars, threeStars, coldFinish, hotReady, hotFinish, dayOneReady, dayTwoReady, dayThreeReady];
  const handleStart = () => {
    setIsLoading(true);
    const lessonRef = studentProfile?.currentLesson;

    if (!studentProfile?.firstLessonCompleted) {
      navigate(`/student/dashboard/first-lesson`);
      setIsLoading(false);
    } else if (studentProfile?.benchmarksAssigned) {
      setStartTime(new Date().getTime());
      navigate(`/student/dashboard/benchmark`);
      setIsLoading(false);
    } else if (studentProfile?.hotReadAssigned) {
      navigate(`/student/dashboard/cold-hot-reads`);
      setIsLoading(false);
    } else if (studentProfile?.coldReadAssigned) {
      navigate(`/student/dashboard/cold-hot-reads`);
      setIsLoading(false);
    } else {
      startLesson(studentProfile?.lessonsAssigned[lessonRef]?.path);
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    navigate("/");
  };

 



  return (
    <>
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <p>Loading...</p>
          </div>
        }
      >
        <ParentLoginModal open={openModal} setOpen={setOpenModal} studentProfile={studentProfile}/>
        <div
          className={`bg-center bg-cover bg-gradient-to-r min-h-screen h-full ${spaceBG} ${opacity}`}
        >
          <div className="max-w-full p-4 mx-auto">
            <div className="lg:flex lg:items-center">
              <div className="flex items-center">
                <img
                  className={`h-24 text-sm font-semibold leading-6 text-gray-900 shadow-xl rounded-full p-2 border-4 border-white ${studentProfile?.avatar?.bgColor}`}
                  src={files[studentProfile?.avatar?.imageIndex]}
                  alt=""
                />
                <div className="text-white sm:text-7xl text-5xl font-londrina tracking-widemb-4 ml-2 drop-shadow">
                  {studentProfile?.firstName}
                </div>
              </div>
              <div className="lg:mx-auto flex items-center lg:shadow-black shadow-sm rounded-full h-16 mt-6 lg:mt-0">
                {/* <div className="p-3 bg-blue-600 rounded-full -mr-24 text-white font-gbold text-5xl flex items-start justify-center z-20">{studentProfile?.totalWordsRead > 0 ? studentProfile?.totalWordsRead : "0000"}
                </div> */}
                <div
                  className={`${studentProfile?.avatar?.bgColor} bg-opacity-60 w-80 py-4 rounded-l-full pl-12 text-white font-gbold text-2xl`}
                >
                 Star Progress
                </div>
                <button
                  className={`${studentProfile?.avatar?.bgColor}   shadow shadow-black hover:bg-violet-600 px-12 py-4 rounded-full -ml-24  text-white font-gbold text-2xl text-left flex items-center`}
                >
                  <img
                    src={star}
                    className="w-8 drop-shadow-lg shadow-gray-50"
                    alt="star"
                  />{" "}
                  <span className="ml-2">
                    {studentProfile?.totalStars}
                  </span>
                </button>
              </div>

              <button className=" text-white mr-2  hover:bg-teal-500 rounded-full p-4"
              onClick={() => setOpenModal(true)}
              >
                Parent Login
              </button>

              <div className="hidden lg:block">
                <button
                  type="button"
                  className="tracking-wide px-6 py-3 border border-transparent text-3xl font-gbold rounded-full shadow-sm text-gray-900 bg-yellow-300 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            </div>
            <div className="mt-12 grid grid-cols-1 lg:grid-cols-12 gap-3">
              <div
                className={`overflow-hidden rounded-lgshadow lg:col-span-7 border-8 bg-opacity-30 bg-white `}
              >
                <div className="px-4 py-5 sm:p-6">
                  <div className="text-4xl font-gbold drop-shadow-sm text-white">
                    Today's Lesson
                  </div>
                  <div className="grid grid-cols-5 gap-4 mx-auto max-w-2xl mt-12">
                    {!studentProfile?.firstLessonCompleted ? (
                      <div className="col-span-5 z-10 flex items-center justify-center">
                        <div>
                          <img
                            className="xl:h-36 xl:w-36 h-24 w-24 flex items-center drop-shadow-lg"
                            src={becnhMarkRocket}
                            alt=""
                          />
                        </div>
                        <div className="sm:text-5xl font-londrina  tracking-wide text-white text-2xl">
                          Start Practice Lesson
                        </div>
                      </div>
                    ) : studentProfile?.benchmarksAssigned ? (
                      <div className="col-span-5 z-10 flex items-center justify-center">
                        <div>
                          <img
                            className="xl:h-36 h-24 flex items-center drop-shadow-lg"
                            src={becnhMarkRocket}
                            alt=""
                          />
                        </div>
                        <div className="sm:text-5xl font-londrina tracking-wide text-white text-2xl">
                          Week {studentProfile?.week} Ready
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-span-1">
                          <div>
                            <img
                              className="aspect-h-10  aspect-w-10 flex items-center drop-shadow-lg"
                              src={
                                lesScores[studentProfile?.lessonScores?.score1 === 1 ? 0 : 5]
                              }
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="col-span-1">
                          <div>
                            <img
                              className="aspect-h-10  aspect-w-10 flex items-center drop-shadow-lg"
                              src={
                                lesScores[studentProfile?.lessonScores?.score2 === 0 ? 1 : studentProfile?.lessonScores?.score2 === 1 ? 8 : studentProfile?.lessonScores?.score2]
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div>
                            <img
                              className="aspect-h-10  aspect-w-10 flex items-center drop-shadow-lg"
                              src={
                                lesScores[studentProfile?.lessonScores?.score3 === 0 ? 1 : studentProfile?.lessonScores?.score3 === 1 ? 9 : studentProfile?.lessonScores?.score3]
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div>
                            <img
                              className="aspect-h-10  aspect-w-10 flex items-center drop-shadow-lg"
                              src={
                                lesScores[studentProfile?.lessonScores?.score4 === 0 ? 1 : studentProfile?.lessonScores?.score4 === 1 ? 10 : studentProfile?.lessonScores?.score4]
                              }
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="col-span-1">
                          <div>
                            <img
                              className="aspect-h-10  aspect-w-10 flex items-center drop-shadow-lg"
                              src={
                                lesScores[studentProfile?.lessonScores?.score5 === 0 ? 6 : 7]
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <button
                      className={`col-span-5 mt-4 text-white font-gbold ${studentProfile?.avatar?.bgColor} px-24 text-4xl py-4 mx-auto hover:focus-ring hover:ring-4 shadow shadow-black  hover:bg-opacity-80  hover:ring-white rounded-full p-1 z-10 animate-bounce`}
                      onClick={handleStart}
                    >
                      Start
                    </button>
                  </div>
                </div>
              </div>
              <div className="overflow-hidden rounded-lg bg-red-500 bg-opacity-30 shadow lg:col-span-5 border-8 border-white">
                <div className="px-4 py-5 sm:p-6">
                  <div className="text-4xl font-gbold drop-shadow-sm text-white mb-8">
                    Completed Lesson
                  </div>
                  {studentProfile?.completedTitles?.length > 0 ? (
                    <div className="mt-12">
                      <p className="text-white font-londrina tracking-wide xl:text-3xl text-lg drop-shadow-md p-2 rounded-lg text-center bg-blue-500">
                        Completed Lessons
                      </p>
                      <div className="grid grid-cols-4 gap-2 mt-4">
                        {studentProfile?.completedTitles?.map(
                          (lesson, index) => (
                            <button
                              className="bg-white hover:bg-gray-200 focus:shadow-none text-center p-4 rounded-md shadow-md shadow-black"
                              key={index}
                              onClick={() => {
                                setOpen(true);
                                setCurrentVid(lesson?.videoLink);
                                setTitle(lesson?.title);
                              }}
                            >
                              <p className="col-span-3 text-2xl text-zinc-900 font-londrina tracking-wide drop-shadow-xl mr-4 text-center">
                                {lesson.title}
                              </p>
                              <div>
                                <PlayCircleIcon className="h-12 text-emerald-800  bg-white rounded-full hover:text-green-600 mx-auto" />
                              </div>
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <p
                      className={`text-white font-londrina tracking-wide xl:text-3xl text-lg shadow-md shadow-black p-2 rounded-lg text-center ${studentProfile?.avatar?.bgColor}`}
                    >
                      No Completed Lessons Yet
                    </p>
                  )}
                </div>
              </div>
              <div className="overflow-hidden rounded-lg  bg-amber-400 bg-opacity-30 shadow lg:col-span-4 border-8">
                <div className="px-4 py-5 sm:p-6">
                  <div className="text-4xl font-gbold drop-shadow-sm text-white">
                    Awards
                  </div>
                  <div className="grid 2xl:grid-cols-4 lg:grid-cols-2 sm:grid-cols-4 grid-cols-2  gap-2 mt-4">
                    {studentProfile?.hasAwards ? (
                      <>
                        {studentProfile?.booksToEarn?.map((book, index) => {
                          // Check if the current book exists in the awards array
                          const isAwarded = studentProfile?.booksEarned?.some(
                            (awardedBook) => awardedBook === index
                          );

                          return (
                            <div key={index}>
                              <img
                                src={books[index].source}
                                className={`h-24 w-24 ${
                                  isAwarded
                                    ? "opacity-100 drop-shadow-lg"
                                    : "opacity-25 p-4"
                                }`}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      // Render something else if the student doesn't have awards
                      // For example, you can display only the books from studentProfile?.books
                      <>
                        {studentProfile?.booksToEarn?.map((book, index) => (
                          <div key={index}>
                            <img
                              src={books[index].source}
                              className="h-24 opacity-25"
                              alt=""
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="overflow-hidden rounded-lg bg-green-500 bg-opacity-30 shadow  lg:col-span-4 border-8">
                <div className="px-4 py-5 sm:p-6">
                  <div className="text-4xl font-gbold drop-shadow-sm text-white mb-8">
                    Assigned Lessons
                  </div>
                  {studentProfile?.assignedLessons?.length > 0 ? (
                    <div className="mt-4">
                     
                      {studentProfile?.assignedLessons?.map((lesson, index) => (
                        <div
                          key={index}
                          className="grid grid-cols-4 gap-2 mt-4"
                        >
                          {/* <button>
                            <img
                              src={playButton}
                              className="h-12 col-span-1"
                              alt=""
                            />
                          </button> */}
                          <p className="col-span-3 text-4xl text-zinc-50 font-londrina tracking-wide drop-shadow-xl mr-4 flex items-center">
                            {lesson.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p
                      className={`text-white font-londrina tracking-wide xl:text-3xl text-lg shadow-md shadow-black p-2 rounded-lg text-center ${studentProfile?.avatar?.bgColor}`}
                    >
                      No Assigned Lessons Yet
                    </p>
                  )}
                </div>
              </div>
              <Video
                open={open}
                setOpen={setOpen}
                video={currentVid}
                title={title}
              />
              <div className="overflow-hidden rounded-lg  bg-blue-500 bg-opacity-30 shadow  lg:col-span-4 border-8">
                <div className="px-4 py-5 sm:p-6">
                  <div className="text-4xl font-gbold drop-shadow-sm text-white">
                    Videos
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}
