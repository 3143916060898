import { useState } from "react";
import girl from "../assets/girl-reading-l2l.png";
import shape from "../assets/shape.svg";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];

export default function Hero() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-transparent">
      <div className="relative isolate">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-6 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-24">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto md:pl-12">
            <div className="mt-10 max-w-lg text-4xl font-gbold tracking-tight text-gray-900 sm:text-5xl">
              <div>A Groundbreaking,</div>
              <div className="text-red-400/90 mt-2">Music-Based Reading</div>
              <img
                src={shape}
                alt="line"
                className="mt-2 flex items-center w-5/6"
              />
              <div className="mt-2">Program for K-5th</div>
            </div>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Add Lyrics2Learn to any home curriculum and watch reading soar.
            </p>
            <div className="mt-10 md:flex md:items-center md:gap-x-6 w-full">
              <Link 
              to="/free-trial"
              className="relative gap-x-1.5 rounded-md bg-red-400/90 px-8 py-4 text-lg font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">
                20-Day Free Trial
              </Link>
              <Link
              to="https://readingmastery.org/df-quiz/reading-mastery-qualification-survey-2/" 
              className="relative mt-6 md:mt-0 inline-flex gap-x-1.5 rounded-md bg-white border border-red-400/90  px-8 py-4 text-lg font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-red-400/90  shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">
                Free Online Tutoring Consultation
              </Link>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <img
              src={girl}
              alt="girl reading"
              className="rounded-full mx-auto w-[25.875rem] h-[25.875rem] object-center object-cover max-w-full drop-shadow-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
