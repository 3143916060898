import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import webImg from "../assets/webinar.png";
import logo from "../assets/white-logo.png";
import {
  Bars3BottomLeftIcon,
  ChartBarIcon,
  CogIcon,
  HomeIcon,
  PencilIcon,
  PhotoIcon,
  PlayCircleIcon,
  PlusCircleIcon,
  PlusIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  Squares2X2Icon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import Main from "./pages/Main";
import { UserAuth } from "../context/AuthContext";
import Student from "./pages/Student";
import StudentQuizGrader from "./pages/StudentQuizGrader";
import AssignLesson from "./pages/AssignLesson";
import AddNewStudent from "./pages/AddNewStudent";
import Recordings from "./pages/Recordings";
import Settings from "./pages/Settings";
import Reports from "./pages/Reports";
import { useEffect } from "react";
import SideFaq from "./components/ParentSideFAQ";
import Videos from "./pages/Videos";

const sidebarNavigation = [
  {
    name: "Home",
    href: "#",
    icon: HomeIcon,
    current: 0,
    back: "bg-red-500",
    text: "text-white",
  },
  {
    name: "Students",
    href: "#",
    icon: UserGroupIcon,
    current: 1,
    back: "bg-orange-500",
    text: "text-white",
  },
  {
    name: "Grader",
    href: "#",
    icon: PencilIcon,
    current: 2,
    back: "bg-yellow-500",
    text: "text-white",
  },
  {
    name: "Lessons",
    href: "#",
    icon: RocketLaunchIcon,
    current: 3,
    back: "bg-green-500",
    text: "text-white",
  },
  {
    name: "Add Student",
    href: "#",
    icon: PlusCircleIcon,
    current: 4,
    back: "bg-teal-500",
    text: "text-white",
  },
  {
    name: "Recordings",
    href: "#",
    icon: PlayCircleIcon,
    current: 5,
    back: "bg-violet-500",
    text: "text-white",
  },
  {
    name: "Reports",
    href: "#",
    icon: ChartBarIcon,
    current: 6,
    back: "bg-blue-500",
    text: "text-white",
  },

  {
    name: "Settings",
    href: "#",
    icon: CogIcon,
    current: 7,
    back: "bg-gray-50",
    text: "text-slate-950",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { profile, logout, curentNumber, setCurrentNumber, setIsLoading } = UserAuth();
  const [sideOpen, setSideOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  let currentPage = [
    <Main />,
    <Student />,
    <StudentQuizGrader />,
    <AssignLesson />,
    <AddNewStudent />,
    <Videos />,
    <Reports />,
    <Settings />,
  ];
  

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="h-full">
      <div className="h-full overflow-hidden">
        <div className="flex h-full">
          {/* Narrow sidebar */}
          <div className="hidden w-28 overflow-y-auto bg-slate-950 md:block">
            <div className="flex w-full flex-col items-center py-6">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt="Lyrics2Learn at Home"
                />
              </div>
              <div className="mt-6 w-full flex-1 space-y-1 px-2">
                {sidebarNavigation.map((item) => (
                  <Link
                    key={item.name}
                    href={item.href}
                    className={`${
                      item.current === curentNumber &&
                      "border-amber-400 border-4"
                    } text-indigo-100 hover:bg-violet-800 hover:text-white ${
                      item.back
                    } group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium ${
                      item.text
                    }`}
                    aria-current={item.current ? "page" : undefined}
                    onClick={(e) => {
                      setCurrentNumber(item.current);
                    }}
                  >
                    <item.icon
                      className="group-hover:text-white h-6 w-6"
                      aria-hidden="true"
                    />
                    <span className={`mt-2 font-bold whitespace-nowrap`}>
                      {item.name}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Transition.Root show={mobileMenuOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-20 md:hidden"
              onClose={setMobileMenuOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pb-4 pt-5">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute right-0 top-1 -mr-14 p-1">
                        <button
                          type="button"
                          className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Close sidebar</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="Your Company"
                      />
                    </div>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                      <nav className="flex h-full flex-col">
                        <div className="space-y-1">
                          {sidebarNavigation.map((item) => (
                            <Link
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-indigo-800 text-white"
                                  : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
                                "group flex items-center rounded-md py-2 px-3 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                              onClick={(e) => {
                                setCurrentNumber(item.current);
                              }}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-white"
                                    : "text-indigo-300 group-hover:text-white",
                                  "mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              <span>{item.name}</span>
                            </Link>
                          ))}
                        </div>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Content area */}
          <div className="flex flex-1 flex-col overflow-hidden">
            <header className="w-full">
              <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
                <button
                  type="button"
                  className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex flex-1 justify-between px-4 sm:px-6">
                  <div className="flex flex-1">
                    {/* <form
                      className="flex w-full md:ml-0"
                      action="#"
                      method="GET"
                    >
                      <label htmlFor="search-field" className="sr-only">
                        Search all files
                      </label>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                          <MagnifyingGlassIcon
                            className="h-5 w-5 flex-shrink-0"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          name="search-field"
                          id="search-field"
                          className="h-full w-full border-0 py-2 pl-8 pr-3 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder:text-gray-400 sm:text-sm"
                          placeholder="Search"
                          type="search"
                        />
                      </div>
                    </form> */}
                  </div>
                  <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative flex-shrink-0">
                      <div>
                        <Menu.Button className="flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <div className="mr-2">{profile?.firstName} {profile?.lastName}</div>
                          <UserCircleIcon className="h-8 w-8 rounded-full" />
                          {/* <img
                            className="h-8 w-8 rounded-full"
                            src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                            alt=""
                          /> */}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {/* <Menu.Item>
                            <Link
                              to="/profile"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-red-50"
                            >
                              Your Profile
                            </Link>
                          </Menu.Item> */}
                          <Menu.Item>
                            <button
                              className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-red-50"
                              onClick={handleLogout}
                            >
                              Logout
                            </button>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </header>

            {/* Main content */}
            <div className="flex flex-1 items-stretch overflow-hidden">
              <main className="flex-1 overflow-y-auto relative">
                {currentPage[curentNumber]}
                <button
                  onClick={() => {
                    setSideOpen(!sideOpen);
                  }}
                >
                  {sideOpen ? (
                    <ArrowRightCircleIcon className="hidden xl:block  absolute top-0 right-0 h-12 w-12 text-red-500 " />
                  ) : (
                    <QuestionMarkCircleIcon className="hidden xl:block  absolute top-0 right-0 h-12 w-12 text-green-500 " />
                  )}
                </button>
              </main>

              {/* Secondary column (hidden on smaller screens) */}
              {sideOpen && (
                <aside className="hidden w-96 overflow-y-auto border-l border-gray-200 bg-white xl:block px-3">
                  {submitted ? (
                    <div>
                      <h1 className="text-center text-xl font-bold mt-4">
                        Thank you for signing up!
                      </h1>
                      <p className="text-center text-sm text-gray-500">
                        {" "}
                        We will be in touch soon.
                      </p>
                    </div>
                  ) : (
                    <div>
                    <h1 className="text-center text-xl font-bold mt-4">
                      Live Webinar with Teacher and Founder Every Wednesday at
                      6:00 EST
                    </h1>
                    <div>
                      <img
                        src={webImg}
                        alt="webinar"
                        className="border-2 rounded-md object-center object-cover border-rose-300"
                      />
                    </div>
                    <p className="text-center text-sm text-gray-500">
                      {" "}
                      Jeremy works with parents every week to help you and your
                      child succeed. Join us!
                    </p>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter your email"
                        className="border-2 rounded-md border-rose-300 w-full mt-4 pr-8 py-4"
                      />
                    </div>
                    <div className="flex justify-center mt-4">
                      <button className="relative gap-x-1.5 rounded-md bg-red-400/90 px-8 py-4 text-lg font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full "
                      onClick={() => {setSubmitted(true)}}>
                        Sign Up
                      </button>
                    </div>
                    <p className="text-center text-sm text-gray-500 ">
                      We respect your privacy. Unsubscribe at any time.
                    </p>
                    </div>
                  )}
                    <SideFaq />
                  
                </aside>
              )}
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
