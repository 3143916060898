import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  finishFirstLesson,
} from "../../config/lessonFunctions";

const FirstQuiz = () => {
  const { startTime, setStartTime, currentLesson, studentProfile } = UserAuth();
  const [score, setScore] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [lyricsCount, setLyricsCount] = useState(0);

  const navigate = useNavigate();

  const handleAnswerOptionClick = (isCorrect) => {
    if (isCorrect) {
      setScore([...score, 1]);
    } else {
      setScore([...score, 0]);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < currentLesson?.quiz?.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  const handleback = () => {
    const newScoreArr = [...score]; // Create a copy of the score array
    newScoreArr.pop(); // Remove the last element from the copy

    setScore(newScoreArr);
    setCurrentQuestion(currentQuestion - 1);
  };

  useEffect(() => {
    if (currentLesson) {
      const words = currentLesson?.lyricLines?.map((lyric) => lyric.split(" "));
      const count = words
        ?.map((word) => word.length)
        .reduce((a, b) => a + b, 0);
      setLyricsCount(count);
    }
  }, [currentLesson]);

  const finalScore = score.map((a) => a).reduce((a, b) => a + b, 0);
  const handleScore = () => {
    const finalTime = new Date().getTime() - startTime;
    finishFirstLesson(
      studentProfile?.studentId,
      studentProfile?.parentId,
      finalTime,
      lyricsCount,
    );
    setStartTime(null);
    navigate("/award/book/0");
  };

  return (
    <div className="quiz">
      {showScore ? (
        <>
          <div className="score-section font-londrina text-7xl text-center">
            You scored <span className="text-emerald-700">{finalScore}</span>{" "}
            out of{" "}
            <span className="text-emerald-700">
              {currentLesson?.quiz?.length}
            </span>
            !
          </div>
          <div className="score-section font-londrina text-7xl text-center">
            {(finalScore / currentLesson?.quiz?.length) * 100}%
          </div>
          <button
            className="p-4 bg-yellow-300 text-7xl font-londrina mx-auto mt-4 rounded-md w-full hover:bg-yellow-500"
            onClick={handleScore}
          >
            Next
          </button>
          {(finalScore / currentLesson?.quiz?.length) * 100 < 60 && (
            <div className="score-section font-londrina text-7xl text-center">
              You need to score at least 60% to pass the quiz.
            </div>
          )}
        </>
      ) : (
        <>
          <div className="question-section">
            <div className="text-2xl font-bold font-londrina">
              <span>Question {currentQuestion + 1}</span>/
              {currentLesson?.quiz?.length}
            </div>
            <div className="text-4xl font-bold">
              {currentLesson?.quiz[currentQuestion]?.question}
            </div>
          </div>
          <div className="answer-section mt-4 grid grid-cols-1 gap-4">
            {currentLesson?.quiz[currentQuestion]?.answers?.map(
              (answerOption, index) => (
                <button
                  key={index}
                  className={`${
                    index ===
                    parseInt(
                      currentLesson?.quiz[currentQuestion]?.correctAnswer
                    )
                      ? "bg-indigo-500 hover:bg-indigo-700 animate-bounce"
                      : "bg-emerald-500 hover:bg-emerald-700"
                  } p-4 rounded-full text-white capitalize text-2xl font-bold `}
                  onClick={() =>
                    handleAnswerOptionClick(
                      index ===
                        parseInt(
                          currentLesson?.quiz[currentQuestion]?.correctAnswer
                        )
                    )
                  }
                  disabled={
                    index ===
                    parseInt(
                      currentLesson?.quiz[currentQuestion]?.correctAnswer
                    )
                      ? false
                      : true
                  }
                >
                  {answerOption}
                </button>
              )
            )}
          </div>
          {currentQuestion > 0 && (
            <button
              className="bg-blue-500 text-white text-lg font-londrina p-4 rounded-full mt-4 flex items-center"
              onClick={() => {
                handleback();
              }}
            >
              <ArrowLeftIcon className="h-6 flex mr-2" /> Go Back
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default FirstQuiz;
