import { CloudArrowUpIcon, LockClosedIcon, MusicalNoteIcon, ServerIcon } from '@heroicons/react/20/solid'
import logo from "../assets/long-logo.png";
import lefty from "../assets/lefty-speech.png";
import { Link } from 'react-router-dom';

const features = [
  {
    name: 'Control lessons ',
    description:
      'assigned, or let the program automatically guide your child through a personlaized learning path',
    icon: MusicalNoteIcon,
  },
  {
    name: 'Reading lessons ',
    description: 'Kids can work through six years of reading lessons ',
    icon: MusicalNoteIcon,
  },
  {
    name: 'Use our full reading evaluation',
    description: "to identify skills to help you target and improve your child's phonemic awareness, phonics, fluency, and comprehension",
    icon: MusicalNoteIcon,
  }
]

const featuresTwo = [
    {
      name: 'Hundreds of reading lessons',
      description:
        ' integrating science, history and character building',
      icon: MusicalNoteIcon,
    },
    {
      name: 'Fusing music with reading',
      description: ' rapidly increases fluency, word recognition, vocabulary, and comprehension',
      icon: MusicalNoteIcon,
    },
    {
      name: 'Recorded Lessons',
      description: "We'll help you use comprehension and fluency recordings to help your child grow, succeed, and become confident",
      icon: MusicalNoteIcon,
    },
  ]

export default function Finale() {
  return (
    <div className="overflow-hidden bg-transparent py-24 sm:py-32 px-4 bg-white">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                <img src={logo} alt="" className="rounded-lg w-56" />
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Why our Fluency Program Works</h2>
              <p className="mt-2 text-3xl font-gbold tracking-tight text-gray-900 sm:text-4xl">Kids using L2L independently for 15 minutes per day average <span className='text-emerald-600'>66% greater </span>reading gains.</p>
              <p className="mt-6 text-xl font-gbold tracking-tight text-gray-900 sm:text-2xl">
              Kids who receive research-based, targeted instruction along with L2L average <span className='text-emerald-600'>1+ year of gain every 4-5 months</span>.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <div className='flex justify-center'>
                <Link to="/pricing" className='bg-indigo-600 text-white px-8 py-2 hover:bg-indigo-700 rounded-full text-2xl mt-3'>$1500 Value</Link>
            </div>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {featuresTwo.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <div className='flex justify-center'>
                <Link to="/pricing" className='bg-indigo-600 text-white px-8 py-2 hover:bg-indigo-700 rounded-full text-2xl mt-3'>Learn More</Link>
            </div>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
              <div
                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                aria-hidden="true"
              />
              <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                <img
                  src={lefty}
                  alt="lefty"
                  width={2432}
                  height={1442}
                  className="mb-12 pr-12"
                />
              </div>
              <div
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                aria-hidden="true"
              />
            </div>
            <div className='flex justify-center mt-4'>
            <Link
                  to="/free-trial"
                  className="relative text-center gap-x-1.5 rounded-md bg-amber-400 w-full px-12 py-5 text-2xl font-semibold hover:ring-1 hover:ring-offset-indigo-400 hover:ring-indigo-400 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                 Start 20-Day Free Trial
                </Link>
            </div>
            <div className='flex justify-center'>
                <button className=' text-teal-500 px-8 py-2 hover:text-teal-700 text-2xl mt-3 w-96'>Free Tutoring Consultation</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
