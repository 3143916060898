import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import files from "../../assets/Images";
import { createStudent } from "../../helpers/firebaseFunctions";
import { useNavigate } from "react-router-dom";
import LoginSuccess from "../components/LoginSuccess";

export default function AddNewStudent() {
  const { profile, isParent } = UserAuth();
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [studentBirthYear, setStudentBirthYear] = useState("");
  const [agreeCoppa, setAgreeCoppa] = useState(false);
  const [gradeLevel, setGradeLevel] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const studentData = {
    email: profile?.email,
    name: profile?.name,
    userName: userName,
    studentName: studentName,
    studentBirthYear: studentBirthYear,
    agreeCoppa: agreeCoppa,    
    gradeLevel: gradeLevel,
    imageIndex: imageIndex,
    parentID: profile?.id,  
    parentEmail: profile?.email,  
  };

  const handleSaveNewStudent = () => {
    setLoading(true);
    createStudent(studentData);
    setTimeout(() => {
      setLoading(false);
      navigate("/student/dashboard")
    }, 1500);
  };

  useEffect(() => {
    if (isParent) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isParent]);

  const handleClose = () => {
    setOpen(false);
  };



  if (loading) {
    return (
    <div>Loading...</div>
    )
  } 

  return (
    <div className="sm:py-12 py-8">
      <LoginSuccess open={open} handleClose={handleClose} />
      <h1 className="text-lg font-semibold text-center">Add New Student</h1>
      <div className="w-full max-w-3xl mx-auto bg-white px-4 py-12 rounded-md shadow mt-8">
        <div className="grid sm:grid-cols-4 grid-cols-2 gap-4">
          <div className="sm:col-span-2 col-span-4 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              Students First Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 px-3 py-2 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="First Name"
              onChange={(e) => {
                setStudentName(e.target.value);
              }}
            />
          </div>
          <div className="col-span-4 sm:col-span-2 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              Username
            </label>
            <input
              type="text"
              name="username"
              id="username"
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 px-3 py-2 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="User Name"
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </div>
          <div className="col-span-4 sm:col-span-2 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              {studentName}
              {studentName && "'s"} Birth Year
            </label>
            <select
              id="location"
              name="location"
              className="block w-full border-0 px-3 py-2 text-gray-900 focus:ring-0 "
              defaultValue="Child's Birth Year"
              onChange={(e) => {
                setStudentBirthYear(e.target.value);
              }}
            >
              <option className="px-3">2022</option>
              <option>2021</option>
              <option>2020</option>
              <option>2019</option>
              <option>2018</option>
              <option>2017</option>
              <option>2016</option>
              <option>2015</option>
              <option>2014</option>
              <option>2015</option>
              <option>2014</option>
              <option>2013</option>
              <option>2012</option>
              <option>2011</option>
              <option>2010</option>
              <option>2009</option>
              <option>2008</option>
              <option>2007</option>
              <option>2006</option>
              <option>2005</option>
              <option>2004</option>
              <option disabled>Child's Birth Year</option>
            </select>
          </div>
          <div className="col-span-4 sm:col-span-2 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              {studentName}
              {studentName && "'s"} Grade Level
            </label>
            <select
              id="location"
              name="location"
              className="block w-full border-0 px-3 py-2 text-gray-900 focus:ring-0 "
              defaultValue="Child's Birth Year"
              onChange={(e) => {
                setGradeLevel(e.target.value);
              }}
            >
              <option value="k">Kindergarten</option>
              <option value="1">1st Grade</option>
              <option value="2">2nd Grade</option>
              <option value="3">3rd Grade</option>
              <option value="4">4th Grade</option>
              <option value="5">5th Grade</option>

              <option disabled>Current Grade Level</option>
            </select>
          </div>
          <div className="col-span-4 text-center">
            <a href="/" className="text-center mt-8 font-bold text-yellow-400">
              View COPPA Consent Notice
            </a>
            <div className="relative flex justify-center">
              <div className="flex h-5">
                <input
                  id="comments"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-yellow-400 focus:ring-yellow-300"
                  onChange={() => {
                    setAgreeCoppa(!agreeCoppa);
                  }}
                />
              </div>
              <div className="ml-3 text-sm">
                <p className="text-gray-500">
                  I {profile?.name}, as the Parent or Guardian of {studentName},
                  grant my COPPA consent
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-4 text-center">
            <div className="relative mx-auto max-w-lg">
              <h1 className="text-center text-3xl font-bold mt-12">
                Student Login Token
              </h1>
              {}
              <p className="text-center text-md mt-2">
                Select a token for your child to use on the site
              </p>
              <p className="text-center mb-8 text-sm text-gray-400">
                Students do not use passwords, we use images to ensure the
                privacy and safety of your student
              </p>
            </div>
            <div className="max-w-3xl mx-auto">
              <ul className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-8 xl:gap-x-8 mt-4">
                {files.map((file, index) => (
                  <li key={file.source} className="relative">
                    <div className="group aspect-w-10 aspect-h-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-emerald-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                      <img
                        src={file.source}
                        alt=""
                        className="pointer-events-none object-cover group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 focus:outline-none"
                        onClick={() => setImageIndex(index)}
                      >
                        <span className="sr-only text-center">
                          View details for {file.title}
                        </span>
                      </button>
                    </div>
                    <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900 text-center">
                      {file.title}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className="bg-emerald-500 hover:bg-emerald-600 text-white font-bold py-2 px-4 rounded mt-8"
            onClick={handleSaveNewStudent}
          >
            Save New Student
          </button>
        </div>
      </div>
    </div>
  );
}
