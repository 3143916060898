const { REACT_APP_API_URL } = process.env;


export const createStripeSubscription = async (
  token,
  email,
  phone,
  name,
  cardNumber,
  expMonth,
  expYear,
  cvc,
  zip,
  items,
  amount,
  description
) => {
  try {
    const response = await fetch("https://us-central1-reading-mastery-7f9b1.cloudfunctions.net/createAccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        email,
        name,
        cardNumber,
        expMonth,
        expYear,
        cvc,
        zip,
        items,
        amount,
        description,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
