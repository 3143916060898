import {
  addDoc,
  collection,
  doc,
  query,
  getDocs,
  updateDoc,
  where,
  getDoc,
  arrayUnion,
  increment,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage } from "./firebase";
import { getDownloadURL, ref } from "firebase/storage";

export const startFirstLesson = async (videoRef) => {
  const lessonVideo = await getDoc(doc(db, videoRef));

  //create firestore reference from

  if (lessonVideo.exists()) {
    const lessonVideoData = await getDownloadURL(
      ref(storage, lessonVideo.data().lessonVideo)
    );
    return lessonVideoData;
  } else {
    console.log("No such document!");
  }
};

export const getVideoForLesson = async (lessonVideo) => {
  const lessonVideoData = await getDownloadURL(ref(storage, lessonVideo));
  return lessonVideoData;
};

export const findLessonByTitle = async (lessonTitle, grade) => {
  const lessonQuery = query(
    collection(db, "lessons", grade, "day1"),
    where("title", "==", lessonTitle)
  );

  const lessons = []

  const querySnapshot = await getDocs(lessonQuery);
  querySnapshot.forEach((doc) => {
    lessons.push(doc.data());
  });
  return getVideoForLesson(lessons[0].lessonVideo)


};

//create firestore reference from

export const checkForExistingUserName = async (userName) => {
  try {
    const docRef = doc(db, "userNames", userName);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
};

export const finishFirstLesson = async (
  studentID,
  parentID,
  totalTime,
  lyricsCount
) => {
  const studentRef = doc(db, "students", studentID);
  const studentSnap = await getDoc(studentRef);
  const books = [0];
  const booksToEarn = [0, 1, 2, 3, 4, 5, 6, 7];
  if (studentSnap.exists()) {
    await updateDoc(studentRef, {
      lessonsComplete: increment(1),
      firstLessonCompleted: true,
      hasAwards: true,
      booksEarned: books,
      booksToEarn: booksToEarn,
      totalStars: 3,
      grades: [100],
      timeSpentLearning: totalTime,
      fluencyPracticeTime: totalTime,
      totalWordsRead: lyricsCount,
    });
  } else {
    console.log("No such document!");
  }
  const parentRef = doc(db, "users", parentID);
  const parentSnap = await getDoc(parentRef);
  if (parentSnap.exists()) {
    await updateDoc(parentRef, { alert: true });
  } else {
    console.log("No such document!");
  }
};

export const startFirstQuiz = async (videoRef) => {
  const lesson = await getDoc(doc(db, videoRef));
  if (lesson.exists()) {
    const quiz = lesson.data();
    return quiz;
  } else {
    console.log("No such document!");
  }
};

export const getUserByParentID = async (parentID) => {
  try {
    const q = query(
      collection(db, "newStudents"),
      where("parentID", "==", parentID)
    );
    const querySnapshot = await getDocs(q);

    const users = []; // Initialize an array to store the documents

    querySnapshot.forEach((doc) => {
      // Push each document's data into the array
      users.push({
        id: doc.id,
        data: doc.data(),
      });
    });

    console.log(users); // Optional: You can log the array if you want to see its content

    return users; // Return the array of documents
  } catch (err) {
    console.log(err);
    return []; // Return an empty array in case of an error
  }
};

export const startBenchMark = async (benchmarkID) => {
  console.log("startBenchMark", benchmarkID);
  const docRef = doc(db, "benchmarks", benchmarkID);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    console.log("No such document!");
  }
};

// export const uploadBenchMarkVideo = async (video, id, benchmark) => {
//   if (video === null) {
//     return;
//   } else {
//     const videoRef = ref(storageForSecondBucket, `benchmarks/${id}/${benchmark}`);
//     const uploadTask = uploadBytes(videoRef, video);
//     console.log("uploadTask", uploadTask);
//   }
// };

export const studentCompletedBenchmark = async (lessonData, url) => {
  console.log("studentCompletedBenchmark", url);
  const studentRef = doc(db, "students", lessonData?.studentId);
  const studentSnap = await getDoc(studentRef);
  if (studentSnap.exists()) {
    await updateDoc(studentRef, {
      completedBenchMarks: arrayUnion(lessonData?.benchmarkID),
      benchmarksAssigned: false,
      lessonsComplete: increment(1),
      lexileScore: arrayUnion(lessonData?.lexileScore),
      benchmarksComplete: increment(1),
      timeSpentLearning:
        lessonData?.time + studentSnap.data().timeSpentLearning,
      benchMarkVideos: arrayUnion(url),
      totalWords: lessonData?.totalWords + studentSnap.data().totalWords,
    });
  }

  const parentRef = doc(db, "users", lessonData?.parentID);
  const parentSnap = await getDoc(parentRef);
  if (parentSnap.exists()) {
    await updateDoc(parentRef, {
      toView: increment(1),
    });
  } else {
    console.log("No such document!");
  }
};

export const updateAttempt = async (studentID) => {
  const studentRef = doc(db, "students", studentID);
  const studentSnap = await getDoc(studentRef);
  if (studentSnap.exists()) {
    await updateDoc(studentRef, {
      attempts: increment(1),
    });
  }
};

export const updateStudentScores = async (
  id,
  score,
  books,
  parent,
  award,
  hotReadUpdate,
  lessonRef,
  time,
  currentVideoLink,
  lessonNumber,
  totalQuestionsinQuiz,
  totalQuestionsCorrect
) => {
  const studentRef = doc(db, "students", id);
  const studentSnap = await getDoc(studentRef);
  if (studentSnap.exists()) {
    const studentData = studentSnap.data();
    const lessonScores = studentData.lessonScores || {};
    const currentLesson = studentData.currentLesson;

    lessonScores[`score${currentLesson + 2}`] = award + 1;
    lessonScores[`score${currentLesson + 3}`] = 1;

    let scroeVar = "";
    let dayFinished = "";
    let scoreAvg;
    let totalQuestionsAsked;
    let totalCorrect;

    if (currentLesson === 0) {
      scroeVar = "dayOneScore";
      dayFinished = "dayOneFinished";
      totalQuestionsAsked = "totalQuestionsAsked1";
      totalCorrect = "totalQuestions1";
      const currentAvg = studentData.dayOneScore;
      scoreAvg = (currentAvg + score) / studentData.dayOneFinished;
    } else if (currentLesson === 1) {
      scroeVar = "dayTwoScore";
      dayFinished = "dayTwoFinished";
      totalQuestionsAsked = "totalQuestionsAsked2";
      totalCorrect = "totalQuestions2";
      const currentAvg = studentData.dayTwoScore;
      scoreAvg = (currentAvg + score) / studentData.dayTwoFinished;
    } else {
      scroeVar = "dayThreeScore";
      dayFinished = "dayThreeFinished";
      totalQuestionsAsked = "totalQuestionsAsked3";
      totalCorrect = "totalQuestions3";
      const currentAvg = studentData.dayThreeScore;
      scoreAvg = (currentAvg + score) / studentData.dayThreeFinished;
    }

    const record = {
      score: score,
      day: currentLesson + 1,
      lessonRef: lessonRef,
      title: currentLesson.title,
    };

    await updateDoc(studentRef, {
      lessonScores: lessonScores,
      booksEarned: arrayUnion(books),
      grades: [...studentData.grades, score],
      currentLesson: increment(1),
      lessonsComplete: increment(1),
      [scroeVar]: [...studentData[scroeVar], score],
      [dayFinished]: increment(1),
      hotReadAssigned: hotReadUpdate,
      timeSpentLearning: time + studentData.timeSpentLearning,
      fluencyPracticeTime: time + studentData.fluencyPracticeTime,
      currentVideoLink: currentVideoLink,
      totalStars: increment(award),
    });

    const lessonRefID = lessonNumber + id;

    const lessontrack = await getDoc(doc(db, "lessonTracker", lessonRefID));
    const lessonTracksScores = lessontrack.data().lessonScores || [];
    lessonTracksScores.push(score);
    if (lessontrack.exists()) {
      await updateDoc(doc(db, "lessonTracker", lessonRefID), {
        lessonScores: lessonTracksScores,
        attempts: increment(1),
        fluencyPracticeTime: studentData.fluencyPracticeTime,
        comprehensionPracticeTime: studentData.fluencyPracticeTime,
        lastActiveDate: serverTimestamp(),
        [totalQuestionsAsked]: totalQuestionsinQuiz,
        [totalCorrect]: totalQuestionsCorrect,
      });
    }
  }
  const parentRef = doc(db, "users", parent);
  const parentSnap = await getDoc(parentRef);
  if (parentSnap.exists()) {
    await updateDoc(parentRef, { alert: true });
  }
};

export const removeView = (parentID) => {
  const parentRef = doc(db, "users", parentID);
  updateDoc(parentRef, { toView: 0 });
};

export const saveRead = async (lessonData, linkURL, readType) => {
  const lessonRefID = lessonData.lessonNumber + lessonData.studentId;
  const studentRef = doc(db, "students", lessonData.studentId);
  const studentSnap = await getDoc(studentRef);
  if (studentSnap.exists()) {
    const studentDataFromFB = studentSnap.data();
    const lessonsScores = studentDataFromFB.lessonScores || {};
    if (readType === "cold") {
      lessonsScores["score1"] = 4;
      lessonsScores["score2"] = 1;

      const lessontrack = await getDoc(doc(db, "lessonTracker", lessonRefID));
      const comprehgensionPracticeTime =
        lessontrack.data().comprehensionPracticeTime;
      if (lessontrack.exists()) {
        await updateDoc(doc(db, "lessonTracker", lessonRefID), {
          comprehensionPracticeTime:
            comprehgensionPracticeTime + lessonData.time,
          lastActiveDate: serverTimestamp(),
          coldReadVideo: linkURL,
        });
      }

      await updateDoc(studentRef, {
        coldReadComplete: true,
        coldReadAssigned: false,
        hotReadComplete: false,
        totalStars: increment(3),
        lessonsComplete: increment(1),
        lessonScores: lessonsScores,
        coldReadCount: increment(1),
        timeSpentLearning:
          lessonData.time + studentDataFromFB.timeSpentLearning,
        toView: increment(1),
      });
    } else {
      console.log("hot read");
      lessonsScores["score1"] = 1;
      lessonsScores["score2"] = 0;
      lessonsScores["score3"] = 0;
      lessonsScores["score4"] = 0;
      lessonsScores["score5"] = 0;

      let benchMarkAssigned = false;
      let week = studentDataFromFB.week + 1;

      let lesPlan = [];
      const days = ["day1", "day2", "day3"];
      const lessonNumber = studentDataFromFB.currentLessonReference + 1;
      const lastLesson = {
        1: 32,
        2: 59,
        3: 85,
        4: 110,
        5: 134,
        6: 164,
      };
      let newLessonTitle = "";

      for (const day of days) {
        const lessonQuery = query(
          collection(db, "lessons", studentDataFromFB.gradeLevel, day),
          where("lessonNumber", "==", lessonNumber)
        );

        const querySnapshot = await getDocs(lessonQuery);

        if (!querySnapshot.empty) {
          lesPlan.push(querySnapshot.docs[0].ref);
          newLessonTitle = querySnapshot.docs[0].data().title;
        } else {
          const lastLessonNumber = lastLesson[studentDataFromFB.gradeLevel];
          newLessonTitle = querySnapshot.docs[0].data().title;
          const lastLessonQuery = query(
            collection(db, "lessons", studentDataFromFB.gradeLevel, day),
            where("lessonNumber", "==", lastLessonNumber)
          );

          const lastLessonSnapshot = await getDocs(lastLessonQuery);

          if (!lastLessonSnapshot.empty) {
            lesPlan.push(querySnapshot.docs[0].ref);
          } else {
            console.log("No such document!");
          }
        }
      }

      if (studentDataFromFB.week === 3) {
        benchMarkAssigned = true;
      }

      if (studentDataFromFB.week === 4) {
        week = 1;
      }

      const vidSnap = await getDownloadURL(
        ref(storage, lessonData.lessonVideo)
      );

      const completedLesson = {
        title: lessonData.title,
        videoLink: vidSnap,
      };

      const lessontrack = await getDoc(doc(db, "lessonTracker", lessonRefID));
      const comprehgensionPracticeTime =
        lessontrack.data().comprehensionPracticeTime;
      if (lessontrack.exists()) {
        await updateDoc(doc(db, "lessonTracker", lessonRefID), {
          comprehensionPracticeTime:
            comprehgensionPracticeTime + lessonData.time,
          lastActiveDate: serverTimestamp(),
          hotReadVideo: linkURL,
          completed: true,
        });
      }

      await updateDoc(studentRef, {
        coldReadComplete: false,
        coldReadAssigned: true,
        hotReadComplete: true,
        hotReadAssigned: false,
        lessonsComplete: increment(1),
        lessonsAssigned: lesPlan,
        currentLessonReference: increment(1),
        currentLesson: 0,
        lessonScores: lessonsScores,
        totalStars: increment(3),
        hotReadCount: increment(1),
        timeSpentLearning:
          lessonData.time + studentDataFromFB.timeSpentLearning,
        toView: increment(1),
        week: week,
        benchmarksAssigned: benchMarkAssigned,
        completedTitles: arrayUnion(completedLesson),
      });
      const newLessonRefId = lessonNumber + lessonData.studentId;
      setDoc(doc(db, "lessonTracker", newLessonRefId), {
        studentId: lessonData.studentId,
        lessonId: lessonNumber,
        id: lessonRefID,
        completed: false,
        lessonScores: [],
        graded: false,
        attempts: 0,
        fluencyPracticeTime: 0,
        comprehensionPracticeTime: 0,
        lastActiveDate: serverTimestamp(),
        totalQuestionsAsked1: 0,
        totalQuestionsAsked2: 0,
        totalQuestionsAsked3: 0,
        totalQuestions1: 0,
        totalQuestions2: 0,
        totalQuestions3: 0,
        parentId: studentSnap.data().parentId,
        references: lesPlan,
        title: newLessonTitle,
        week: week,
      });
    }
  }
  const parentRef = doc(db, "users", lessonData.parentId);
  const parentSnap = await getDoc(parentRef);
  if (parentSnap.exists()) {
    await updateDoc(parentRef, {
      toView: increment(1),
      alert: true,
    });
  }
};

export const handleWatchedVideo = async (trackerID, type) => {
  const lessonRef = doc(db, "lessonTracker", trackerID);
  if (type === "cold") {
    await updateDoc(lessonRef, {
      coldReadViewed: true,
    });
  } else {
    await updateDoc(lessonRef, {
      hotReadViewed: true,
    });
  }
}


export const handleAssignLessons = async (
  assignedLessons,
  studentData,
  parentID
) => {
  try {
    const studentRef = doc(db, "students", studentData);
    const studentSnap = await getDoc(studentRef);
    if (studentSnap.exists()) {
      await updateDoc(studentRef, {
        assignedLessons: assignedLessons,
      });
    }
    const parentRef = doc(db, "users", parentID);
    await updateDoc(parentRef, {
      lastActive: new Date(),
    });
  } catch (err) {
    console.log(err);
  }
};
