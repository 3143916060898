import React from 'react'

export default function TermsOfService() {
  return (
    <div className='max-w-5xl mx-auto px-12 gap-y-3 grid grid-cols-1'>
        <div className='text-center'>Terms of Use</div>
        <div className='text-center'>Thank you for using Lyrics2Learn (L2L)! </div>
        <div>We are excited to help support your students as they improve fluency and comprehension using music, video, and current research-based strategies to improve reading.</div>
        <div> Lyrics2Learn, LLC, (“L2L,” “we,” “our,” or “us”) provides a reading and learning platform on the lyrics2learn websites, the Lyrics2Learn mobile app (“App”), and any associated services including our homeschool and online tutoring programs (collectively, the “Service”). You accept these terms of use (“Terms”) by creating an account on this Service or by otherwise accessing or using the Service. </div>
        <div>We may make changes to these Terms from time to time. If we make changes, we will provide you with notice of such changes, such as by providing a notice through our Service or updating the date at the top of these Terms. Unless we say otherwise in our notice, the updated Terms will be effective immediately, and your continued use of our Service after we provide such notice will confirm your acceptance of the changes. If you do not agree to the updated Terms, you must stop using our Service. Please read these Terms carefully, including the binding arbitration clause and class action waiver in Section 13, which affects how disputes are resolved. If you do not agree to these Terms, do not use our Service.</div>
        <h1 className='text-2xl font-bold'>1. ELIGIBILITY TO USE THE SERVICE</h1>
        <div>L2L welcomes you to its platform. You may access the Service using your personal email address or an email address provided to you by your organization. Depending on the role you play when using the Service, you may be able to create, manage and share student, family, classroom, or school access by different types of Users. </div>
        <div>There are a number of different roles that you may play as you access the Service, and this may depend in part on whether you access the Service using your personal email address or an email address provided to you by your organization. Your use of the Service must be consistent with the role assigned to the account you use when accessing the Service. </div>
        <div>Teacher</div>
        <div> A “teacher” initiates and manages students and Topics and manages and sets permissions for all and Topics. Unless prohibited by applicable law, Teachers using the Service with an email address provided by their affiliated organization, such as an educational institution or organization or other educational business (a “School”), represent and warrant that they have the legal authority to bind their organization to these Terms. Notwithstanding the foregoing, Teachers acknowledge that they are always individually bound by these Terms. Teachers using the Service with an email address provided by their School agree that their use of the Service may be subject to additional terms between Lyrics2Learn and the relevant organization. Schools accept Lyrics2Learn’s Terms and Privacy Policy on behalf of their Students. They are also responsible for obtaining parental consent for any Members who are minors in their country. Specific responsibilities of Teachers are described in Section 4 of these Terms.</div>
        <div>Parent</div>
        <div> A “parent” initiates and manages students and Topics and manages and sets permissions for all and Topics their students can access. Unless prohibited by applicable law, Parents using the Service represent and warrant that they have the legal authority to bind themselves or others associated to the account to these Terms. Notwithstanding the foregoing, Parents acknowledge that they are always individually bound by these Terms. Parents using the Service agree that their use of the Service may be subject to additional terms between Lyrics2Learn and themselves. Parents  accept Lyrics2Learn’s Terms and Privacy Policy on behalf of their Students. They are also responsible for obtaining any guardian consent for any Members/students who are minors. Specific responsibilities of Parents  are described in Section 4 of these Terms.</div>
        <div>User </div>
        <div>As used in these Terms, “User” is any user of the Service, including parents, teachers, administrators, and all other Guests or persons who have created an account.</div>
        <div>If you are a User who is under 18 years old (or the age of legal majority where you live), you may use the Service only with the permission of a parent or legal guardian who agrees to be bound by these Terms. If you are a parent or legal guardian of a User under the age of 18 (or the age of legal majority where they live), then you agree to be bound by these Terms with respect to that User’s use of the Service. </div>
        <div>Further, individuals under the age of 13 or under the age where parental consent is required for use of the Service under applicable law (individually, a “Child” and collectively, “Children”) may not, under any circumstances or for any reason, use the Service, except as permitted below:</div>
        <div>Student Members of any age, including Student Members who are Children, may only use the Service at the invitation and under the supervision of a Parent or school member who has agreed to these Terms.</div>
        <div>Other Members who are Children may only use the Service after their parent or legal guardian provides consent to us in accordance with applicable law. For more information, please contact us.</div>
        <div>No User may use our Service if you have previously been removed or suspended from the Service.</div>
        <h1 className='text-2xl font-bold'>2. PRIVACY POLICY</h1>
        <div>We value the privacy of our Users. Please read our Privacy Policy below to learn more about what information we collect and how we use, share, and otherwise process that information.</div>
        <h1 className='text-2xl font-bold'>3. ACCOUNTS AND REGISTRATION</h1>
        <div>3.1. Account Creation and Deletion is completed only by parents, teachers or administrators. When you register for the Service, we will ask you to provide certain information about yourself. You agree not to provide any false, inaccurate, or misleading information when signing up for your account. You must keep this information up to date. You may delete or cancel your account at any time and for any reason bygoing to settings. If you have trouble deleting your account, you can also contact us for assistance.</div>
        <div>3.2. Account Responsibilities. To protect your account, keep your account details and password confidential, as you are responsible for activities that occur under your account. </div>
        <h1 className='text-2xl font-bold'>4. Teachers, Parents, Guardians, Administrators</h1>
        <div>4.1 Responsibilities for account holders overseeing students have additional responsibilities to their Students, who may be Children. If you are one of the above, you agree to the following:</div>
        <div>(a) You are fully responsible for the use of the Service by your Students.</div>
        <div>(b) You consent to Lyrics2Learn’s collection and use of personal information from your Students, as described in our Privacy Policy. </div>
        <div>(c) You represent and warrant that you have: (i) the authority to consent to our collection and use of personal information from your Students; (ii) provided privacy notices and obtained consents as required by your School’s policies and applicable law, including but not limited to the Children’s Online Privacy Protection Act (“COPPA”) and the Family Educational Rights and Privacy Act (“FERPA”); and (iii) filed evidence of such consents (including verifiable parental consents) with your School administrator before using the Service.</div>
        <div>(d) You are solely responsible for responding to requests from parents and Student Members to review, correct, take down, transfer or update Student Member personal information or Student Member User Content in accordance with applicable laws and your School’s policies. You are responsible for notifying Lyrics2Learn if a parent or legal guardian revokes parental consent required to process personal information about a Student.</div>
        <div>(e) You represent and warrant that you will use the Service and the personal information collected from Student Members in connection with the Service in accordance with applicable laws, our Privacy Policy, these Terms and your School’s policies.</div>
        <div>(f) You may not publicly post Groups or Topics containing User Content provided by or collected from your Student Members (“Student Member User Content”) without explicit parent approval. If you do so, you are solely responsible for obtaining any additional consents required to permit Student User Content and Student personal information to be shared with others.</div>
        <div>(g) You are responsible for any Student’s User Content and will respond to any questions from Students or their parents about possession, control, or transfer of Student Member User Content.</div>
        <h1 className='text-2xl font-bold'>5. YOUR CONTENT</h1>
        <div>5.1 User Content. The Service allows Users to create, view, grade and store content, including but not limited to Topics, text, videos, and other material (collectively, “User Content”). You may not create, post, store or share any User Content that violates these Terms. You retain all rights in and to your User Content. You are solely responsible for any User Content you create, post, share or store through the Service. We use best practices to back up User Content, but we cannot guarantee that User Content will be available in the event it is intentionally or accidentally deleted by another User, so we recommend you backup your Content regularly. </div>
        <div>5.2 License. To the extent necessary to provide the Service to you and others and to protect you and the Service, you grant to us a worldwide and royalty-free intellectual property license to use, view, download, reproduce, modify, create derivative works of, make copies of, retain, transmit, reformat, display, and distribute via communication tools your User Content on the Service. You represent and warrant that for the duration of these Terms, you have (and will have) all the rights necessary for your User Content that is uploaded, stored, or shared on or through the Service and that the collection, use, and retention of your User Content will not violate any law or rights of others. With respect to User Content, the above licenses terminate within a commercially reasonable time after your videos are removed or deleted from the Service.</div>
        <div>5.3 Confidentiality. As Users are able to share User Content outside the Group or Topic to which it was submitted, Lyrics2Learn does not guarantee confidentiality with respect to your User Content. You are in control of your content.</div>
        <div>5.4 Code of Conduct. </div>
        <div>By using the Service, you are agreeing to follow these rules:</div>
        <div>(a) Don’t do anything illegal. Don’t help others break these rules.</div>
        <div>(b) Don’t engage in any activity that exploits, harms, or threatens to harm other individuals, including but not limited to Children. </div>
        <div>(c) Don’t send spam. Spam is unwanted or unsolicited bulk email, postings, contact requests, SMS (text messages), or instant messages. </div>
        <div>(d) Don’t publicly display or use the Service to share content or material that is inappropriate for the Member audience with whom it will be shared - certain content and material is inappropriate for all Users (involving, for example, pornography, offensive language, graphic violence, or criminal activity). </div>
        <div>(e) Don’t engage in activity that is fraudulent, false or misleading (e.g., impersonating someone else, manipulating the Service to increase play count or affecting comments). </div>
        <div>(f) Don’t circumvent any restrictions on access to or availability of the Service (e.g., giving false information in order to be able to use the Service, probe, scan, or test the vulnerability of our Service, systems, or network).  </div>
        <div>(g) Don’t engage in activity that is harmful to you, the Service, or others (e.g., transmitting viruses, bullying, stalking, posting terrorist content, communicating hate speech, or advocating violence against others). </div>
        <div>(h) Don’t infringe upon the rights of others (e.g., unauthorized sharing or use of videos, copyrighted music or other copyrighted material, comments, and/or media, violating the intellectual property rights or privacy of others or collecting or harvesting any information that might identify a person). </div>
        <div>(i) Don’t share any login information associated with any account you use to access our Service. Accordingly, you are responsible for all activities that occur under your account. </div>
        <div>(j) Don’t use student information for marketing or advertising purposes. </div>
        <div>(k) Don’t invite Children to use the Service unless you are the parent of the student/s or School member inviting a Child as a Student Member. </div>
        <div>(l) Don’t use any robot, spider, crawler, scraper, or other automated means or interface to access the Service or extract any other User’s information within Lyrics2Learn. </div>
        <div>5.6 Enforcement. We may (but have no obligation to) review any User Content. Notwithstanding the fact that we have no obligation to monitor the User Content on the Service, we reserve the right in our sole discretion to, at any time for any reason (a) block, delete, limit, restrict access to, remove, or modify your information, including your User Content; and (b) suspend or terminate your account, and refuse any current or future use of the Service (or any portion thereof). We will not be liable to you or any third party for taking either of these actions. We may also investigate and/or take appropriate action against you for violating these Terms or for otherwise creating liability for us or any other person, such as by reporting you to law enforcement authorities.</div>
        <div>5.7 Deletion of User Content. You may delete your User Content on the Service by following the instructions provided on the Service or in our dashboard. School members (or parents, if applicable) can also correct, edit, or update their Member information and User Content. Members (and their parents, if applicable) can also contact us to request deletion of your personal information. </div>
        <h1 className='text-2xl font-bold'>6. Analytics</h1>
        <div>We use cookies and similar technologies to collect information about your use of the Service in order to analyze and track data and determine the popularity of certain content on our Service. Cookies are small data files stored on your hard drive or in device memory that help us provide and improve the Service and your experience, collect data about the Service and how it is used, and to share information about L2L and its features and functionalities to Leads. For example, cookies allow you to log in to secure areas of the Service and help the content of the pages load quickly. We use cookies to provide you with a more personal, useful, and interactive experience with our Service. We also use web beacons (also known as “pixel tags” or “clear GIFs”) to help deliver cookies and gather usage and performance data.  
In addition to the cookies we set, we allow other third-parties to provide analytics services on our behalf. These third-parties can set cookies when you use the Service to collect information about you. For example:
Companies we hire to provide services that help us offer our Service, such as site analytics providers, may place cookies when you visit our sites.
Companies that help us manage emails, user metrics and behavioral tracking.
Each User has a variety of tools to control cookies, web beacons, and similar technologies, including browser controls to block and delete cookies and controls from some third-party analytics service providers to opt out of data collection through web beacons and similar technologies. User browser settings and other choices may impact the functionality of the Service. 
7. Information Choices and Changes

7.1 Personal Information and User Content  
Users may opt out of providing personal information to the Service at any time by ceasing to use the application. Users may also request deletion of their personal information, including content, by contacting us, or, in some cases, contacting the teacher or school user. We may ask you for certain information about yourself or about your content to validate your request. If we cannot validate your request, we may not be able to remove the content. 
If you request deletion of your account or content, please note that we may still retain certain information as required by law or for our legitimate business purposes.
7.2 Communications Preferences
 We may send Users  emails regarding events, news, and other topics. Users can opt out of receiving such emails by following the instructions provided in those emails. Note that if a User  opts out of such emails, we may still send them operational emails that pertain to the their account such as privacy updates and notifications regarding our Service.
8. Children's Privacy
This section of the Privacy Policy applies to information we collect from Users under the age of 13 or the age where Parental consent is required for use of the Service under applicable law (individually, a “Child,” and collectively, “Children”). With consent from a parent or legal guardian (a “Parent”) obtained by their School or Teacher, Children can use the Service as Student Users.
If we learn that we have collected a Child’s personal information without legally required Parental consent, we will take steps to delete the information as soon as possible. Parents who believe that their Child has submitted personal information to us and would like to have it deleted may contact us.
 8.1 Collection, Use and Disclosure of Children’s Information 
Under the United States Children’s Online Privacy Protection Act (“COPPA”), verifiable Parental consent is needed to collect, use, or disclose personal information (e.g., full name and email address) from each User under 13 years old. 
 a) Information Collected from Children. We obtain verifiable Parental consent before collecting, using, or disclosing information from a Child, including as described in Section 8.2 for Student Users. We collect the same types of personal information from Children as we do from other Members, as described in Section 1 above. We will not knowingly require any Child to disclose more information than is reasonably needed to participate in the Service. Often, Teachers and Parents can excluded personal;by identifiable information by disabling the recording feature and using usernames and passwords that do not include names.
b) Use and Sharing of Children’s Information. Once we obtain verifiable Parental consent, we may use and share personal information collected from Children for the same purposes applicable to other Members, except as otherwise described in Sections 2 through 6 above. As with other Users, we do not sell Children’s personal information or use Children’s personal information to market or advertise to Children, nor do we permit third-parties to do so.  
c) As described in Section 4.1, content Children post to Parents and Teachers may be visible to other Users of the Service such as school Administators.  We encourage Parents to monitor their Children’s use of the Service, including the content and personal information they submit. L2L  is not able to control or monitor all personal information or content Children may submit to the Service, nor what Users or recipients of a Content may ultimately do with any information or content they have access to from the Service. 
d) Parental Rights. Parents have certain rights with respect to our collection, use, retention, and disclosure of their Child’s information. This includes the right to review, update, or delete personal information about their Child, as well as to prohibit our sharing of personal information about their Child with certain third-parties. Parents can exercise these rights by contacting their Child’s School Lead or by sending requests directly to us. Please note that if a Parent requests deletion of his/her Child’s personal information or content, we may still retain certain information as required by law or for our legitimate business purposes as outlined in Section 10. 
8.2 Notice to Schools and Student Members 
In accordance with COPPA, a teacher, tutor, or school admin may act as the agent of the Parent of a student and provide consent on behalf of the Parent for L2L’s collection, use, and disclosure of the student’s personal information. As such, by enrolling a Student Member under 13 to use the Service, the School, Parent, Teacher or Administrator  provides such consent to us and agrees that the Student’s activities while on the Service are subject to our Terms, including but not limited to our Code of Conduct.
This Privacy Policy does not govern any School’s collection, use, or disclosure of a Student ’s personal information through the Service, and we are not responsible for any such collection, use, or disclosure. 
9. Information Security
We use security technologies and procedures to help protect your personal information. While we take efforts to protect your personal information, please remember that no transmission of information on the Internet is 100% secure, and we cannot guarantee or be responsible for absolute security of your information. 
Privacy of Educator & Student Personal Data
Lyrics2Learn is an educational offering and any personal data that is collected from or generated by L2L is used only to provide that educational offering:
We abide by the Student Privacy Pledge shown below, which pledges us to “not collect, maintain, use or share student personal information beyond that needed for authorized educational/school purposes.”
Educators, Administrators and Parents control the content in their dashboard and are able to edit/delete, moderaste and score student content. All data will be saved on L2L until deleted by the User. Any deletion is immediate and permanent.
Lyrics2Learn can be used by students of all ages.
We do not sell or share student data with any marketers.
Data collected by L2L is only processed to provide, improve & develop the service, provide customer & technical service, and comply with applicable laws.
Parent, student, eligible student, teacher or principal should contact the L2L educator directly to understand the educator's privacy practices or to request to access, change or delete information collected by the educator when neccessary.
Security Best Practices
Lyrics2Learn has robust data security, follows industry best practices, and is routinely reviewed for issues and improvements. Our practices include but are not limited to the following:
Data is stored in a secure data center facility located in the United States.
Security practices, processes, and controls include, but are not limited to, encryption of data in transit, encryption of data at rest, manual & automated monitoring & auditing systems, and access control. Data at rest is encrypted using AES-256 and data in transit is encrypted using TLS protocol standards.
Implementation of least privilege data access by limiting employee access to whatever extent is required for them to perform their job functions. L2L follows employee access best practices including, but not limited to, employee training & education, signed confidentiality agreements, criminal background checks, and secured access control such as network & application level firewalling & two-factor authentication.
Upon becoming aware of breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Student Data or other personal information, promptly and without undue delay Lyrics2Learn will (1) notify affected individuals of the Security Incident; (2) investigate the Security Incident and provide affected individuals with detailed information about the Security Incident; (3) take reasonable steps to mitigate the effects and to minimize any damage resulting from the Security Incident. 
10. Data Retention
We store the information we collect for as long as you have an account (if you are a Teacher, parent or Administrator), or for up to 30 days in our archives when you ask for data to be deleted. Additionally, we may retain User information to resolve disputes, enforce our policies, and to comply with the law. 
14. Contact Information
If you have any questions about this Privacy Policy or wish to contact us, please contact us at admin@lyrics2learn.com</div>

    </div>
  )
}
