import React from "react";
import { ArrowTrendingUpIcon } from "@heroicons/react/20/solid";
import greg from "../assets/greg.png";
import ryder from "../assets/ryder.jpg";
import ashon from "../assets/ashton.png";

export default function FunForStudents() {
  return (
    <div className="bg-white">
      <div className="bg-transparent px-6 sm:py-20 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-5xl font-gbold tracking-tight text-teal-500 ">
            Fun for students - Easy for parents
          </h2>
          <p className="mt-6 text-xl leading-8 text-gray-500 mx-auto max-w-2xl">
            Improves engagement, fluency, comprehension, and vocabulary in just
            15 minutes a day
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-4xl">
      <section className="isolate overflow-hidden bg-white px-6 lg:px-8 ">
        <div className="relative mx-auto max-w-2xl lg:max-w-4xl">
          <div className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.indigo.100),white)] opacity-20 lg:left-36" />
          <div className="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-500/10 ring-1 ring-indigo-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center" />
          <figure className="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
            <div className="relative col-span-2 lg:col-start-1 lg:row-start-2">
              <ArrowTrendingUpIcon className="absolute -top-12 left-0 -z-10 h-32 text-gray-900/10" />
              <div className="font-gbold text-indigo-500 text-2xl">
                Greg - 2.5 year gain
              </div>
              <blockquote className="text-xl  leading-8 text-gray-900 sm:text-2xl sm:leading-9 mt-2">
                <p>
                  Phonemic Awareness: <span className="text-teal-500 font-gbold">85% to 95% </span>
                </p>
                <p>
                  Phonics: <span className="text-teal-500 font-gbold">42% to 87%</span> 
                </p>
                <p>
                  Fluency: <span className="text-teal-500 font-gbold">50% to 92%</span> accuracy 
                </p>
                <p>
                  Comprehension: <span className="text-teal-500 font-gbold">Kinder up to 3rd grade</span>
                </p>
              </blockquote>
            </div>
            <div className="col-end-1 w-16 lg:row-span-4 lg:w-72">
              <img
                className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                src={greg}
                alt=""
              />
            </div>
          </figure>
        </div>
      </section>
      <section className="isolate overflow-hidden bg-white px-6 lg:px-8 mt-8">
        <div className="relative mx-auto max-w-2xl lg:max-w-4xl">
          <div className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.indigo.100),white)] opacity-20 lg:left-36" />
          <div className="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-500/10 ring-1 ring-indigo-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center" />
          <figure className="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
            <div className="relative col-span-2 lg:col-start-1 lg:row-start-2">
              <ArrowTrendingUpIcon className="absolute -top-12 left-0 -z-10 h-32 text-gray-900/10" />
              <div className="font-gbold text-indigo-500 text-2xl">
              Ryder: 3 year gain
              </div>
              <blockquote className="text-xl  leading-8 text-gray-900 sm:text-2xl sm:leading-9 mt-2">
                <p>
                  Phonemic Awareness: <span className="text-teal-500 font-gbold">85% to 100% </span>
                </p>
                <p>
                  Phonics: <span className="text-teal-500 font-gbold">78% to 92%</span> 
                </p>
                <p>
                  Fluency: <span className="text-teal-500 font-gbold">65% to 96%</span> accuracy 
                </p>
                <p>
                  Comprehension: <span className="text-teal-500 font-gbold">2nd up to 5th grade</span>
                </p>
              </blockquote>
            </div>
            <div className="col-end-1 w-16 lg:row-span-4 lg:w-72">
              <img
                className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                src={ryder}
                alt=""
              />
            </div>
          </figure>
        </div>
      </section>
      <section className="isolate overflow-hidden bg-white px-6 lg:px-8 mt-8">
        <div className="relative mx-auto max-w-2xl lg:max-w-4xl">
          <div className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.indigo.100),white)] opacity-20 lg:left-36" />
          <div className="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-500/10 ring-1 ring-indigo-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center" />
          <figure className="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
            <div className="relative col-span-2 lg:col-start-1 lg:row-start-2">
              <ArrowTrendingUpIcon className="absolute -top-12 left-0 -z-10 h-32 text-gray-900/10" />
              <div className="font-gbold text-indigo-500 text-2xl">
              Ashon: 2.0 year gain
              </div>
              <blockquote className="text-xl  leading-8 text-gray-900 sm:text-2xl sm:leading-9 mt-2">
                <p>
                  Phonemic Awareness: <span className="text-teal-500 font-gbold">85% to 98%</span>
                </p>
                <p>
                  Phonics: <span className="text-teal-500 font-gbold">71% to 90%</span> 
                </p>
                <p>
                  Fluency: <span className="text-teal-500 font-gbold">50% to 92%</span> accuracy 
                </p>
                <p>
                  Comprehension: <span className="text-teal-500 font-gbold">Late 1st up to late 3rd</span>
                </p>
              </blockquote>
            </div>
            <div className="col-end-1 w-16 lg:row-span-4 lg:w-72">
              <img
                className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                src={ashon}
                alt=""
              />
            </div>
          </figure>
        </div>
      </section>
      <div className="text-center text-teal-500 font-gbold text-3xl mt-24 pb-20">
      Over 3,000,000 million lessons completed in classrooms and homes around the globe.    
      </div>
      </div>
    </div>
  );
}
