import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import blueButton from "../../assets/blueButton.png";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import { colorOptions } from "../../assets/backgrounds/colors";
import { setStudentOptions } from "../../helpers/firebaseFunctions";

export default function PickBackground() {
  const { bg, indx } = useParams();
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const [ student, setStudent ] = useState(null);



  const { studentProfile, studentLogout, studentUser } = UserAuth();

  const settings = {
    imageColor: bg,
    imageIndex: indx,
    color: selected,
  };

  useEffect(() => {
    if (studentProfile) {
      setStudent(studentProfile);
    }
  }
  , [studentProfile]);



  const handleCreate = async () => {
    await setStudentOptions(student.studentId, settings).then(() => {
      navigate("/student/dashboard");
    });
  };

  return (
    <div className="max-w-7xl mx-auto mt-8 p-4">
      <div className="font-londrina text-4xl text-zinc-700 mb-8 text-center">
        Pick your background
      </div>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {colorOptions.map((colors, index) => (
          <li key={index} className="relative">
            <div className={`group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gradient-to-r ${colors?.leftColor} ${colors?.rightColor} focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:-translate-y-1`}>
           
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
                onClick={() => setSelected(index)}
              >
              </button>
            </div>
       
          </li>
        ))}
      </ul>
      <div className="relative mt-12 left-1">
        <div className="text-center">
          <button
            className="mx-auto hover:opacity-75 relative w-80"
            onClick={handleCreate}
          >
            <img src={blueButton} alt="" />

            <span className="absolute top-4 left-16 right-0 text-center font-londrina text-7xl text-zinc-700 flex items-center">
              Next{" "}
              <ArrowRightCircleIcon className="ml-2 text-center text-zinc-700 h-20" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
