import React, { useState } from "react";
import ParentLogin from "../components/ParentLogin";
import StudentLogin from "../components/StudentLogin";

export default function Login() {
  const [parrentLogin, setParentLogin] = useState(true);
  const [studentLogin, setStudentLogin] = useState(false);
  return (
    <>
      <div className="max-w-4xl mx-auto mt-12 px-4">
        <div className="grid grid-cols-2 gap-3">
          <button
            className={`relative gap-x-1.5 rounded-md bg-red-400/90 md:px-14 px-6 py-6 text-md font-semibold hover:ring-1 hover:ring-offset-violet-400 hover:ring-violet-400 text-white shadow-sm hover:bg-violet-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 ${parrentLogin ? "bg-violet-500" : ""}`}
            onClick={() => {setParentLogin(!parrentLogin); setStudentLogin(false)}}
          >
            Parent Login
          </button>
          <button className={`relative gap-x-1.5 rounded-md bg-red-400/90 md:px-14 px-6 py-6 text-md font-semibold hover:ring-1 hover:ring-offset-violet-400 hover:ring-violet-400 text-white shadow-sm hover:bg-violet-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600 ${studentLogin ? "bg-violet-500" : ""}`}
          onClick={() => {setStudentLogin(!studentLogin); setParentLogin(false)}}
          >
            Student Login
          </button>
        </div>
        {parrentLogin && <div><ParentLogin /></div>}
        {studentLogin && <div><StudentLogin /></div>}
      </div>
      
    </>
  );
}
