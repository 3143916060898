import { createContext, useContext, useEffect, useState } from "react";
import { db, auth, storage } from "../config/firebase";
import {
  setDoc,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const UserContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tempParentData, setTempParentData] = useState({});
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [tracker, setTracker] = useState([]); 
  const [students, setStudents] = useState([]);
  const [studentUser, setStudentUser] = useState({});
  const [studentProfile, setStudent] = useState("");
  const [currentLesson, setCurrentLesson] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [current, setCurrent] = useState(0);
  const [currentVideo, setCurrentVideo] = useState("");
  const [allLessons, setAllLessons] = useState([]);
  const [authRole, setAuthRole] = useState([]);
  const [myStudents, setMyStudents] = useState([]);
  const [time, setTime] = useState(0);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    hasAccount: false,
  });
  const [isParent, setIsParent] = useState(false);
  const [curentNumber, setCurrentNumber] = useState(0);

  const navigate = useNavigate();

  const studentLogout = () => {
    setStudentUser({});
    window.localStorage.setItem("auth", "false");
    window.localStorage.setItem("student", {});
  };

  const logout = async () => {
    setIsLoggedIn(false);
    await signOut(auth);
    window.location.reload();
  };



  const submitQuiz = async (quizData, lines) => {
    try {
      await updateDoc(
        doc(db, "lessons", quizData.grade, quizData.day, quizData.lessonNumber),
        {
          title: quizData.title,
          grade: quizData.grade,
          lyrics: quizData.lyrics,
          lyricLines: lines,
          quiz: quizData.questions,
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const submitLessons = async (lessonData) => {
    for (const lesson of lessonData) {
      await updateDoc(doc(db, "lessonOrder", "lessons"), {
        [lesson.id]: lesson,
      });
    }
  };

  const updateLessonSubmit = async (lessonData) => {
    const q = doc(db, "lessonOrder", "lessons");
    const docSnap = await getDoc(q);
    const data = docSnap.data();
    data[lessonData.id][lessonData.day] = true;
    await updateDoc(q, data);
  };

  const getAllLessons = () => {
    onSnapshot(doc(db, "lessonOrder", "lessons"), (doc) => {
      console.log(doc.data());
      setAllLessons(doc.data());
    });
  };

  const getLessons = (grade, day, callback) => {
    const q = query(collection(db, "lessons", grade, day));
    return onSnapshot(q, (querySnapshot) => {
      const lessons = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(lessons);
    });
  };

  const createBenchmark = async (benchmarkData) => {
    const id = `${benchmarkData.gradeLevel}-${benchmarkData.orderNumber}`;
    try {
      await setDoc(doc(db, "benchmarks", id), benchmarkData);
    } catch (err) {
      console.log(err);
    }
  };

  const getBenchmarks = async (gradeLevel) => {
    const q = query(
      collection(db, "benchmarks"),
      where("gradeLevel", "==", gradeLevel)
    );
    const querySnapshot = await getDocs(q);
    const benchmarks = [];
    querySnapshot.forEach((doc) => {
      benchmarks.push(doc.data());
    });
    return benchmarks;
  };

  const getAllBenchmarks = async () => {
    const q = query(collection(db, "benchmarks"));
    const querySnapshot = await getDocs(q);
    const benchmarks = [];
    querySnapshot.forEach((doc) => {
      benchmarks.push(doc.data());
    });
    return benchmarks;
  };

  const updateUser = async ({ userData }) => {
    try {
      await updateDoc(doc(db, "users", user.uid), userData);
    } catch (err) {
      console.log(err.message);
      alert(`There was an error: ${err}`);
    }
  };

  const updateStudent = async (studentData) => {
    try {
      await updateDoc(doc(db, "newStudents", studentData._id), studentData);
      await updateDoc(doc(db, "users", user.uid), { hasStudent: true });
    } catch (err) {
      console.log(err.message);
      alert(`There was an error: ${err}`);
    }
  };

  // const getStudent = (id) => {
  //   const q = query(collection(db, "newStudents"), where("parentID", "==", id));
  //   const unsuscribe = onSnapshot(q, (querySnapshot) => {
  //     const relatedStudents = [];
  //     querySnapshot.forEach((doc) => {
  //       relatedStudents.push(doc.data());
  //     });
  //     setStudents(relatedStudents);
  //     return unsuscribe();
  //   });
  // };

  const getStudentReports = (id) => {
    const q = query(
      collection(db, "lessonTracker"),
      where("parentId", "==", id)
    );
    const unsuscribe = onSnapshot(q, (querySnapshot) => {
      const relatedStudents = [];
      querySnapshot.forEach((doc) => {
        relatedStudents.push(doc.data());
      });
      setTracker(relatedStudents);
      return unsuscribe();
    });
  };

  const startLesson = async (lesson) => {
    const lessonReady = await getDoc(doc(db, lesson));
    if (lessonReady.exists()) {
      console.log("Document data:", lessonReady.data());
      setCurrentLesson(lessonReady.data());
      const videoUrl = await getDownloadURL(
        ref(storage, lessonReady.data().lessonVideo)
      );
      setCurrentVideo(videoUrl);
      navigate("/student/dashboard/student-lesson/" + lesson);
      setIsLoading(false);
    } else {
      console.log("No such document");
    }
  };

  const startVideo = async (lesson) => {
    const lessonReady = await getDoc(doc(db, lesson));
    if (lessonReady.exists()) {
      console.log("Document data:", lessonReady.data());
      setCurrentLesson(lessonReady.data());
      const videoUrl = await getDownloadURL(
        ref(storage, lessonReady.data().lessonVideo)
      );
      setCurrentVideo(videoUrl);
      navigate("/student/dashboard/practice-time/" + lesson);
      setIsLoading(false);
    } else {
      console.log("No such document");
    }
  };





  const coldReadRotRead = async (lesson) => {
    const lessonReady = await getDoc(doc(db, lesson));
    if (lessonReady.exists()) {
      console.log("Document data:", lessonReady.data());
      setCurrentLesson(lessonReady.data());
    } else {
      console.log("No such document");
    }
  };

  const scoreLesson = async (lessonData) => {
    try {
      await updateDoc(doc(db, "students", lessonData.uid), {
        [lessonData.scoredID]: lessonData.score,
        [lessonData.newScoreID]: lessonData.newScore,
        currentLesson: lessonData.currentLesson,
        books: arrayUnion(lessonData.book),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getMyStudents = (id) => {
    try {
      const q = query(collection(db, "students"), where("parentId", "==", id));
      const unsuscribe = onSnapshot(q, (querySnapshot) => {
        const relatedStudents = [];
        querySnapshot.forEach((doc) => {
          relatedStudents.push(doc.data());
        });
        setMyStudents(relatedStudents);
        return unsuscribe();
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setIsLoggedIn(true);
        onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
          setProfile(doc.data());
          setAuthRole(true);
          if (doc.data()?.role && doc.data().role === "parent") {
            setIsParent(true);
            getMyStudents(currentUser.uid);
            getStudentReports(currentUser.uid);
          } else {
            setIsParent(false);
          }
        });
        onSnapshot(doc(db, "students", currentUser.uid), (doc) => {
          setStudent(doc.data());
        });
        console.log("It ran again");
      } else {
        setIsLoggedIn(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        logout,
        profile,
        myStudents,
        updateUser,
        isLoggedIn,
        students,
        updateStudent,
        studentLogout,
        studentUser,
        studentProfile,
        startLesson,
        startVideo,
        currentLesson,
        currentVideo,
        getLessons,
        submitQuiz,
        submitLessons,
        allLessons,
        updateLessonSubmit,
        authRole,
        setNewUser,
        newUser,
        isParent,
        curentNumber,
        setCurrentNumber,
        createBenchmark,
        getAllBenchmarks,
        coldReadRotRead,
        time,
        endTime,
        startTime,
        setEndTime,
        setStartTime,
        setTime,
        setCurrentLesson,
        isLoading,
        setIsLoading,
        tempParentData,
        current,
        setCurrent,
        setTempParentData,
        setUser,
        setStudentUser,
        setIsLoggedIn,
        setIsParent,
        tracker
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => {
  return useContext(UserContext);
};
