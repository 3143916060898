import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import Progress from "./Progress";

export default function MoreInfo() {
  const [zip, setZip] = useState("");
  const [studentName, setChildsName] = useState(null);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [studentBirthYear, setBirthYear] = useState(null);
  const [agreeCoppa, setAgreeCoppa] = useState(false);

  const { newUser, setNewUser, profile } = UserAuth("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setNewUser({
      ...newUser,
      zip: zip,
      studentName: studentName,
      studentBirthYear: studentBirthYear,
      agreeCoppa: agreeCoppa,
      gradeLevel: gradeLevel,
      email: profile?.email,
      name: profile?.name,
      started: true,
    });
    navigate("/assign-username");
  };
  console.log(newUser);

  return (
    <div className="overflow-hidden py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
      <div className="relative mx-auto max-w-lg">
        <h1 className="text-center text-3xl font-bold mt-12">
          Let's setup your Student's Account
        </h1>
        <p className="text-center text-md mb-8 mt-2">
          Your child is on the way to becoming a better reader! Before we can
          get them started we just need to add some information
        </p>
        <form className="mx-auto max-w-xs grid grid-cols-1 gap-y-3">
         
          <div className="relative rounded-md border bg-white  border-gray-300 px-3 py-2 shadow-sm focus-within:border-yellow-400 focus-within:ring-1 focus-within:ring-yellow-400">
            <div className="mt-1">
              <input
                type="text"
                name="childs-name"
                id="childs-name"
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-400 focus:ring-0 sm:text-md font-bold"
                placeholder="Student's First Name"
                onChange={(e) => {
                  setChildsName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="relative rounded-md bg-white shadow-sm  focus-within:ring-1 focus-within:ring-yellow-400 border border-gray-300">
            <div>
              <select
                id="location"
                name="location"
                className="block w-full border-0 focus:ring-0 rounded-md  px-3 py-2 text-gray-900 sm:text-md font-bold "
                defaultValue="Student's Birth Year"
                onChange={(e) => {
                  setBirthYear(e.target.value);
                }}
              >
                <option>2022</option>
                <option>2021</option>
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
                <option>2017</option>
                <option>2016</option>
                <option>2015</option>
                <option>2014</option>
                <option>2015</option>
                <option>2014</option>
                <option>2013</option>
                <option>2012</option>
                <option>2011</option>
                <option>2010</option>
                <option>2009</option>
                <option>2008</option>
                <option>2007</option>
                <option>2006</option>
                <option>2005</option>
                <option>2004</option>
                <option disabled>Student's Birth Year</option>
              </select>
            </div>
          </div>
          <div className="relative rounded-md bg-white shadow-sm  focus-within:ring-1 focus-within:ring-yellow-400 border border-gray-300">
            <div>
              <select
                id="location"
                name="location"
                className="block w-full border-0 focus:ring-0 rounded-md  px-3 py-2 text-gray-900 sm:text-md font-bold "
                defaultValue="Student's Grade Level"
                onChange={(e) => setGradeLevel(e.target.value)}
              >
                <option value="k">Kindergarten</option>
                <option value="1">1st Grade</option>
                <option value="2">2nd Grade</option>
                <option value="3">3rd Grade</option>
                <option value="4">4th Grade</option>
                <option value="5">5th Grade</option>

                <option disabled>Student's Grade Level</option>
              </select>
            </div>
          </div>
          <a href="/" className="text-center mt-8 font-bold text-yellow-400">
            View COPPA Consent Notice
          </a>
          <div className="relative flex">
            <div className="flex h-5">
              <input
                id="comments"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-yellow-400 focus:ring-yellow-300"
                onChange={() => {
                  setAgreeCoppa(!agreeCoppa);
                }}
              />
            </div>
            <div className="ml-3 text-sm">
              <p className="text-gray-500">
                I {profile?.name}, as the Parent or Guardian of {studentName},
                grant my COPPA consent
              </p>
            </div>
          </div>
          <button
            type="button"
            className="mt-8 items-center text-center rounded-md border border-transparent bg-yellow-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2"
            onClick={handleSubmit}
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
}
