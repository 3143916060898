import React, { useEffect, useState } from "react";
import files from "../../assets/Images";
import { UserAuth } from "../../context/AuthContext";
import IconDropDown from "../components/IconDropDown";
import { updateStudent } from "../../helpers/firebaseFunctions";
import { Link } from "react-router-dom";

export default function Student() {
  const { setCurrentNumber, myStudents, profile } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [studentIndex, setStudentIndex] = useState(0);
  const [icon, setIcon] = useState(myStudents[studentIndex]?.imageIndex);
  const [name, setName] = useState(myStudents[studentIndex]?.studentName);
  const [userName, setUserName] = useState(myStudents[studentIndex]?.userName);
  const [gradeLevel, setGradeLevel] = useState("");
  const [imageIndex, setImageIndex] = useState();
  const [isInputFocused, setInputFocused] = useState(false);
  const id = myStudents[studentIndex]?.studentId;

  // e default the new icon
  useEffect(() => {
    setGradeLevel(myStudents[studentIndex]?.gradeLevel);
    setImageIndex(myStudents[studentIndex]?.imageIndex);
    setIcon(myStudents[studentIndex]?.imageIndex);
  }, [studentIndex, myStudents]);

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };  

  const handleGradeLevelChange = (e) => {
    setGradeLevel(e.target.value);
    // You might want to update the student's grade level here as well
  };

  const studentData = {
    imageIndex: icon,
    userName: userName,
    gradeLevel: gradeLevel,
    loginID: myStudents[studentIndex]?.loginID,
  };

  const studentTemp = myStudents[studentIndex]?.oldStudentId;
  const userID = profile?.id

  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    updateStudent(id, studentTemp, studentData, userID);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-emerald-500"></div>
        </div>
      ) : (
        <main>
          <div className="mx-auto max-w-full py-6 sm:px-6 lg:px-8 h-screen">
            <div className="grid grid-cols-8 gap-2 max-w-5xl mx-auto">
              <div className="col-span-2 w-full">
                <select
                  id="student"
                  name="student"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                  defaultValue={myStudents[0]?.firstName}
                  onChange={(e) => {
                    setStudentIndex(e.target.value);
                  }}
                >
                  {myStudents?.map((student, index) => (
                    <option key={index} value={index}>
                      {student.firstName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-4"></div>

              <div className="col-span-2 w-full">
                <button
                  type="button"
                  className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 w-full mt-2 h-12"
                  onClick={() => setCurrentNumber(4)}
                >
                  Add another Student
                </button>
              </div>
            </div>
            <div className="px-4 py-4 sm:px-0">
              <div className="rounded-lg shadow-lg bg-gray-50 max-w-5xl mx-auto">
                <h1 className="text-2xl text-center font-bold pt-8">
                  Edit your Student's Account
                </h1>
                <h3 className="text-center text-lg">
                  Here you can change, update, or delete anything related to your student
                </h3>
                <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 md:p-16 p-4 m-4 md:m-0">
                  <div className="relative rounded-md border border-gray-300 px-4 py-2 shadow-sm focus-within:border-emerald-500 focus-within:ring-1 focus-within:ring-emerald-500 col-span-2 sm:col-span-1 bg-white">
                    <label
                      htmlFor="name"
                      className={`absolute -top-4 left-2 -mt-px inline-block bg-gray-50 px-1 text-lg font-medium ${
                        isInputFocused ? "text-gray-400" : "text-gray-900"
                      }`}
                    >
                      Student Username
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="block w-full border-0 p-0 text-gray-900 focus:ring-0 sm:text-lg mt-1 h-12"
                      placeholder={
                        isInputFocused
                          ? ""
                          : myStudents[studentIndex]
                          ? myStudents[studentIndex].userName
                          : "Enter Student"
                      }
                      onChange={(e) => setUserName(e.target.value)}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="relative rounded-md border border-gray-300 shadow-sm focus-within:border-emerald-500 focus-within:ring-1 focus-within:ring-emerald-500 sm:col-span-1 col-span-2 bg-white">
                    <div className="mt-1">
                      <label
                        htmlFor="name"
                        className={`absolute -top-4 left-2 -mt-px inline-block bg-gray-50 px-1 text-lg font-medium ${
                          isInputFocused ? "text-gray-400" : "text-gray-900"
                        }`}
                      >
                        Grade Level
                      </label>
                      <select
                        id="location"
                        name="location"
                        className="block w-full border-0 px-4 py-5 text-gray-900 focus:ring-0 sm:text-md rounded-md"
                        value={gradeLevel}
                        onChange={handleGradeLevelChange}
                      >
                        <option value="k">Kindergarten</option>
                        <option value="1">1st Grade</option>
                        <option value="2">2nd Grade</option>
                        <option value="3">3rd Grade</option>
                        <option value="4">4th Grade</option>
                        <option value="5">5th Grade</option>

                        <option disabled>Current Grade Level</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <p className="text-lg font-semibold col-span-2 sm:mt-8 text-center">
                      The current{" "}
                      <span className="text-emerald-500 ">Login Image</span> for
                      your Student
                    </p>
                    <p className="col-span-2 text-center">
                      Students do not use passwords, we use images to ensure the
                      privacy and safety of your student
                    </p>

                    <div className="flex items-center max-w-sm mx-auto">
                      <img
                        src={files[icon]?.source}
                        alt=""
                        className="h-36 w-36 bg-gray-100 mx-auto mt-5"
                      />
                      <IconDropDown icon={icon} setIcon={setIcon} />
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6 col-span-2 mx-auto">
                    <button
                      type="button"
                      className=" rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:text-sm"
                      onClick={handleUpdate}
                    >
                      Update Student
                    </button>
                  </div>
                </form>
                <Link
                to={`/print/${icon}/${userName}/${myStudents[studentIndex].firstName}`} 
                className="flex items-center justify-center rounded-b-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 w-full mt-2 h-12">Print Student Login Card</Link>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
}
