import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import Progress from "./Progress";
import { checkForExistingUserName } from "../../config/lessonFunctions";

export default function AssignUserName() {
  const [userName, setUserName] = useState(null);

  const { newUser, setNewUser } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (newUser.started) {
      return;
    } else {
      navigate("/free-trial");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const doesUserExist = await checkForExistingUserName(userName);
    console.log(doesUserExist, "doesUserExist")

    if (doesUserExist) {
      alert("Username already exists");
      return;
    } else if (userName) {
      setNewUser({
        ...newUser,
        userName: userName,
      });
      navigate("/token");
    } else {
      alert("Please fill out all fields");
    }
  };

  console.log(newUser);

  return (
    <div className="overflow-hidden py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
      <div className="relative mx-auto max-w-lg">
        <h1 className="text-center text-3xl font-bold mt-12">Student Username</h1>
        {}
        <p className="text-center text-md mb-8 mt-2">
          Set a username for your child to use on the site
        </p>
        <form className="mx-auto max-w-xs grid grid-cols-1 gap-y-3">
          <div className="relative rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-yellow-400 focus-within:ring-1 focus-within:ring-yellow-400">
            <div className="mt-1">
              <input
                type="text"
                name="childs-name"
                id="childs-name"
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-400 focus:ring-0 sm:text-md font-bold"
                placeholder="Username"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className=" items-center text-center rounded-md border border-transparent bg-yellow-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2"
            onClick={handleSubmit}
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
}
