import { auth } from "../config/firebase";
const API = process.env.REACT_APP_API_URL;

export async function fetchFromAPI(endpointURL, opts) {
    const { method, body } = { method: "POST", body: null, ...opts };
  
    const user = auth.currentUser;
    const token = user && (await user.getIdToken());
  
    const res = await fetch(`${API}/${endpointURL}`, {
      method,
      ...(body && { body: JSON.stringify(body) }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  
    return res.json();
  }


  export const createNewUser = async (userData) => {
    const response = await fetchFromAPI("user/create-account", {
      method: "POST",
      body: userData,
    });
    return response;
  };