import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import star from "../../assets/gameIcons/star.png";
import blankstar from "../../assets/gameIcons/blankstar.png";
import { useNavigate, useParams } from "react-router-dom";
import winSound from "../../assets/Sounds/stars.wav";

export default function StarsAnimation() {
  const navigate = useNavigate();
  const [isStarGrowing, setIsStarGrowing] = useState(false);
  const [isStarTwoGrowing, setIsStarTwoGrowing] = useState(false);
  const [isStarThreeGrowing, setIsStarThreeGrowing] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [readyToNavigate, setReadyToNavigate] = useState(false);

  const { stars } = useParams();

  const audio = new Audio(winSound);

  useEffect(() => {
    setTimeout(() => {
    audio.play();
    }, 1000);
  }, []);

  useEffect(() => {
    // Trigger the star growth animation after a delay (e.g., 2 seconds)
    const timer = setTimeout(() => {
      setIsStarGrowing(true);
    }, 1000);

    const timer1 = setTimeout(() => {
      if (stars === "2" || stars === "3") {
        setIsStarTwoGrowing(true);
      } else {
        setIsDone(true);
      }
    }, 2000);

    const timer2 = setTimeout(() => {
      if (stars === "3") {
        setIsStarThreeGrowing(true);
      } else {
        setIsDone(true);
        
      }
    }, 3000);

    const timer3 = setTimeout(() => {
        setIsDone(true);
    }, 3500);
 
    
    let timer4 = setTimeout(() => {
        setReadyToNavigate(true);
      }, 3700);
    

    // Clean up the timer when the component unmounts
    return () => {
      clearTimeout(timer);
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, []);

  const handleLeave = () => {
    navigate("/student/dashboard");
  };

  return (
    <div className="sm:max-w-4xl mx-auto relative pt-24">
      <button className="absolute top-12 right-0" onClick={handleLeave}>
        <XMarkIcon className="h-12 text-emerald-500 hover:text-emerald-600" />
      </button>
      <div className="text-center   text-5xl font-londrina">Congrats!</div>
      <div className="text-center text-2xl font-londrina">
        You have completed the challenge!
      </div>
      <h1 className="text-7xl font-londrina text-center mt-12 ">You passed!</h1>
      <h2 className="text-4xl font-londrina text-center">
        You earned {stars} stars{" "}
      </h2>
      <div
        className={`flex items-center justify-center mt-12 transition duration-150 ease-in-out ${
          readyToNavigate ? "opacity-100" : isDone ? "transform scale-150" : ""
        }`}
      >
        <img
          src={isStarGrowing ? star : blankstar}
          alt="Award"
          className={`mx-3 w-24 sm:w-auto transition duration-150 ease-in-out ${
            isStarGrowing ? "transform scale-125" : isDone ? "" : "animate-spin"
          }`}
        />
        <img
          src={isStarTwoGrowing ? star : blankstar}
          alt="Award"
          className={`mx-3 w-24 sm:w-auto transition duration-150 ease-in-out ${
            isStarTwoGrowing
              ? "transform scale-125"
              : isDone
              ? ""
              : "animate-spin"
          }`}
        />
        <img
          src={isStarThreeGrowing ? star : blankstar}
          alt="Award"
          className={`mx-3 w-24 sm:w-auto transition duration-150 ease-in-out ${
            isStarThreeGrowing
              ? "transform scale-125"
              : isDone
              ? ""
              : "animate-spin"
          }`}
        />
      </div>
      <div className="text-center">
        <button
          className={`bg-yellow-300 text-7xl font-londrina mx-auto mt-20 rounded-md w-1/2  hover:bg-yellow-500 ${
            readyToNavigate
              ? "animate-bounce"
              : ""
          }`}
          onClick={handleLeave}
        >
          Next
        </button>
      </div>
    </div>
  );
}
