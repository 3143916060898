import React, { useEffect, useRef, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { useRecordWebcam } from "react-record-webcam";
import { useNavigate } from "react-router-dom";
import instructions from "../../assets/readoutloud.wav";
import hotinstructions from "../../assets/hotreadoutloud.wav";
import lefty from "../../assets/lefty-new.png";
import {
  ArrowPathRoundedSquareIcon,
  CursorArrowRippleIcon,
  HomeIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { saveRead } from "../../config/lessonFunctions";
import UploadCare from "../components/UploadCare";

const { REACT_APP_API_URL } = process.env;

export default function ColdAndHotReads() {
  const {
    studentProfile,
    coldReadRotRead,
    currentLesson,
    setStartTime,
    startTime,
    setIsLoading,
    currentVideo,
  } = UserAuth();
  const [readType, setReadType] = useState("cold");
  const [cameraOpen, setCameraOpen] = useState(false);
  const [lessonStarted, setLessonStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [benchmarkID, setBenchmarkID] = useState("");
  const [lessonComplete, setLessonComplete] = useState(false);
  const [currentBenchmark, setCurrentBenchmark] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const { uploading, uploadColdHotRead } = UploadCare("be59ddaa3d3a91b31931");

  const navigate = useNavigate();
  const audio = new Audio(instructions);
  const hotAudio = new Audio(hotinstructions);

  useEffect(() => {
    // Set the audioRef based on readType
    if (readType === "cold") {
      audioRef.current = audio; // Set the ref to the cold audio
    } else {
      audioRef.current = hotAudio; // Set the ref to the hot audio
    }
  }, [readType]);

  const OPTIONS = {
    fileName: "test-filename",
    mimeType: "video/x-matroska;codecs=avc1",
    width: 1920,
    height: 1080,
    disableLogs: true,
  };

  const playAudio = () => {
    setIsPlaying(true);
    audioRef.current.play();
  };

  const stopAudio = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  const recordWebcam = useRecordWebcam(OPTIONS);

  useEffect(() => {
    if (studentProfile?.coldReadComplete) {
      setReadType("hot");
      setIsLoading(false);
    }
    if (studentProfile) {
      coldReadRotRead(studentProfile?.lessonsAssigned[0]?.path);
    } else {
      navigate("/student/dashboard");
    }
    setIsLoading(false);
  }, [studentProfile]);

  const handleOpenWebcam = async () => {
    recordWebcam.open();
    setLoading(true);
    playAudio();
    setStartTime(new Date().getTime());
    setTimeout(() => {
      setCameraOpen(true);
      setLoading(false);
    }, 1500);
  };

  const [countdown, setCountdown] = useState(60); // Initial countdown value in seconds

  useEffect(() => {
    let countdownInterval;

    if (lessonStarted && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }

    return () => {
      // Clear the countdown interval when the component unmounts or when the countdown reaches 0
      clearInterval(countdownInterval);
    };
  }, [lessonStarted, countdown]);

  let recordingTimeout;
  const countForOneMinute = () => {
    recordingTimeout = setTimeout(() => {
      recordWebcam.stop();
      setLessonStarted(false);
      setCameraOpen(false);
      setLessonComplete(true);
    }, 60000);
  };

  const handleRecordStart = () => {
    recordWebcam.start();
    setLessonStarted(true);
    stopAudio();
    countForOneMinute();
  };

  const handlePracticeLesson = () => {
    navigate(`/student/practice-time/lessons/${currentLesson.grade}/day3/${currentLesson.lessonNumber}`)
  }

  const saveFileWithUpoadCare = async () => {
    setLoading(true);
    try {
      const fileBlob = await recordWebcam.getRecording();

      // Create a new Blob with a custom type if needed
      const mimeType = "video/mp4";
      const recordedVideoBlob = new Blob([fileBlob], { type: mimeType });

      // Create a file name for the recorded video
      const fileName = `${studentProfile?.studentId}-${readType}-read-${studentProfile?.currentLesson
        }-${new Date().getTime()}.mp4`;

      // Create a FormData object and append the Blob to it
      const formData = new FormData();
      formData.append("file", recordedVideoBlob, fileName);
      const finalTime = new Date().getTime() - startTime;
      const lessonData = {
        studentId: studentProfile?.studentId,
        readType: readType,
        parentId: studentProfile?.parentId,
        time: finalTime,
        title: currentLesson?.title,
        lessonVideo: currentLesson?.lessonVideo,
        lessonNumber: currentLesson?.lessonNumber,
      };
      await uploadColdHotRead(
        recordedVideoBlob,
        lessonData,
        readType,
        fileName
      );
      setLoading(false);
    } catch (error) {
      alert("Error uploading file:", error);
      setLoading(false);
      navigate("/student/lesson-complete");
    }
  };

  return (
    <div className="relative">
      <div
        className={`sticky top-0 sm:top-5 sm:left-5 rounded-md lg:w-96 w-48 ${!cameraOpen
          ? "bg-transparent"
          : readType === "hot"
            ? "bg-red-500 shadow-md shadow-black"
            : "bg-blue-500 shadow-md shadow-black"
          } p-3`}
      >
        <video ref={recordWebcam.webcamRef} autoPlay muted />
      </div>
      {/* count down progress circle timer here */}
      <div className="fixed top-2 right-2">
        <div className=" rounded-full p-2">
          <div
            className={` rounded-full h-16 w-16 flex items-center justify-center ${readType === "hot" ? "bg-red-500" : "bg-blue-500"
              } `}
          >
            <div className="text-white text-4xl font-gbold">{countdown}</div>
          </div>
        </div>
      </div>

      <div className="max-w-full mx-auto -mt-48">
        {lessonStarted ? (
          <div className="grid gtid-cols-3 gap-x-2 xs:pt-0 2xl:pt-0 pt-72n">
            <div className="justify-center p-12 max-w-5xl mx-auto">
              <div className="text-center text-4xl font-gbold font-semibold">
                {currentLesson?.title}
              </div>
              <div className="flex items-center justify-center">
                <div className="items-center mt-4">
                  {currentLesson?.lyricLines?.map((text, index) => (
                    <div key={index} className="text-left text-3xl ">
                      {text === "" ? <div className="mt-4 mb-4"></div> : text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : cameraOpen ? (
          <div className="px-6 lg:px-8 flex items-center h-screen justify-center">
            <div className="mx-auto max-w-2xl text-center">
              <div>
                <img src={lefty} alt="" className="h-56 w-auto mx-auto" />
                <div className="text-center text-4xl font-gbold font-semibold">
                  Read Clearly and Read Out Loud!
                </div>
                <button
                  onClick={handleRecordStart}
                  className="mt-8 w-full inline-flex items-center justify-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-green-500 hover:bg-green-600 font-gbold animate-bounce"
                >
                  <PlayCircleIcon className="inline-block h-12 w-12 bg-white mr-4 rounded-full" />{" "}
                  Record
                </button>
              </div>
            </div>
          </div>
        ) : lessonComplete ? (
          <div className="bg-white px-6 lg:px-8 flex items-center h-screen justify-center">
            <div className="mx-auto max-w-2xl text-center">
              {loading ? (
                <div className="z-50 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
                </div>
              ) : (
                <button
                  onClick={saveFileWithUpoadCare}
                  className="w-full flex items-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 animate-bounce font-gbold "
                >
                  <HomeIcon className="inline-block h-12 w-12 mr-4" />
                  Go Home
                </button>
              )}

            </div>
          </div>
        ) : (
          <div className="bg-white px-6 lg:px-8 flex items-center h-screen justify-center">
            <div className="mx-auto max-w-2xl text-center">
              <div className="mb-12 text-7xl font-gbold tracking-widest">
                Welcome to your{" "}
                {readType === "cold" ? (
                  <span className="text-blue-500 underline">COLD</span>
                ) : (
                  <span className="text-red-500 underline">HOT</span>
                )}{" "}
                read!{" "}
              </div>
              <div className="mb-12 text-4xl font-gbold tracking-widest">
                In this lesson you need to READ OUTLOUD!
              </div>
              {readType === "hot" && (
                <button className="w-full flex items-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-emerald-600 hover:bg-emerald-700 animate-bounce font-gbold justify-center mb-12"
                onClick={handlePracticeLesson}>
                  <ArrowPathRoundedSquareIcon className="inline-block h-12 w-12 mr-4" /> Practice More
                </button>

              )}
              <button
                onClick={handleOpenWebcam}
                className="w-full flex items-center px-12 py-3 border border-transparent md:text-7xl text-2xl font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 animate-bounce font-gbold justify-center "
              >
                {loading ? (
                  <div className="z-50 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
                  </div>
                ) : (
                  <>
                    <CursorArrowRippleIcon className="inline-block h-12 w-12 mr-4" />
                    Start Lesson
                  </>
                )}
              </button>


            </div>
          </div>
        )}
      </div>
    </div>
  );
}
