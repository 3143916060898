import { XMarkIcon } from "@heroicons/react/20/solid";
import books from "../../assets/books";
import { useNavigate, useParams } from "react-router-dom";

export default function Award() {

    const navigate = useNavigate();

    const { id } = useParams();

    const handleSaveAward = () => {
        // save award to database
        navigate('/student/dashboard')
    }
  return (
    <div className="max-w-4xl mx-auto relative px-4">
        <button className="absolute top-10 right-2" onClick={handleSaveAward}><XMarkIcon className="h-12 text-emerald-500 hover:text-emerald-600"/></button>
      <div className="text-center pt-24  text-5xl font-londrina">Congrats!</div>
      <div className="text-center text-2xl font-londrina ">
        You have completed the <span className="text-emerald-600">{books[id].title}</span> award!
      </div>
        <h1 className="text-7xl font-londrina text-center mt-12 ">{books[id].title}</h1>
        <h2 className="text-4xl font-londrina text-center">Award</h2>
        <div className="flex items-center justify-center mt-12 ">
        <img
          src={books[id].source}
          alt="Award"
          className="w-64 h-64 transition-all duration-500 animate-bounce"
        />
        </div>
        <button className="bg-yellow-300 text-7xl font-londrina mx-auto mt-4 rounded-md w-full hover:bg-yellow-500"onClick={handleSaveAward}>Next</button>
    </div>
  );
}
