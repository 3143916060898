import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import files from "../../assets/Images";
import { createStudent } from "../../helpers/firebaseFunctions";

export default function AssignToken() {
  const [imageIndex, setImageIndex] = useState(0);
  const { newUser, setNewUser, profile } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (newUser.started) {
      return;
    } else {
      navigate("/setup-student");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

      const newUserAd = {
        ...newUser,
        imageIndex: imageIndex,
        parentID: profile.id,
        parentEmail: profile.email,
      }
      setNewUser(newUserAd);
      createStudent(newUserAd);
      navigate("/print-login");

  };

  return (
    <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
      <div className="relative mx-auto max-w-lg">
        <h1 className="text-center text-3xl font-bold ">
          Student Login Token
        </h1>
        <p className="text-center text-md mt-2">
          Select a token for your child to use on the site {imageIndex}
        </p>
        <p className="text-center mb-8 text-sm text-gray-400">
          Students do not use passwords, we use images to ensure the privacy and
          safety of your student
        </p>
      </div>
      <div className="max-w-3xl mx-auto">
        <ul className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-8 xl:gap-x-8 mt-4">
          {files.map((file, index) => (
            <li key={file.source} className="relative">
              <div className={`group aspect-w-10 aspect-h-10 block w-full overflow-hidden rounded-lg  focus-within:ring-2 focus-within:ring-emerald-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 ${imageIndex === index ? "border-teal-500 border-2 bg-teal-100/90 bg-opacity-10" : "bg-gray-50"}`}>
                <img
                  src={file.source}
                  alt=""
                  className="pointer-events-none object-cover group-hover:opacity-75"
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                  onClick={() => setImageIndex(index)}
                >
                  <span className="sr-only text-center">
                    View details for {file.title}
                  </span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900 text-center">
                {file.title}
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className="mx-auto max-w-xs grid grid-cols-1 gap-y-3 mt-4">
        <button
          type="button"
          className="mx-auto items-center text-center rounded-md border border-transparent bg-yellow-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-300 focus:ring-offset-2"
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
}
