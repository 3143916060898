import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import FreeTrial from "./pages/FreeTrial";
import NewPricing from "./pages/Pricing";
import Login from "./pages/Login";
import CreateNewEmail from "./parents/components/CreateNewEmail";
import { UserAuth } from "./context/AuthContext";
import { useState } from "react";
import { useEffect } from "react";
import Nav from "./components/Nav";
import { Suspense } from "react";
import Loading from "./components/Loading";
import ParentRoutes from "./routes/ParentRoutes";
import StudentRoutes from "./routes/StudentRoutes";
import TermsOfService from "./pages/TermsOfService";

function App() {
  const { isLoggedIn, isParent, isLoading } = UserAuth();
  const [parent, setParent] = useState(false);

  useEffect(() => {
    if (isParent) {
      setParent(true);
    }
  }, [isParent]);

  return (
    <div>
      <Suspense fallback={<Loading />}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
          {isLoggedIn ? (
              <>
                {parent && <ParentRoutes />}
                {!parent && <StudentRoutes />}
              </>
          ) : (
            <>
            <Nav />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/free-trial" element={<FreeTrial />} />
              <Route path="/pricing" element={<NewPricing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/create-parent-email" element={<CreateNewEmail />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/*" element={<Home />} />
            </Routes>
            </>
          )}
          </>
        )}
      </Suspense>
    </div>
  );
}

export default App;
