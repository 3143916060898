import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db } from "../config/firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { createNewUser } from "./helpers";

export async function parentSignIn(email, password) {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    
    if (res.user) {
      return {status: 200}; // Sign-in was successful
    } else {
      // Handle other cases or return an error message
      return "Sign-in failed"; // You can replace this with an appropriate error message
    }
  } catch (error) {
    // Handle any exceptions that may occur during sign-in
    return error.message; // Return the error message
  }
}


export const studentLoginFromStudent = async (userName, token) => {
  let status;
  const q = query(
    collection(db, "loginChecks"),
    where("uname", "==", userName)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    console.log(doc.id);
    if (doc.data().indx === token && doc.data().uname === userName) {
      const email = doc.id + "@readingmastery.org";
      const password = "studentPassword123";
      await signInWithEmailAndPassword(auth, email, password);
      return { hasAccount: true, _id: doc.id };
    } else {
      console.log("Login Incorrect");
      status = null;
    }
  });
  return status;
};

export async function studentSignInFromParent(userName, token) {
  let status;
  const q = query(
    collection(db, "students"),
    where("userName", "==", userName)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    if (doc.data().imageIndex === token && doc.data().userName === userName) {
      if (doc.data().hasAccount) {
        const email = doc.data().loginID + "@readingmastery.org";
        const password = "studentPassword123";
        await signInWithEmailAndPassword(auth, email, password);
        status = { hasAccount: true, _id: doc.data()._id, data: doc.data() };
      } else {
        status = { hasAccount: false, _id: doc.data()._id, data: doc.data() };
      }
    } else {
      console.log("Login Incorrect");
      status = null;
    }
  });
  return status;
}

export async function createParent(parentData) {
  try {
    const user = await createUserWithEmailAndPassword(
      auth,
      parentData.email,
      parentData.password
    );
    await setDoc(doc(db, "users", user.user.uid), {
      email: parentData.email,
      id: user.user.uid,
      name: parentData.name,
      firstName: parentData.firstName,
      lastName: parentData.lastName,
      acceptsTermsForSubscription: true,
      phone: parentData.phoneNumber,
      subscription: parentData.subscription,
      hasAccount: true,
      finished: false,
      role: "parent",
    });
    try {
      const userInfo = {
        email: parentData.email,
        first_name: parentData.firstName,
        last_name: parentData.lastName,
        phone_number: parentData.phoneNumber,
        UserType: "Home",
        first_seen: new Date(),
        ip_address: parentData.ip,
        UserID: user.user.uid,
        pageVisits: 1,
        last_seen: new Date(),
        updated_at: new Date(),
        last_ip: parentData.ip,
        SubscriptionChannel: "Home_Free_Trial",
        AtHomeSubscriptionType: parentData.subscription
      }
      createNewUser(userInfo);
    } catch (error) {
      console.log(error);
    }
    return { hasAccount: true, _id: user.user.uid };
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      return { hasAccount: false, _id: null, error: error.code };
    } else {
      return { hasAccount: false, _id: null, error: error.code };
    }
  }
}

export const setStudentOptions = async (studentID, settings) => {
  const docRef = doc(db, "students", studentID);
  await updateDoc(docRef, {
    avatar: {
      bgColor: settings.imageColor,
      imageIndex: settings.imageIndex,
    },
    color: settings.color,
    finishedSetup: true,
  });
  return true;
};

export const loginStudent = async (studentID, studentIndex) => {
  let result = null; // Use a different variable name
  console.log(studentID, studentIndex);
  try {
    await studentSignInFromParent(studentID, studentIndex).then((res) => {
      console.log(res);
      if (res.hasAccount) {
        result = 200; // Use the 'result' variable here
      } else {
        result = res.data; // Use the 'result' variable here
      }
    });
  } catch (e) {
    console.log(e.message);
  }
  return result; // Return the 'result' variable
};

export const createStudent = async (studentInfo) => {
  const id = Math.floor(Math.random() * 1000000000);
  const email = id + "@readingmastery.org";
  const password = "studentPassword123";
  return createUserWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      const getStudentGradeLevel = async () => {
        const lastLesson = {
          k: 32,
          1: 59,
          2: 85,
          3: 110,
          4: 134,
          5: 164,
        };
        const firstLesson = {
          k: 1,
          1: 32,
          2: 60,
          3: 86,
          4: 111,
          5: 135,
        };
        const customReferencePathDay1 = doc(
          db,
          "lessons",
          studentInfo.gradeLevel.toString(),
          "day1",
          firstLesson[studentInfo.gradeLevel].toString()
        );
        const customReferencePathDay2 = doc(
          db,
          "lessons",
          studentInfo.gradeLevel.toString(),
          "day2",
          firstLesson[studentInfo.gradeLevel].toString()
        );
        const customReferencePathDay3 = doc(
          db,
          "lessons",
          studentInfo.gradeLevel.toString(),
          "day3",
          firstLesson[studentInfo.gradeLevel].toString()
        );

        const customRef1 = await getDoc(customReferencePathDay1);
        const ref1 = customRef1.ref;
        const customRef2 = await getDoc(customReferencePathDay2);
        const ref2 = customRef2.ref;
        const customRef3 = await getDoc(customReferencePathDay3);
        const ref3 = customRef3.ref;
        const title = customRef1.data().title

        let lesPlan = [ref1, ref2, ref3];

        setDoc(doc(db, "students", userCredential.user.uid), {
          studentId: userCredential.user.uid,
          parentEmail: studentInfo.parentEmail,
          lessonsComplete: 0,
          coldReadCount: 0,
          hotReadCount: 0,
          toView: 0,
          totalRecordings: 0,
          fluencyPracticeTime: 0,
          timeSpentLearning: 0,
          totalWordsRead: 0,
          attemptedLessons: [],
          week: 1,
          grades: [],
          totalStars: 0,
          hasAwards: false,
          booksEarned: [],
          booksToEarn: [0, 1, 2, 3, 4, 5, 6, 7],
          isAssigendLessonsFromParent: false,
          currentLesson: 0,
          lessonScores: {
            score1: 1,
            score2: 0,
            score3: 0,
            score4: 0,
            score5: 0,
          },
          lessonsAssigned: lesPlan,
          parentsAssigned: [],
          firstLessonCompleted: false,
          benchMarksAssigned: false,
          hotReadAssigned: false,
          coldReadAssigned: true,
          benchMarkVideos: [],
          currentBenchmark: 1,
          imageIndex: studentInfo.imageIndex,
          lastLesson: lastLesson[studentInfo.gradeLevel],
          firstLesson: firstLesson[studentInfo.gradeLevel],
          gradeLevel: studentInfo.gradeLevel,
          currentLessonReference: firstLesson[studentInfo.gradeLevel],
          userName: studentInfo.userName,
          completedFirstBenchMark: false,
          benchMarksComplete: 0,
          avatar: {
            bgColor: "bg-blue-500",
            imageIndex: 0,
          },
          color: 0,
          email: email,
          firstName: studentInfo.studentName,
          parentId: studentInfo.parentID,
          role: "student",
          hasAccount: true,
          finishedSetup: false,
          loginID: id,
          awpm: 0,
          dayOneScore: [],
          dayTwoScore: [],
          dayThreeScore: [],
          dayOneFinished: 1,
          dayTwoFinished: 1,
          dayThreeFinished: 1,
        });
        const parentRef = doc(db, "users", studentInfo.parentID);
        setDoc(doc(db, "userNames", studentInfo.userName), {
          _id: userCredential.user.uid,
          status: "active",
        });
        const stringID = id.toString();
        setDoc(doc(db, "loginChecks", stringID), {
          indx: studentInfo.imageIndex,
          uname: studentInfo.userName,
        });
        const lessonRefID = firstLesson[studentInfo.gradeLevel] + userCredential.user.uid
        setDoc(doc(db, "lessonTracker", lessonRefID), {
          studentId: userCredential.user.uid,
          lessonId: firstLesson[studentInfo.gradeLevel],
          id: lessonRefID,
          completed: false, 
          lessonScores: [],
          graded: false, 
          attempts: 0,
          fluencyPracticeTime: 0,
          comprehensionPracticeTime: 0,
          lastActiveDate: serverTimestamp(),
          totalQuestionsAsked1: 0,
          totalQuestionsAsked2: 0,
          totalQuestionsAsked3: 0,
          totalQuestions1: 0,
          totalQuestions2: 0,
          totalQuestions3: 0,
          parentId: studentInfo.parentID,
          references: lesPlan,
          title: title,
          week: 1,
        }) 
        const parentSnap = await getDoc(parentRef);
        if (parentSnap.exists()) {
          updateDoc(parentRef, {
            hasStudent: true,
            alert: true,
          });
        } else {
          console.log("Parent does not exist.");
        }
      };
      getStudentGradeLevel();
    }
  );
};

export const createStudentFromParent = async (studentData) => {
  await signOut(auth);
  return studentData;
};

export const updateStudent = async (studentID, temp, studentData, userID) => {
  console.log(studentID, temp, studentData);
  try {
    const docRef2 = doc(db, "students", studentID);
    await updateDoc(docRef2, studentData);
    const docRef3 = doc(db, "users", userID);
    await updateDoc(docRef3, { updated: serverTimestamp() });
    const docRef4 = doc(db, "loginChecks", studentData.loginID.toString());
    await updateDoc(docRef4, {
      indx: studentData.imageIndex,
      uname: studentData.userName,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
