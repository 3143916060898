import React, { useEffect } from "react";
import Hero from "../components/Hero";
import FunForStudents from "../components/FunForStudents";
import MusicReading from "../components/MusicReading";
import Finale from "../components/Finale";

export default function Home() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Hero />
      <FunForStudents />
      <MusicReading />
      <Finale />
    </>
  );
}
