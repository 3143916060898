import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { UserAuth } from "../../context/AuthContext";
import { removeView } from "../../config/lessonFunctions";
import { loginStudent } from "../../helpers/firebaseFunctions";
import { useNavigate } from "react-router-dom";
import LoginSuccess from "../components/LoginSuccess";
import {
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";

ChartJS.register(ArcElement, Tooltip, Legend);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Main() {
  const { setCurrentNumber, profile, myStudents, isParent } = UserAuth();
  const [studentIndex, setStudentIndex] = useState(0);

  const navigate = useNavigate();

  const percentage = myStudents[studentIndex]?.grades[0]
    ? myStudents[studentIndex]?.grades.reduce((a, b) => a + b, 0) /
      myStudents[studentIndex]?.grades?.length
    : 0;

  const scoreData = {
    datasets: [
      {
        data: [100 - percentage, percentage],
        backgroundColor:
          percentage > 80
            ? ["transparent", "#22c55e"]
            : percentage > 60
            ? ["transparent", "#eab308"]
            : ["transparent", "#ef4444"],
        borderColor:
          percentage > 80
            ? ["#15803d", "#15803d"]
            : percentage > 60
            ? ["#d97706", "#d97706"]
            : ["#c53030", "#c53030"],
        borderWidth: 1,
      },
    ],
  };

  const day1percentage = myStudents[studentIndex]?.dayOneScore[0]
    ? myStudents[studentIndex]?.dayOneScore.reduce((a, b) => a + b, 0) /
      myStudents[studentIndex]?.dayOneScore?.length
    : 0;

  const dayOne = {
    datasets: [
      {
        data: [100 - day1percentage, day1percentage],
        backgroundColor:
          day1percentage > 80
            ? ["transparent", "#bbf7d0"]
            : day1percentage > 60
            ? ["transparent", "#fef9c3"]
            : ["transparent", "#fbb6ce"],
        borderColor:
          day1percentage > 80
            ? ["#15803d", "#15803d"]
            : day1percentage > 60
            ? ["#d97706", "#d97706"]
            : ["#c53030", "#c53030"],
        borderWidth: 1,
      },
    ],
  };

  const handleRemoveView = () => {
    if (profile?.toView > 0) {
      removeView(profile?.id);
      setCurrentNumber(5);
    } else {
      setCurrentNumber(5);
    }
  };

  const day2percentage = myStudents[studentIndex]?.dayTwoScore[0]
    ? myStudents[studentIndex]?.dayTwoScore.reduce((a, b) => a + b, 0) /
      myStudents[studentIndex]?.dayTwoScore?.length
    : 0;

  const dayTwo = {
    datasets: [
      {
        data: [100 - day2percentage, day2percentage],
        backgroundColor:
          day2percentage > 80
            ? ["transparent", "#bbf7d0"]
            : day2percentage > 60
            ? ["transparent", "#fef9c3"]
            : ["transparent", "#fbb6ce"],
        borderColor:
          day2percentage > 80
            ? ["#15803d", "#15803d"]
            : day2percentage > 60
            ? ["#d97706", "#d97706"]
            : ["#c53030", "#c53030"],
        borderWidth: 1,
      },
    ],
  };

  const day3percentage = myStudents[studentIndex]?.dayThreeScore[0]
    ? myStudents[studentIndex]?.dayThreeScore.reduce((a, b) => a + b, 0) /
      myStudents[studentIndex]?.dayThreeScore?.length
    : 0;

  const dayThree = {
    datasets: [
      {
        data: [100 - day3percentage, day3percentage],
        backgroundColor:
          day3percentage > 80
            ? ["transparent", "#bbf7d0"]
            : day3percentage > 60
            ? ["transparent", "#fef9c3"]
            : ["transparent", "#fbb6ce"],
        borderColor:
          day3percentage > 80
            ? ["#15803d", "#15803d"]
            : day3percentage > 60
            ? ["#d97706", "#d97706"]
            : ["#c53030", "#c53030"],
        borderWidth: 1,
      },
    ],
  };

  const getGradeSuffix = (gradeLevel) => {
    if (gradeLevel === "k") {
      return ""; // Blank for kindergarten
    } else if (gradeLevel === "1") {
      return "st";
    } else if (gradeLevel === "2") {
      return "nd";
    } else if (gradeLevel === "3") {
      return "rd";
    } else if (gradeLevel === "4" || gradeLevel === "5") {
      return "th";
    } else {
      return "th"; // Default to "th" for other cases
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleLoginStudent = async () => {
    await loginStudent(
      myStudents[studentIndex]?.userName,
      myStudents[studentIndex]?.imageIndex
    ).then((res) => {
      navigate("/student/dashboard");
    });
  };

  useEffect(() => {
    if (isParent) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isParent]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <LoginSuccess open={open} handleClose={handleClose} />
      <main className="mx-auto max-w-7xl pt-6">
        <div className="">
          <div className="px-4 sm:px-6 lg:px-8 sm:flex items-center gap-4">
            <div className="w-full">
              <h1 className="text-2xl font-semibold text-gray-900">
                Dashboard for{" "}
                <span className="text-indigo-500 font-extrabold">
                  {myStudents[studentIndex]?.firstName}
                </span>
              </h1>
            </div>
            <div className="ml-auto flex items-center">
              <select
                id="student"
                name="student"
                className="w-48 block h-12  rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={myStudents[studentIndex]?.firstName}
                onChange={(e) => {
                  setStudentIndex(e.target.value);
                }}
              >
                {myStudents?.map((student, index) => (
                  <option key={index} value={index}>
                    {student.firstName}
                  </option>
                ))}
              </select>

              <button
                className=" w-full ml-2 rounded-md bg-red-400/90 px-2.5 h-12 py-1.5 text-sm font-semibold text-white shadow-sm  hover:bg-red-600 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-red-500 whitespace-nowrap "
                onClick={() => handleLoginStudent()}
              >
                Login Your Student
              </button>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}

            <div className="grid md:grid-cols-4 grid-cols-1 md:gap-2 sm:border sm:bg-white rounded sm:shadow p-4 mt-12">
              <div className="sm:flex sm:items-center col-span-4 ">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    My Student's Current Progress
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Your student is on the{" "}
                    <strong className="font-semibold text-gray-900">
                      {myStudents[studentIndex]?.gradeLevel}
                      {getGradeSuffix(myStudents[studentIndex]?.gradeLevel)}
                    </strong>{" "}
                    grade track. {myStudents[studentIndex]?.studentName} is{" "}
                    <span className="font-semibold text-emerald-600">
                      {(
                        ((myStudents[studentIndex]?.currentLessonReference -
                          myStudents[studentIndex]?.firstLesson) /
                          (myStudents[studentIndex]?.lastLesson -
                            myStudents[studentIndex]?.firstLesson)) *
                        100
                      ).toFixed(0)}
                      %
                    </span>{" "}
                    done with this track.
                  </p>
                </div>
              </div>
              <div className="rounded-lg col-span-4 mt-4">
                <div className="-mx-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg sm:mb-0 mb-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Student
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm bg-yellow-50 font-semibold text-gray-900 lg:table-cell text-center"
                        >
                          Benchmarks
                          <div className="tooltip">
                            <QuestionMarkCircleIcon className="h-4 w-4 ml-1 inline mb-1 text-gray-400" />
                            <span className="tooltiptext">
                              <span className="block">
                              This is a short fluency evaluation given every 4 lessons to help you track student progress. 
                              </span>
                              <span className="block mt-4">
                              It is leveled to the grade you have assigned your student. 
                              </span>
                              <span className="block mt-4">
                              If your student is mastering one story per week, this will go up throughout the year! 
                              </span>
                            </span>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-sm font-semibold text-gray-900 lg:table-cell bg-blue-50 text-center"
                        >
                          Cold Read
                          <div className="tooltip">
                            <QuestionMarkCircleIcon className="h-4 w-4 ml-1 inline mb-1 text-gray-400" />
                            <span className="tooltiptext">
                              <span className="block">
                                A cold read is when your student reads a new
                                weekly text for the first time before practicing
                                with the musician.{" "}
                              </span>
                              <span className="block mt-4">
                                Every weekly lesson starts with a 1 minute cold
                                read video recording so you and your student can
                                see fluency improvement.{" "}
                              </span>
                            </span>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell bg-red-50 text-center"
                        >
                          Hot Read
                          <div className="tooltip">
                            <QuestionMarkCircleIcon className="h-4 w-4 ml-1 inline mb-1 text-gray-400" />
                            <span className="tooltiptext">
                              <span className="block">
                                A “hot read” is when your student has practiced
                                with the musician (out loud 3 times each day!)
                              </span>
                              <span className="block mt-4">
                                Every weekly lesson ends with a 1 minute hot
                                read video recording so you and your student can
                                see how practice leads to improvement!
                              </span>
                            </span>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right"
                        >
                          <span>Recordings</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className={
                            "border-t border-transparent relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                          }
                        >
                          <div className="font-medium text-gray-900">
                            {myStudents[studentIndex]?.firstName}

                            {/* <span className="ml-1 text-indigo-600">
                              (Current Plan)
                            </span> */}
                          </div>

                          <div className="absolute -top-px left-0 right-0 h-px bg-gray-200" />
                        </td>
                        <td className="border-t border-gray-200 hidden px-3 py-3.5 text-lg text-gray-500 lg:table-cell text-center font-bold">
                          {myStudents[studentIndex]?.lessonsComplete}
                        </td>
                        <td className="border-t border-gray-200 hidden px-3 py-3.5 text-lg text-blue-500 lg:table-cell text-center font-bold">
                          {myStudents[studentIndex]?.coldReadCount}
                        </td>
                        <td className="border-t border-gray-200 hidden px-3 py-3.5 text-lg text-red-500 lg:table-cell text-center font-bold">
                          {myStudents[studentIndex]?.hotReadCount}
                        </td>

                        <td
                          className={classNames(
                            true ? "" : "border-t border-transparent",
                            "relative py-3.5 pl-3 pr-4 text-right text-lg font-bold sm:pr-6 text-center"
                          )}
                        >
                          <button
                            className="inline-flex items-center rounded-md bg-yellow-200 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-yellow-300 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                            disabled={profile?.toView?.length === 0}
                            onClick={handleRemoveView}
                          >
                            View ({profile?.toView || 0})
                            <span className="sr-only">, name</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="lg:mt-4 col-span-4">
                <div className="grid grid-cols-4 gap-2">
                  <div className="divide-y divide-blue-600 overflow-hidden rounded-lg border border-blue-600 shadow col-span-4 lg:col-span-1">
                    <div className="px-4 py-5 sm:px-6 text-center bg-blue-50 font-bold text-blue-600">
                      Lessons Complete
                    </div>
                    <div className="px-4 py-5 sm:p-6 text-center text-5xl font-bold text-blue-600 bg-white">
                      {myStudents[studentIndex]?.lessonsComplete}
                    </div>
                  </div>

                  <div className="divide-y divide-emerald-600 overflow-hidden border border-emerald-600 rounded-lg bg-white shadow col-span-4 lg:col-span-1">
                    <div className="px-4 py-5 sm:px-6 text-center bg-emerald-50 text-emerald-600 font-bold">
                      Recordings
                    </div>
                    <div className="px-4 py-5 sm:p-6 text-center text-5xl font-bold text-emerald-600">
                      {myStudents[studentIndex]?.benchMarkVideos?.length +
                        myStudents[studentIndex]?.coldReadCount +
                        myStudents[studentIndex]?.hotReadCount}
                    </div>
                  </div>
                  <div className="divide-y divide-indigo-600 overflow-hidden rounded-lg bg-white border border-blue-600 shadow col-span-4 lg:col-span-1">
                    <div className="px-4 py-5 sm:px-6 text-center bg-indigo-50 text-indigo-600 font-bold">
                      Fluency Practice Time
                    </div>
                    <div className="px-4 py-5 sm:p-6 text-center text-3xl font-bold text-indigo-600">
                      {(
                        myStudents[studentIndex]?.fluencyPracticeTime / 60000
                      ).toFixed(0)}{" "}
                      Minutes
                    </div>
                  </div>
                  <div className="divide-y divide-yellow-600 overflow-hidden rounded-lg bg-white border border-yellow-600 shadow col-span-4 lg:col-span-1">
                    <div className="px-4 py-5 sm:px-6 text-center bg-yellow-50 text-yellow-600 font-bold ">
                      Time Spent Learning
                    </div>
                    <div className="px-4 py-5 sm:p-6 text-center text-3xl font-bold text-yellow-600">
                      {(
                        myStudents[studentIndex]?.timeSpentLearning / 60000
                      ).toFixed(0)}{" "}
                      Minutes
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-96 rounded-lg col-span-4 mt-6">
                <div className="grid grid-cols-5 gap-2">
                  <div className="text-center col-span-5 lg:col-span-1">
                    <div className="text-xl font-semibold col-span-1">
                      Grade Level
                    </div>
                    <div className="text-7xl font-bold flex justify-center items-center h-full text-emerald-500">
                      {myStudents[studentIndex]?.gradeLevel}
                      {getGradeSuffix(myStudents[studentIndex]?.gradeLevel)}
                    </div>
                  </div>
                  <div className="text-center col-span-5 lg:col-span-1">
                    <div className="text-xl font-semibold col-span-1">
                      Questions Correct
                      <div
                        className={`text-4xl font-bold ${
                          percentage > 80
                            ? "text-green-700"
                            : percentage > 60
                            ? "text-yellow-700"
                            : "text-red-700"
                        }`}
                      >
                        {percentage?.toFixed(0)}%{" "}
                      </div>
                    </div>
                    <Doughnut data={scoreData} />
                  </div>
                  <div className="text-center col-span-5 lg:col-span-1">
                    <div className="text-xl font-semibold col-span-1">
                      Literal Day One{" "}
                      <div
                        className={`text-4xl font-bold ${
                          day1percentage > 80
                            ? "text-green-700"
                            : day1percentage > 60
                            ? "text-yellow-700"
                            : "text-red-700"
                        }`}
                      >
                        {day1percentage?.toFixed(0)}%{" "}
                      </div>
                    </div>
                    <Doughnut data={dayOne} />
                  </div>
                  <div className="text-center col-span-5 lg:col-span-1">
                    <div className="text-xl font-semibold col-span-1">
                      Inference Day Two
                      <div
                        className={`text-4xl font-bold ${
                          day2percentage > 80
                            ? "text-green-700"
                            : day2percentage > 60
                            ? "text-yellow-700"
                            : "text-red-700"
                        }`}
                      >
                        {day2percentage?.toFixed(0)}%{" "}
                      </div>
                    </div>
                    <Doughnut data={dayTwo} />
                  </div>
                  <div className="text-center col-span-5 lg:col-span-1 mb-12 sm:mb-0">
                    <div className="text-xl font-semibold col-span-1">
                      Complex Day Three{" "}
                      <div
                        className={`text-4xl font-bold ${
                          day3percentage > 80
                            ? "text-green-700"
                            : day3percentage > 60
                            ? "text-yellow-700"
                            : "text-red-700"
                        }`}
                      >
                        {day3percentage?.toFixed(0)}%{" "}
                      </div>
                    </div>
                    <Doughnut data={dayThree} />
                  </div>
                </div>
              </div>
            </div>

            {/* /End replace */}
          </div>
        </div>
      </main>
    </div>
  );
}
