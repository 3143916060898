import { RadioGroup } from "@headlessui/react";
import { CheckIcon, MusicalNoteIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month", notes: "", discount: "" },
  { value: "annually", label: "Annually", priceSuffix: "/year", notes: "(MOST POPULAR)", discount: "*50% off monthly"  },
];

export default function NewPricing() {
  const [frequency, setFrequency] = useState(frequencies[1]);
  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []); 

  const tiers = [
    {
      name: "Family Membership",
      id: 1,
      href: "/free-trial",
      priceMonthly: frequency.value === "monthly" ? "$35" : "$210",
      description:
        "For parents helping their kids thrive and succeed in reading.",
      features: [
        "Accelerate reading growth up to  150%",
        "Full access to 6 years of fun, music-based reading lessons.",
        "Hundreds of music-based stories set to hip-hop, rock, blues and more",
        "Thousands of interactive comprehension activities, worksheets and videos.",
        "Kids can work independently and self-paced, or parents can take control of lessons and content. ",
        "Optional video recordings showing student fluency improvement",
        "Bi-monthly live webinars from our founder, offering tips, research and help for all parents.",
        "Track reading weekly, monthly and yearly progress and improvement.",
        "Access and training to do your own professional reading evaluation, pinpointing specific skills your child needs to read and grow.",
      ],
    },
    {
      name: "Reading Tutoring",
      id: 2,
      href: "https://readingmastery.org/df-quiz/reading-mastery-qualification-survey-2/",
      priceMonthly: "Custom Pricing",
      description:
        "For parents who want help from master teachers who will catch their kids up in reading.",
      features: [
        "Free phone consultation to ensure we are a good fit for your child.",
        "One-on-one reading evaluation identifying needs in phonemic awareness, phonics, fluency & comprehension.",
        "10-15 page reading summary outlining instructional strategies and goals.",
        "Monthly reports communicating student progress.",
        "A partnership with a small group of master teachers who are passionate, kind, and dedicated to your child's success.",
        "Full access to our music-based reading software.",
        "In-person, online sessions 1 on 1 or in small groups of 2-4 kids.",
      ],
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="isolate overflow-hidden bg-violet-600">
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-violet-400">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-gbold tracking-tight text-white sm:text-5xl">
            Affordable, Effective, and Fun
          </p>
        </div>
        <div className="relative mt-4">
          <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
            Designed to help you engage your students using a new
            ground-breaking reading tool, available to parents for the first
            time:{" "}
            <span className="font-gbold text-white">
              music is powerful{" "}
              <MusicalNoteIcon className="h-5 w-5 inline-block" />
            </span>
            .
          </p>
          <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
            <ellipse
              cx={604}
              cy={512}
              fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)"
              rx={604}
              ry={512}
            />
            <defs>
              <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className=" mt-12 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-md shadow-lg bg-gray-100 p-4 text-center text-lg font-semibold leading-5 "
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked
                      ? "bg-violet-600 text-white shadow-lg"
                      : "text-gray-500",
                    "cursor-pointer rounded-sm p-4 "
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      </div>

      <div className="flow-root pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-6xl lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3
                      id={tier.id}
                      className="text-center text-3xl font-gbold leading-7 text-violet-600"
                    >
                      {tier.name}
                    </h3>
                    <div className="flex items-baseline gap-x-2 mt-12">
                      <span className="text-5xl font-gbold tracking-tight text-gray-900">
                        {tier.priceMonthly}
                      </span>
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        {tier.id === 1 && frequency.priceSuffix} <span className="text-red-500">{tier.id === 1 && frequency.notes}</span>
                      </span>
                    </div>
                    {tier.id === 1 && <div>{frequency.discount}</div>}
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <ul
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      {tier.id === 2 && (
                        <li className="flex gap-x-3"> <CheckIcon
                        className="h-6 w-5 flex-none text-violet-600"
                        aria-hidden="true"
                      /><span className="font-extrabold -mr-2">Guaranteed</span>growth of 1 year or more every 6 months.</li>
                      )}
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-violet-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link
                    to={tier.href}
                    aria-describedby={tier.id}
                    className="mt-8 block rounded-md bg-violet-600 px-8 py-4 text-center text-lg font-semibold leading-6 text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                  >
                    Get started today
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
